import {Component, Input, OnInit} from '@angular/core';
import {RoutesService} from "../../../core/services/routes.service";

@Component({
  selector: 'app-bottom-help',
  templateUrl: './bottom-help.component.html',
  styleUrls: ['./bottom-help.component.scss']
})
export class BottomHelpComponent implements OnInit {

  @Input() showText = true;


  constructor(
      public routesService: RoutesService
  ) { }

  ngOnInit(): void {
  }

}
