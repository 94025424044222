import {Image, ImageAttributeWrapper, ImageWrapper} from "./image.model";

export interface Role {
    id: number;
    title: string;
    person: Person[];
}

export interface Person {
    id: number;
    name: string;
    languages: string;
    position: string;
    picture: Image;
}

export interface EmployeeWrapper {
    id: number;
    role: RoleWrapper;
}

export interface RoleWrapper {
    id: number;
    title: string;
    person: PersonWrapper[];
}

export interface PersonWrapper {
    id: number;
    name: string;
    languages: string;
    position: string;
    picture: ImageAttributeWrapper;
}
