<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Begrüssungspaket für 120 €
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/offers/top/Angebote-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Begrüßungspaket für Neupatient(inn)en</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p class="bold" i18n>Sind Sie an einer schmerzfreien Zahnbehandlung auf hohem Niveau
                        interessiert?<br>
                        Dann sehen wir Ihrer Terminanfrage mit Interesse entgegen!</p>
                    <p i18n>In unserem Eurodent Zahn- und Implantatzentrum in Ungarn erhalten Sie schöne, neue Zähne –
                        zu
                        einem
                        guten Preis, ohne dabei Abstriche an der Qualität befürchten zu müssen. Wir bieten Ihnen eine
                        besondere Möglichkeit, um einen persönlichen Eindruck von uns zu gewinnen. Danach können Sie
                        sich
                        entscheiden, ob Sie uns Ihr Vertrauen schenken.</p>

                    <app-top-navigation></app-top-navigation>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>Was das paket enthält?</h1>
        </div>
        <div class="welcome-pack">
            <div class="welcome-pack__item">
                <div class="welcome-pack__item__icon">
                    <img src="assets/icons/offers/talk.svg">
                </div>
                <div class="welcome-pack__item__description">
                    <div i18n class="welcome-pack__item__description__title">Erstberatung mit individuellem Heil- und
                        Kostenplan <br> <span class="text">(im Wert von 50 €)</span>
                    </div>
                </div>
            </div>
            <div class="welcome-pack__item">
                <div class="welcome-pack__item__icon">
                    <img src="assets/icons/offers/x-ray.svg">
                </div>
                <div class="welcome-pack__item__description">
                    <div i18n class="welcome-pack__item__description__title">Panoramaröntgenaufnahme
                        <br> <span class="text">(im Wert von 50 €)</span>
                    </div>
                </div>
            </div>

            <div class="welcome-pack__item">
                <div class="welcome-pack__item__icon">
                    <img src="assets/icons/offers/tooth.svg">
                </div>
                <div class="welcome-pack__item__description">
                    <div i18n class="welcome-pack__item__description__title">Mundhygiene
                        <br> <span class="text">(im Wert von 60 €)</span>
                    </div>
                </div>
            </div>
            <div class="welcome-pack__item">
                <div class="welcome-pack__item__icon">
                    <img src="assets/icons/offers/bus.svg">
                </div>
                <div class="welcome-pack__item__description">
                    <div i18n class="welcome-pack__item__description__title">Transfer vom Flughafen Wien oder Flughafen
                        Bratislava
                        <br> <span class="text">(im Wert von 120 €)</span>
                    </div>
                </div>
            </div>
            <div class="welcome-pack__item">
                <div class="welcome-pack__item__icon">
                    <img src="assets/icons/offers/x-ray.svg">
                </div>
                <div class="welcome-pack__item__description">
                    <div i18n class="welcome-pack__item__description__title">1 Übernachtung in unserem exklusiven
                        Appartementhaus oder in unserer exklusiven Pension.*
                        <br>
                        <span class="text">(im Wert von 40 € + 30 € Reinigungsgebühr)</span>
                    </div>
                </div>
            </div>
            <div class="welcome-pack__item" *ngIf="locale === 'de'">
                <div class="welcome-pack__item__icon">
                    <img src="assets/icons/offers/tooth.svg">
                </div>
                <div class="welcome-pack__item__description">
                    <div i18n class="welcome-pack__item__description__title">Stempel über Zahnkontrolle im Bonusheft
                    </div>
                </div>
            </div>
        </div>

        <div class="welcome-pack__price-container" *ngIf="welcomePackage != undefined">
            <div class="welcome-pack__price-card">
                <div i18n class="title">Für 1 Person</div>
                <div class="title-chevron">
                    <div class="left"></div>
                    <div class="right"></div>
                </div>
                <div class="price-tag">
                    <span class="price"><span class="bold">{{welcomePackage.per_person}}</span> €</span>
                </div>
            </div>
            <div class="welcome-pack__price-card">
                <div i18n class="title">Für 1 Person mit Begleiter</div>
                <div class="title-chevron">
                    <div class="left"></div>
                    <div class="right"></div>
                </div>
                <div class="price-tag">
                    <span class="price"><span class="bold">{{welcomePackage.per_person_plus_one}}</span> €</span>
                </div>
            </div>
            <div class="welcome-pack__price-card">
                <div i18n class="title">Für 2 Personen</div>
                <div class="title-chevron">
                    <div class="left"></div>
                    <div class="right"></div>
                </div>
                <div class="price-tag">
                    <span class="price"><span class="bold">{{welcomePackage.per_two_people}}</span> €</span>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <p i18n>*Gültig nur für Patient(inn)en, die aus größerer Entfernung (mehr als 250 km) zu uns kommen.
        </p>

        <p i18n class="margin-top">Unsere Zahnärzte haben bereits mehr als 50.000 Patient(inn)en aus dem deutschsprachigen Raum behandelt,
            die
            schon ein völlig neues Lebensgefühl mit ihren schönen, neuen Zähnen genießen. </p>
        <app-contact-form></app-contact-form>
    </div>
</div>
<div class="content--bottom">
    <img src="assets/images/offers/bottom/Angebote-bottom.jpg">
</div>

