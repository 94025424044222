import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';

@Component({
  selector: 'app-arrival',
  templateUrl: './arrival.component.html',
  styleUrls: ['./arrival.component.scss']
})
export class ArrivalComponent implements OnInit {

  constructor(
      @Inject(LOCALE_ID) public locale: string,
  ) { }

  ngOnInit(): void {
  }

}
