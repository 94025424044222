<ng-container *ngIf="offer">
    <div class="content--top" *ngIf="offer">
        <div class="content--top__title">
            <div class="content-container">
                <h1>{{ offer.title }}</h1>
            </div>
        </div>
        <div class="content--top__lead">
            <div class="content-container">
                <div class="content--top__lead__container">
                    <div class="content--top__lead__image">
                        <img src="{{offer.main_picture.largeUrl}}"
                             alt="{{offer.main_picture.alternativeText}}">
                    </div>
                    <div class="content--top__lead__title">
                        <h2>{{ offer.subtitle }}</h2>
                        <div class="content--top__lead__title__separator"></div>
                    </div>
                    <div class="content--top__lead__description" >
                        <div [innerHTML]="richtextService.trustHTML(changeListStyle(offer.lead))"></div>
                        <app-top-navigation></app-top-navigation>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content--main">
        <div class="content-container">
            <ng-container *ngFor="let content of offer.blog_text">
                <div class="content__accordions" *ngIf="content.__component === 'blog.accordion'">
                    <app-offer-accordion *ngFor="let item of content.fields" [accordion]="item"></app-offer-accordion>
                </div>

                <a *ngIf="content.__component === 'blog.link'" class="navigation-button" routerLink="{{content.link}}">
                    <span i18n class="navigation-button__label">weiterlesen</span>
                    <div class="navigation-button__arrow">
                        <img src="assets/icons/navigation-arrow.svg">
                    </div>
                </a>

                <div *ngIf="content.__component === 'blog.root-text'" [innerHTML]="richtextService.trustHTML(changeListStyle(content.text))"></div>
            </ng-container>
        </div>
        <app-contact-form></app-contact-form>
    </div>
    <div class="content--bottom">
        <img src="{{offer.mood_picture.largeUrl}}" alt="{{offer.mood_picture.alternativeText}}">
    </div>
</ng-container>
