<ng-container *ngIf="coronavirus">
    <div class="content--top">
        <div class="content--top__title">
            <div class="content-container">
                <h1>{{ coronavirus.title }}</h1>
            </div>
        </div>
        <div class="content--top__lead">
            <div class="content-container">
                <div class="content--top__lead__container">
                    <div class="content--top__lead__image">
                        <img src="{{coronavirus.main_picture.largeUrl}}"
                             alt="{{coronavirus.main_picture.alternativeText}}">
                    </div>
                    <div class="content--top__lead__title">
                        <h2>{{ coronavirus.subtitle }}</h2>
                        <div class="content--top__lead__title__separator"></div>
                    </div>
                    <div class="content--top__lead__description" [innerHTML]="richtextService.trustHTML(changeListStyle(coronavirus.lead))">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content--main">
        <div class="content-container">
            <div class="content__accordions">
                <div class="content__accordion" *ngFor="let accordion of coronavirus.accordion">
                    <input type="checkbox" id="chck-{{accordion.id}}">
                    <label class="content__accordion__label" for="chck-{{accordion.id}}">
                        {{ accordion.title }}
                        <img src="assets/icons/accordion.svg">
                    </label>
                    <div class="content__accordion__content">
                        <div [innerHTML]="richtextService.trustHTML(accordion.text)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-container wide">
            <app-contact-form></app-contact-form>
        </div>
    </div>
    <div class="content--bottom">
        <img src="{{coronavirus.mood_picture.largeUrl}}" alt="{{coronavirus.mood_picture.alternativeText}}">
    </div>
</ng-container>
