import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {WelcomePackage, WelcomePackageSingletonWrapper} from "../models/welcome-package.model";
import {LanguageService} from "./language.service";
import {identity, map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class WelcomePackageService {
    constructor(private http: HttpClient, private lang: LanguageService) {
    }

    public getWelcomePackage(): Observable<WelcomePackage> {
        return this.http
            .get<WelcomePackageSingletonWrapper>(
                environment.apiUrl +
                "welcome-package?locale=" +
                this.lang.getLang()
            )
            .pipe(map((value) => value.data.attributes))
            .pipe(
                map((value) => ({
                    per_person: value.per_person,
                    per_person_plus_one: value.per_person_plus_one,
                    per_two_people: value.per_two_people,
                }))
            );

    }
}
