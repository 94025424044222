import {Component, HostListener, OnInit} from '@angular/core';
import {Gallery} from "../../../core/models/gallery.model";
import {GalleryService} from "../../../core/services/gallery.service";

@Component({
  selector: 'app-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.scss']
})
export class GalleryListComponent implements OnInit {

  galleries: Gallery[] = [];
  screenWidth = 0;

  constructor(
      private galleryService: GalleryService
  ) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.galleryService.getGalleries().subscribe( (value) => {
      this.galleries=value;
    })
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
  }

}
