import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {ImageUrlService} from "./image-url.service";
import {map, Observable} from "rxjs";
import {Coronavirus, CoronavirusWrapper} from "../models/coronavirus.model";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class CoronavirusService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService,
        private imageUrlService: ImageUrlService
    ) {
    }

    public getCoronavirus(): Observable<Coronavirus> {
        return this.http
            .get<CoronavirusWrapper>(
                environment.apiUrl +
                "coronavirus?populate=*&locale=" +
                this.lang.getLang()
            )
            .pipe(map((value) => ({
                id: value.data.id,
                title: value.data.attributes.title,
                subtitle: value.data.attributes.subtitle,
                lead: value.data.attributes.lead,
                main_picture: {
                    alternativeText: value.data.attributes.main_picture.data.attributes.alternativeText,
                    largeUrl: this.imageUrlService.getImageUrl(value.data.attributes.main_picture.data.attributes)
                },
                mood_picture: {
                    alternativeText: value.data.attributes.mood_picture.data.attributes.alternativeText,
                    largeUrl: this.imageUrlService.getImageUrl(value.data.attributes.mood_picture.data.attributes)
                },
                accordion: value.data.attributes.accordion,
            })))
    }
}
