import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss']
})

export class GalleryDialogComponent {
  constructor(
      public config: NgbCarouselConfig,
      private dialogRef: MatDialogRef<GalleryDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    config.interval = 0;
  }
  ngOnInit(): void {
  }

}
