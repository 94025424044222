import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from "@angular/common/http";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './core/components/navbar/navbar.component';
import {FooterComponent} from './core/components/footer/footer.component';
import {FreeTransportComponent} from './feature/company-services/components/free-transport/free-transport.component';
import {FreeExaminationComponent} from './feature/company-services/components/free-examination/free-examination.component';
import {FreeAccomodationComponent} from './feature/company-services/components/free-accomodation/free-accomodation.component';
import {WeekendTreatmentsComponent} from './feature/company-services/components/weekend-treatments/weekend-treatments.component';
import {HomepageComponent} from './feature/homepage/homepage.component';
import {FixedTeethComponent} from './feature/treatments/fixed-teeth/fixed-teeth.component';
import {StegprotheseComponent} from './feature/treatments/stegprothese/stegprothese.component';
import {DentalImplantsComponent} from './feature/treatments/dental-implants/dental-implants.component';
import {RemovableBracesComponent} from './feature/treatments/removable-braces/removable-braces.component';
import {DentalCrownComponent} from './feature/treatments/dental-crown/dental-crown.component';
import {DentalBridgeComponent} from './feature/treatments/dental-bridge/dental-bridge.component';
import {DentureComponent} from './feature/treatments/denture/denture.component';
import {FillingsComponent} from './feature/treatments/fillings/fillings.component';
import {VeneerComponent} from './feature/treatments/veneer/veneer.component';
import {CombinedDenturesComponent} from './feature/treatments/combined-dentures/combined-dentures.component';
import {PanoramicXrayComponent} from './feature/treatments/panoramic-xray/panoramic-xray.component';
import {ListOfTreatmentsComponent} from './feature/treatments/list-of-treatments/list-of-treatments.component';
import {CadCamComponent} from './feature/treatments/cad-cam/cad-cam.component';
import {VolumeTopographyComponent} from './feature/treatments/volume-topography/volume-topography.component';
import {EmsDentalSpaComponent} from './feature/treatments/ems-dental-spa/ems-dental-spa.component';
import {FixedBracesComponent} from './feature/treatments/fixed-braces/fixed-braces.component';
import {HygieneComponent} from './feature/treatments/hygiene/hygiene.component';
import {HealthInsuranceComponent} from './feature/prices/health-insurance/health-insurance.component';
import {WarrantyComponent} from './feature/prices/warranty/warranty.component';
import {AdvantagesComponent} from './feature/about-us/advantages/advantages.component';
import {OurClinicComponent} from './feature/about-us/our-clinic/our-clinic.component';
import {DentalLaboratoryComponent} from './feature/about-us/dental-laboratory/dental-laboratory.component';
import {PriceExamplesComponent} from './feature/prices/price-examples/price-examples.component';
import {WelcomePackageComponent} from './feature/offers/welcome-package/welcome-package.component';
import {OfferDetailedComponent} from './feature/offers/offer-detailed/offer-detailed.component';
import {OfferAccordionComponent} from './shared/components/offer-accordion/offer-accordion.component';
import {BlogListComponent} from './feature/blog/blog-list/blog-list.component';
import {BlogPageComponent} from './feature/blog/blog-page/blog-page.component';
import {BlogCardComponent} from './shared/components/blog-card/blog-card.component';
import {BottomHelpComponent} from './shared/components/bottom-help/bottom-help.component';
import {OurTeamComponent} from './feature/about-us/our-team/our-team.component';
import {ArrivalComponent} from './feature/contact/arrival/arrival.component';
import {FaqComponent} from "./feature/about-us/faq/faq.component";
import {GalleryPageComponent} from './feature/gallery/gallery-page/gallery-page.component';
import {GalleryListComponent} from './feature/gallery/gallery-list/gallery-list.component';
import {IvyCarouselModule} from "angular-responsive-carousel";
import {NgbCarouselModule, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {GalleryDialogComponent} from './shared/components/gallery-dialog/gallery-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ContactComponent} from "./feature/contact/contact/contact.component";
import {ContactFormComponent} from "./shared/components/contact-form/contact-form.component";
import {CallbackComponent} from './feature/contact/callback/callback.component';
import {ThankYouComponent} from './shared/components/thank-you/thank-you.component';
import {CallbackFormComponent} from './shared/components/callback-form/callback-form.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatSelectFilterModule} from 'mat-select-filter';
import { ActualInformationsComponent } from './shared/components/actual-informations/actual-informations.component';
import { FloatingContactComponent } from './shared/components/floating-contact/floating-contact.component';
import { CoronavirusComponent } from './feature/coronavirus/coronavirus.component';
import { CookieComponent } from './shared/components/cookie/cookie.component';
import { TopNavigationComponent} from "./shared/components/top-navigation/top-navigation.component";
import { UrlRedirectComponent } from './shared/components/url-redirect/url-redirect.component';
import { CookieService } from 'ngx-cookie-service';
import { AllOnFourComponent } from './feature/treatments/all-on-four/all-on-four.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        FreeTransportComponent,
        FreeExaminationComponent,
        FreeAccomodationComponent,
        WeekendTreatmentsComponent,
        HomepageComponent,
        FixedTeethComponent,
        StegprotheseComponent,
        DentalImplantsComponent,
        RemovableBracesComponent,
        DentalCrownComponent,
        DentalBridgeComponent,
        DentureComponent,
        FillingsComponent,
        VeneerComponent,
        CombinedDenturesComponent,
        PanoramicXrayComponent,
        ListOfTreatmentsComponent,
        CadCamComponent,
        VolumeTopographyComponent,
        EmsDentalSpaComponent,
        FixedBracesComponent,
        HygieneComponent,
        HealthInsuranceComponent,
        WarrantyComponent,
        AdvantagesComponent,
        OurClinicComponent,
        DentalLaboratoryComponent,
        PriceExamplesComponent,
        WelcomePackageComponent,
        OfferDetailedComponent,
        OfferAccordionComponent,
        BlogListComponent,
        BlogPageComponent,
        BlogCardComponent,
        BottomHelpComponent,
        OurTeamComponent,
        BottomHelpComponent,
        OurTeamComponent,
        ArrivalComponent,
        FaqComponent,
        GalleryPageComponent,
        GalleryListComponent,
        GalleryDialogComponent,
        OurTeamComponent,
        ArrivalComponent,
        ContactComponent,
        ContactFormComponent,
        FaqComponent,
        CallbackComponent,
        ThankYouComponent,
        CallbackFormComponent,
        ActualInformationsComponent,
        FloatingContactComponent,
        CoronavirusComponent,
        CookieComponent,
        TopNavigationComponent,
        UrlRedirectComponent,
        AllOnFourComponent
    ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      BrowserAnimationsModule,
      HttpClientModule,
      IvyCarouselModule,
      NgbModule,
      NgbCarouselModule,
      MatDialogModule,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatSelectModule,
      MatSelectFilterModule,
    ],
    providers: [CookieService],
    bootstrap: [AppComponent]
})
export class AppModule { }
