<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>SCHÖNE ZÄHNE MIT ZAHNKRONEN
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Zahnkrone-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>DIE ZIRKONKRONE WIRD ALS KÖNIGIN DER KRONEN BEZEICHNET
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n><span class="bold">Die Zahnärzte unserer Zahnklinik empfehlen Ihnen Zirkonkronen </span>aus
                        optischen Gründen, insbesondere
                        für die Behandlung der sichtbaren Frontzähne. Zahnkronen und -brücken aus Zirkon überzeugen
                        durch Ästhetik und einzigartige Langlebigkeit.</p>

                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>Schöne, gesunde Zähne waren schon immer ein Statussymbol </h1>
            <p i18n>Viele Menschen leiden unter beschädigten,
                krummen oder verfärbten Zähnen und schämen sich, richtig zu lachen. Unsere Zahnärzte bieten Ihnen mit
                Zahnkronen hierfür eine perfekte Lösung.
            </p>
            <div class="content--main__information__image-container">
                <img src="assets/images/treatments/main/Zahnkrone-main1.png">
                <img src="assets/images/treatments/main/Zahnkrone-main2.png">
            </div>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    VORTEILE VON ZIRKONKRONEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">hohe Ästhetik, natürliche Schönheit
                        </div>
                        <div i18n class="content--main__content__list__item">harmonisches Gesamtbild
                        </div>
                        <div i18n class="content--main__content__list__item">langjährige, klinisch dokumentierte Qualität
                        </div>
                        <div i18n class="content--main__content__list__item">hohe Festigkeitswerte und Stabilität, brechen
                            bei starker Belastung sehr viel seltener als andere, für Kronen verwendetet Materialien
                        </div>
                        <div i18n class="content--main__content__list__item">langlebige Zahnrestauration
                        </div>
                        <div i18n class="content--main__content__list__item">Verwendung hochwertiger Rohstoffe
                        </div>
                        <div i18n class="content--main__content__list__item">Erhalt der gesunden Zahnsubstanz
                        </div>
                        <div i18n class="content--main__content__list__item">hohe Körperverträglichkeit, auch für Patienten
                            mit Metallallergie geeignet, da Zirkon ein nichtmetallischer Werkstoff ist
                        </div>
                        <div i18n class="content--main__content__list__item">geringe Warm-Kalt-Leitfähigkeit</div>
                        <div i18n class="content--main__content__list__item">gesunde Mundflora</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    WANN KOMMEN ZAHNKRONEN ZUM EINSATZ?

                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Kronen sind ideale Lösungen, um Zähne, die erkrankt oder beschädigt sind, zu reparieren. Durch
                        eine Zahnkrone erhält Ihr Zahn seine ursprüngliche, natürliche Form und Stabilität zurück.
                        Zahnkronen und Brücken geben Ihnen Ihr Lächeln zurück.
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    WIR BIETEN KRONEN AUS VERSCHIEDENEN MATERIALIEN AN
                    <img alt="content accordion icon" i18n-alt src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <h4 i18n>Metallkeramikkrone</h4>
                    <p i18n>Sie besteht aus einem Metallgerüst, das mit Keramik in der entsprechenden Zahnfarbe belegt wird.
                        Das Metallgerüst ist eine Chrom-Kobalt-Legierung und ist nickel- sowie allergiefrei.
                    </p>
                    <h4 i18n class="top-margin">Goldkeramikkrone</h4>
                    <p i18n>Goldkeramikkronen bestehen aus einer goldhaltigen Legierung mit Keramikbeschichtung. Dadurch
                        bieten sie ausgezeichnete Kaustabilität und gehören zu den verträglichsten Kronen für den
                        Körper.
                    </p>
                    <h4 i18n class="top-margin">Vollkeramik und Zirkonkrone</h4>
                    <p i18n>Metallfreie Kronen mit einem Gerüst aus Zirkoniumoxyd verfügen über ausgezeichnete Funktionen und
                        eine hohe Ästhetik. Sie sind absolut biokompatibel, da sie kein Metall enthalten.</p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    WIE LANGE DAUERT DIE BEHANDLUNG?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Konsultation, Erstuntersuchung, Vorbereitung der Zähne,
                                    Abdrucknahme, Einsetzen der provisorischen Krone
                                </div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Nach einer Woche: Gerüstprobe
                                </div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div i18n class="information__title">Fertigstellung nach einer weiteren Woche
                                </div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                    </div>
                    <p><span i18n class="bold">Diese Angaben dienen nur Ihrer Orientierung!</span><ng-template i18n> Wir planen die
                        Behandlungsschritte für jeden Patienten individuell unter Beachtung der persönlichen Anliegen
                        sowie unter Berücksichtigung des Mundzustandes.</ng-template>
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    WAS KOSTET EINE ZAHNKRONE?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-price">
                        <div class="text">
                            <p i18n>Bei einer Behandlung mit Krone(n) sollen Sie mit weiteren Kosten rechnen wie z.B.:
                                Panorama-Röntgenaufnahme(n), temporäre Krone(n) (Provisorium).
                            </p>
                            <p i18n>Die Gesamtkosten beim Einbringen einer Krone werden von mehreren Elementen, z.B. von der
                                Zahl der betroffenen Zähne und vom Typ der Krone, beeinflusst. Erst nach einer
                                Erstuntersuchung können wir Ihnen einen individuellen Heil- und Kostenplan erstellen.
                            </p>

                        </div>
                        <div class="price-card">
                            <div i18n class="title">Eine Zahnkrone kostet </div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span class="price"><span class="bold">200</span> €</span>
                                <span class="price"><span class="pre" i18n>bis</span><span class="bold">350</span> €</span> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/5.jpg">
</div>

