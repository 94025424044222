<div class="footer" [ngClass]="isPrimary ? 'primary' : 'secondary'">
    <div class="footer__illustration">
        <img src="assets/illustrations/footer.svg">
    </div>
    <div class="footer-container">
        <div class="footer__top">
            <div class="footer__top__icons">
                <img *ngIf="locale === 'de'" class="footer__top__logo de" src="assets/eurodent-logo.svg">
                <img *ngIf="locale === 'en'" class="footer__top__logo en" src="assets/eurodent-logo-en.png">
                <img *ngIf="locale === 'fr'" class="footer__top__logo fr" src="assets/eurodent-logo-fr.png">
                <img *ngIf="locale === 'it'" class="footer__top__logo it" src="assets/eurodent-logo-it.png">
                <div class="footer__top__iso">
                    <img src="assets/NQA_ISO9001_CMYK.jpg">
                    <img src="assets/NQA_ISO14001_CMYK.jpg">
                </div>
                <a class="footer__top__facebook" target="_blank" href="https://www.facebook.com/EurodentZahnklinik/">
                    <img src="assets/icons/facebook.svg">
                </a>
            </div>
            <div class="footer__top__information">
                <div class="footer__top__information__item address">
                    <div class="footer__top__information__item__icon">
                        <img src="assets/icons/information/house.svg">
                    </div>
                    <div class="footer__top__information__item__details">
                        <div class="footer__top__information__item__title">H-9200</div>
                        <div class="footer__top__information__item__text">Mosonmagyaróvár,<br>
                            Győrikapu utca 7.
                        </div>
                    </div>
                </div>
                <div class="footer__top__information__item email">
                    <div class="footer__top__information__item__icon">
                        <img src="assets/icons/information/envelop.svg">
                    </div>
                    <div class="footer__top__information__item__details">

                        <div class="footer__top__information__item__title">E-mail</div>
                        <a class="footer__top__information__item__text" href="mailto:eurodent@eurodent.hu">eurodent@eurodent.hu</a>
                    </div>
                </div>
                <div class="footer__top__information__item phone">
                    <div class="footer__top__information__item__icon">
                        <img src="assets/icons/information/phone.svg">
                    </div>
                    <div class="footer__top__information__item__details">
                        <div i18n class="footer__top__information__item__title">Nulltarif</div>
                        <a i18n class="footer__top__information__item__text" href="tel:0080010007000">00 800 1000 7000</a>
                        <div i18n class="footer__top__information__item__title">Festnetz-Nummer</div>
                        <a class="footer__top__information__item__text" href="tel:003696578250">00 36 96 578 250</a>
                    </div>
                </div>
                <div class="footer__top__information__item opening">
                    <div class="footer__top__information__item__icon">
                        <img src="assets/icons/information/clock.svg">
                    </div>
                    <div class="footer__top__information__item__details">
                        <div i18n class="footer__top__information__item__title">Öffnungszeiten</div>
                        <div class="footer__top__information__item__text" *ngIf="opening" [innerHTML]="richtextService.trustHTML(opening.default_openning)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__separator"></div>
        <div class="footer__bottom">
            <div class="footer__bottom__controls">
                <a i18n class="footer__bottom__controls__privacy-policy" *ngIf="privacy" target="_blank" rel=noopener href="{{privacyLink}}">Datenschutzerklärung</a>
                <div class="footer__bottom__controls__separator"></div>
                <a i18n class="footer__bottom__controls__privacy-policy" *ngIf="privacy" target="_blank" rel=noopener href="{{privacyLinkHun}}">Adatkezelési tájékoztató</a>
                <div class="footer__bottom__controls__separator"></div>
                <a i18n class="footer__bottom__controls__sitemap" target="_blank" href="#">Sitemap</a>
            </div>
            <div i18n class="footer__bottom__copyright">2021. Copyright © Eurodent Zahnklinik Ungarn</div>
        </div>
    </div>
</div>
