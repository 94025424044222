<a class="content--top__lead__navigation" routerLink="{{routesService.getRouterLink('contact')}}" fragment="webform">
    <div class="content--top__lead__navigation__text">
        <span i18n class="main">Fordern Sie unser Preisangebot an!</span>
        <span *ngIf="locale === 'it'" i18n class="main">Senden Sie uns eine Übersicht oder ein in Italien erstelltes Angebot und wir senden Ihnen kostenlos und unverbindlich ein von uns erstelltes Angebot zu.</span>
        <span i18n class="sub">Klicken Sie hier!</span>
    </div>
    <div class="content--top__lead__navigation__arrow">
        <img src="assets/icons/navigation-arrow.svg">
    </div>
</a>
