import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

@Injectable({providedIn: 'root'})
export class DialogService {

    constructor(
        private dialog: MatDialog
    ) {
    }

    openModalWithParams(component: any, params: any, disableClose = false): Observable<any> {
        const dialogRef = this.dialog.open(component,
            {
                backdropClass: 'dark-backdrop',
                panelClass: 'dialog-panel',
                // tslint:disable-next-line:object-literal-shorthand
                disableClose: disableClose,
                data: params,
            });
        return dialogRef.afterClosed();
    }
}
