import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {RoutesService} from "../../../core/services/routes.service";

@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.scss']
})
export class WarrantyComponent implements OnInit {

  constructor(
      public routesService: RoutesService,
  ) { }

  ngOnInit(): void {
  }

}
