import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Output, EventEmitter} from '@angular/core';
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {CountrycodeService} from "../../../core/services/countrycode.service";
import {PrivacyPolicy} from "../../../core/models/privacy-policy.model";
import {PrivacyPolicyService} from "../../../core/services/privacy-policy.service";
import {FormService} from "../../../core/services/form.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {RoutesService} from "../../../core/services/routes.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-callback-form',
    templateUrl: './callback-form.component.html',
    styleUrls: ['./callback-form.component.scss']
})
export class CallbackFormComponent implements OnInit {
    callbackForm: FormGroup = this.formBuilder.group({
        full_name: [null, [Validators.required]],
        country: [null, [Validators.required]],
        area: [null, [Validators.required]],
        number: [null, [Validators.required]],
    });
    errors = new Map();

    @Output() formIsSent = new EventEmitter<boolean>();

    placeholderList = [
        {lang: "de", placeholderName: "name", placeholderText: "Ihr Name"},
        {lang: "en", placeholderName: "name", placeholderText: "Your name"},
        {lang: "it", placeholderName: "name", placeholderText: "Il tuo nome"},
        {lang: "fr", placeholderName: "name", placeholderText: "Votre nom"},
    ]

    countryCodes: string[] = [];

    privacy: PrivacyPolicy | undefined;

    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private countryCodeService: CountrycodeService,
        private privacyPolicyService: PrivacyPolicyService,
        private formService: FormService,
        @Inject(LOCALE_ID) public locale: string,
        public routesService: RoutesService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.countryCodeService.getCountryCodes().subscribe((value) => {
            this.countryCodes = value;
        });
        this.privacyPolicyService.getPrivacyPolicy().subscribe((value) => {
            this.privacy = value;
        });
    }

    formIsValid(): boolean {
        return this.callbackForm.valid;
    }

    getPlaceholders(placeholderName: string) {
        return this.placeholderList.find((x: { placeholderName: string; lang: string; }) => x.placeholderName === placeholderName && x.lang === this.locale)?.placeholderText;
    }

    getFormValidationErrors() {
        this.errors = new Map();
        Object.keys(this.callbackForm.controls).forEach((key) => {
            const controlErrors = this.callbackForm.get(key)?.errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    this.errors.set(key, keyError);
                });
            }
        });
    }

    hasError(controlName: string, errorName: string): boolean {
        return this.errors.get(controlName) === errorName;
    }

    checkValidation(event: any) {
        const formControl = this.callbackForm.controls[event.target.name];
        switch (event.target.name) {
            case "full_name":
                if (formControl.hasError("required")) {
                    this.errors.set(event.target.name, "required");
                } else {
                    this.errors.delete(event.target.name);
                }
                break;
            case "country":
                if (formControl.hasError("required")) {
                    this.errors.set(event.target.name, "required");
                } else {
                    this.errors.delete(event.target.name);
                }
                break;
            case "area":
                if (formControl.hasError("required")) {
                    this.errors.set(event.target.name, "required");
                } else {
                    this.errors.delete(event.target.name);
                }
                break;
            case "number":
                if (formControl.hasError("required")) {
                    this.errors.set(event.target.name, "required");
                } else {
                    this.errors.delete(event.target.name);
                }
                break;
        }
    }

    onSubmit() {
        if (this.formIsValid()) {
            this.http.get(environment.ipApiUrl).subscribe((data: any) => {
                let callbackForm = {
                    full_name: this.callbackForm.controls["full_name"].value,
                    country: this.callbackForm.controls["country"].value,
                    area: this.callbackForm.controls["area"].value,
                    number: this.callbackForm.controls["number"].value,
                    ip: data.ip,
                    url: document.location.host,
                }
                this.formService.postCallbackForm(callbackForm).subscribe();
                this.router.navigate([this.routesService.getRouterLink('callback-thankyou')]);
            })
        } else {
            this.getFormValidationErrors();
        }

    }
}
