import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class FormService {
    constructor(
        private http: HttpClient,
        @Inject(LOCALE_ID) public locale: string
    ) {
    }

    public postContactForm(payload: any, files: File[] | undefined): Observable<any> {
        const formData = new FormData();

        // get data from json payload
        Object.entries(payload).forEach(([key, value]: any) => {
            formData.append(key, value);
        });

        files?.forEach((file: File, index: number) => {
            if (file !== undefined) {
                formData.append('file' + index, file);
            }
        });

        return this.http.post<any>(environment.apiUrl + "connect?locale=" + this.locale, formData);
    }

    public postCallbackForm(payload: any): Observable<any> {
        return this.http.post<any>(environment.apiUrl + "callback?locale=" + this.locale, payload);
    }

}
