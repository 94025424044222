import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';

@Component({
  selector: 'app-removable-braces',
  templateUrl: './removable-braces.component.html',
  styleUrls: ['./removable-braces.component.scss']
})
export class RemovableBracesComponent implements OnInit {

  constructor(
      @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit(): void {
  }

}
