import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {ImageUrlService} from "./image-url.service";
import {Meta, Title} from "@angular/platform-browser";
import {Seo} from "../models/seo.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Injectable({
    providedIn: "root",
})

export class MetatagService {
    actualRoute = "";

    constructor(
        private titleService: Title,
        private metaService: Meta,
        private route: ActivatedRoute,
        @Inject(LOCALE_ID) public locale: string,
        private router: Router,
    ) {
    }

    meta: any = {
        homepage: {
            de: {
                title: 'Eurodent Zahnklinik Ungarn |  30 Jahre Experise',
                description: 'Die Zahnklinik in Ungarn für Zahnbehandlungen und Implantate. Erfahrenes Zahnarzt Team mit hervorragender Ausstattung und bester Qualität zu günstigen Preisen.',
            },
            en: {
                title: 'Eurodent Dental Clinic Hungary - Mosonmagyaróvár',
                description: 'Eurodent Dental Clinic Hungary | The best cosmetic dentistry and implantology clinic provides dental treatments for reasonable prices in Hungary.',
            },
            it: {
                title: 'Clinica Dentale Ungheria - Implantologia Dentale Ungheria',
                description: 'Eurodent Implantologia Dentale Ungheria - Impianto dentale | Impianti dentali, Protesi, Corone, Ponti. Studio odontoiatrico Ungheria',
            },
            fr: {
                title: 'Eurodent clinique dentaire et implantaire | 30 ans d\'expertise',
                description: 'Eurodent clinique dentaire et implantaire en Hongrie avec 30 ans d\'expertise. Équipe de dentistes expérimentés disposant d\'une qualité supérieure à des prix avantageux.',
            },
        },
        blog: {
            de: {
                title: 'Unsere Klinik - Eurodent Zahnklinik Ungarn',
                description: 'Unsere Klinik - Bei EURODENT Ungarn Zahn- und Implantatzentrum gibt eine Antwort auf alle Ihre Zahnprobleme.',
            },
            en: {
                title: 'Dental Clinic Hunary Blog',
                description: 'Be up-to-date with the latest news and special offers through the Eurodent Dental Clinic Hungary Blog',
            },
            it: {
                title: 'Clinica Dentale Ungheria Blog',
                description: 'Siate aggiornati sulle ultime novità e sulle offerte speciali attraverso il blog della Eurodent Clinica dentale Ungheria.',
            },
            fr: {
                title: 'Clinique dentaire Hongrie Blog',
                description: 'Soyez au courant des dernières nouvelles et des offres spéciales grâce au blog de l\' Eurodent Clinique Dentaire Hongrie.',
            },
        }
    }

    updateMetaTags(seo: Seo) {
        if (seo) {
            this.titleService.setTitle(seo.metaTitle);
            this.metaService.updateTag({
                name: "description",
                content: seo.metaDescription,
            });
            if (seo.keywords != null) {
                this.metaService.updateTag({
                    name: "keywords",
                    content: seo.keywords,
                });
            }
            if (seo.keywords != null) {
                this.metaService.updateTag({
                    name: "robots",
                    content: seo.metaRobots,
                });
            }
            if (seo.keywords != null) {
                // width=device-width, initial-scale=1 needs because of responsive sitebuild
                this.metaService.updateTag({
                    name: "viewport",
                    content: "width=device-width, initial-scale=1, " + seo.metaViewport,
                });
            }
            if (seo.canonicalURL) {
                const element: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`);
                element!.setAttribute('href', seo.canonicalURL);
            } else {
                const element: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`);
                element!.setAttribute('href', this.actualRoute);
            }
        } else {
            this.titleService.setTitle("Eurodent");
        }
    }

    updateStaticPageMetaTags() {
        let route = this.getChild(this.route);
        let data = route.snapshot.data;

        if (data.type) {
            //homepage and blog meta
            this.titleService.setTitle(this.meta[data.type][this.locale].title);
            this.metaService.updateTag({
                name: "description",
                content: this.meta[data.type][this.locale].description,
            });
        } else {
            // meta from routing
            if (data.title !== undefined) {
                this.titleService.setTitle(data.title);
            }
            if (data.description) {
                this.metaService.updateTag({
                    name: "description",
                    content: data.description,
                });
            } else {
                this.metaService.updateTag({
                    name: "description",
                    content: "",
                });
            }
        }
        this.metaService.updateTag({
            name: "keywords",
            content: "eurodent",
        });
        const element: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`);
        element!.setAttribute('href', this.actualRoute);
    }

    getChild(activatedRoute: ActivatedRoute): any {
        if (activatedRoute.firstChild)
            return this.getChild(activatedRoute.firstChild);
        else return activatedRoute;
    }

    setActualRoute(ac: string) {
        this.actualRoute = ac;
    }
}


