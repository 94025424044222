import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Faq, FaqWrapper} from "../models/faq.model";
import {Opening, OpeningWrapper} from "../models/opening.model";

@Injectable({
    providedIn: "root",
})
export class OpeningService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService
    ) {
    }

    public getOpening(): Observable<Opening> {
        return this.http.get<OpeningWrapper[]>(
            environment.apiUrl +
            "opening-hour?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) => {
                return value[0];
            }))
            .pipe(map((item) => ({
                    id: item.id,
                    default_openning: item.default_openning,
                    holiday_hours: {
                        id: item.holiday_hours.id,
                        opening_hours: item.holiday_hours.opening_hours,
                        from: item.holiday_hours.from,
                        to: item.holiday_hours.to
                    }
                }))
            );
    }
}
