import {Injectable} from "@angular/core";
import {ImageAttributeWrapper} from "../models/image.model";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})

export class ImageUrlService {

    getImageUrl(item: ImageAttributeWrapper): string {
        if (item == null) {
            return "/assets/icons/employee-placeholder.svg";
        } else if (item.formats?.large?.url) {
            return environment.backendBaseUrl + item.formats.large.url;
        } else {
            return environment.backendBaseUrl + item.url;
        }
    }
}
