import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {ActualOffer, ActualOffersWrapper, Review, ReviewsWrapper, Newness, NewsWrapper} from "../models/homepage.model";
import {environment} from "../../../environments/environment";
import {ImageUrlService} from "./image-url.service";

@Injectable({
    providedIn: "root",
})
export class HomepageService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService,
        private imageUrlService: ImageUrlService,
    ) {
    }

    public getActualOffers(): Observable<ActualOffer[]> {
        return this.http.get<ActualOffersWrapper[]>(
            environment.apiUrl +
            "actual-offer?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) => {
                return value[0];
            }))
            .pipe(map((item) =>
            item.actual_offers.map((offer) => ({
                id: offer.id,
                title: offer.title,
                description: offer.description,
                link: offer.link,
                icon: environment.backendBaseUrl + offer.icon.url,
            }))))
    }
    public getReviews(): Observable<Review[]> {
        return this.http.get<ReviewsWrapper[]>(
            environment.apiUrl +
            "social-comment?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) => {
                return value[0];
            }))
            .pipe(map((item) =>
            item.comments.map((comment) => ({
                id: comment.id,
                comment_from: comment.comment_from,
                date: comment.date,
                name: comment.name,
                rating: comment.rating,
                link: comment.link,
            }))))
    }

    public getNews(): Observable<Newness[]> {
        return this.http.get<NewsWrapper[]>(
            environment.apiUrl +
            "newness?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) => {
                return value[0];
            }))
            .pipe(map((value) =>
            value.newness.map((item) => ({
                id: item.id,
                title: item.title,
                lead: item.description,
                slug: item.link,
                cover_image: this.imageUrlService.getImageUrl(item.cover_image),
            }))))
    }
}
