import {Component, HostListener, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {HomepageService} from "../../core/services/homepage.service";
import {ActualOffer, Newness, Review} from "../../core/models/homepage.model";
import {Gallery} from "../../core/models/gallery.model";
import {GalleryService} from "../../core/services/gallery.service";
import {PrivacyPolicyService} from "../../core/services/privacy-policy.service";
import {PrivacyPolicy} from "../../core/models/privacy-policy.model";
import {Router} from "@angular/router";
import {RoutesService} from "../../core/services/routes.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NewsletterService} from "../../core/services/newsletter.service";

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

    actualOffers: ActualOffer[] = [];
    reviews: Review[] = [];
    news: Newness[] = [];
    galleries: Gallery[] = [];
    screenWidth = 0;
    privacy: PrivacyPolicy | undefined;
    isSubscribed = false;

    newsletterForm: FormGroup = this.formBuilder.group({
        full_name: [null, [Validators.required]],
        email: [null, [Validators.required]],
    });
    errors = new Map();

    newsletterNamePlaceholder = [
        {lang: "de", placeholder: "Name"},
        {lang: "en", placeholder: "Surname"},
        {lang: "it", placeholder: "Nome e cognome"},
        {lang: "fr", placeholder: "Nom et prénom"},
    ]

    constructor(
        private http: HttpClient,
        private homepageService: HomepageService,
        private galleryService: GalleryService,
        private privacyPolicyService: PrivacyPolicyService,
        private router: Router,
        public routesService: RoutesService,
        @Inject(LOCALE_ID) public locale: string,
        private formBuilder: FormBuilder,
        private newsletterService: NewsletterService,
    ) {
    }

    ngOnInit(): void {
        this.screenWidth = window.innerWidth;
        this.homepageService.getActualOffers().subscribe((value) => {
            this.actualOffers = value;
        });
        this.galleryService.getGalleries(4).subscribe((value) => {
            this.galleries = value;
        });
        this.homepageService.getReviews().subscribe((value) => {
            this.reviews = value;
        });
        this.homepageService.getNews().subscribe((value) => {
            this.news = value;
        });
        this.privacyPolicyService.getPrivacyPolicy().subscribe((value) => {
            this.privacy = value;
        })
    }

    getNewsletterNamePlaceholder() {
        return this.newsletterNamePlaceholder.find(x =>  x.lang === this.locale)?.placeholder;
    }

    formIsValid(): boolean {
        return this.newsletterForm.valid;
    }

    getFormValidationErrors() {
        this.errors = new Map();
        Object.keys(this.newsletterForm.controls).forEach((key) => {
            const controlErrors = this.newsletterForm.get(key)?.errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    this.errors.set(key, keyError);
                });
            }
        });
    }

    hasError(controlName: string, errorName: string): boolean {
        return this.errors.get(controlName) === errorName;
    }

    checkValidation(event: any) {
        const formControl = this.newsletterForm.controls[event.target.name];
        switch (event.target.name) {
            case "full_name":
                if (formControl.hasError("required")) {
                    this.errors.set(event.target.name, "required");
                } else {
                    this.errors.delete(event.target.name);
                }
                break;
            case "email":
                if (formControl.hasError("required")) {
                    this.errors.set(event.target.name, "required");
                } else {
                    this.errors.delete(event.target.name);
                }
                break;
        }
    }

    onSubmit() {
        if (this.formIsValid()) {
            this.http.get(environment.ipApiUrl).subscribe((data: any) => {
                let callbackForm = {
                    name: this.newsletterForm.controls["full_name"].value,
                    email_address: this.newsletterForm.controls["email"].value,
                }
                this.newsletterService.postNewsletterSubscription(callbackForm).subscribe()
                this.isSubscribed = true;
            })
        } else {
            this.getFormValidationErrors();
        }

    }

    @HostListener("window:resize", ["$event"])
    onResize(event: any) {
        this.screenWidth = window.innerWidth;
    }

}
