<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>LISTE DER BEHANDLUNGEN
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Liste-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Behandlungen bei der Eurodent Zahnklinik
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Vor Beginn der Behandlung erstellen wir individuelle Heil- und Kostenpläne für alle
                        Patient(inn)en aufgrund einer aktuellen (d.h. nicht mehr als 3 Monate alten)
                        Panoramaröntgenaufnahme. Die Konsultationen und die erste Untersuchung sind in unserer Klinik
                        kostenlos. Hier finden Sie die Liste aller Behandlungen, die in unserer Praxis durchgeführt
                        werden.</p>
                    <app-top-navigation></app-top-navigation>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    KONSERVIERUNGSBEHANDLUNGEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Ästhetische Zahnfüllung (Kompositfüllungen mit hohem Glas- und Porzellangehalt)</div>
                        <div i18n class="content--main__content__list__item">Stiftzahn, Stiftzahnaufbau
                        </div>
                        <div i18n class="content--main__content__list__item">Goldinlay, -onlay
                        </div>
                        <div i18n class="content--main__content__list__item">Keramikinlay, -onlay
                        </div>
                        <div i18n class="content--main__content__list__item">Empressinlay, -onlay (Presskeramik)
                        </div>
                        <div i18n class="content--main__content__list__item">Wurzelbehandlungen: Wurzelfüllung, Wurzelfüllungsrevision
                        </div>
                        <div i18n class="content--main__content__list__item">Porzellanschale, Veneer</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    PROTHETISCHE BEHANDLUNGEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Kronen- und Brückenentfernung</div>
                        <div i18n class="content--main__content__list__item">Metallkeramikkrone (nickelfrei)</div>
                        <div i18n class="content--main__content__list__item">Goldkeramikkrone</div>
                        <div i18n class="content--main__content__list__item">Metallfreie Keramikkrone (Vollkeramik)</div>
                        <div i18n class="content--main__content__list__item">Jacketkrone (Stiftkrone)</div>
                        <div i18n class="content--main__content__list__item">Empress (Presskeramikkrone)</div>
                        <div i18n class="content--main__content__list__item">Procera (auf Basis von Zirkonium), das Material des 21. Jahrhunderts
                        </div>
                        <div i18n class="content--main__content__list__item">Provisorische Krone (kann sofort nach der Behandlung auf den Zahn gesetzt werden)
                        </div>
                        <div i18n class="content--main__content__list__item">Technische (langfristige) provisorische Krone
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    MUNDHYGIENE UND ÄSTHETISCHE EINGRIFFE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Zahnsteinentfernung </div>
                        <div i18n class="content--main__content__list__item">Zahnsteinentfernung mit Ultraschall, professionelle Mundhygiene</div>
                        <div i18n class="content--main__content__list__item">Airflow (Entfernung vom Belag mit Standstrahlgebläse) </div>
                        <div i18n class="content--main__content__list__item">Dauerhafte Zahnbleichung (Anwendung des amerikanischen Beyond-Systems)</div>
                        <div i18n class="content--main__content__list__item">Zahnschmuck</div>
                        <div i18n class="content--main__content__list__item">Zahnregulierung</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    KOMBINIERTE ODER PARTIELLE GEBISSE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">OT-CAP, Preci, Steg, CEKA-Anker, Geschiebe: unsichtbare Trageelemente</div>
                        <div i18n class="content--main__content__list__item">Teleskopkrone
                        </div>
                        <div i18n class="content--main__content__list__item">Komplettes Gebiss – mit Metallnetz oder Stegprothese mit implantierter Fixierung</div>
                        <div i18n class="content--main__content__list__item">Voll- und Teilprothese</div>
                        <div i18n class="content--main__content__list__item">Metallfreier kombinierter Zahnersatz (PEEK)</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    KIEFERCHIRURGISCHE EINGRIFFE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Parodontosebehandlung </div>
                        <div i18n class="content--main__content__list__item">Laserbehandlung</div>
                        <div i18n class="content--main__content__list__item">Wurzelspitzenresektion</div>
                        <div i18n class="content--main__content__list__item">Extraktion (mittels einfacher bzw. chirurgischer Methode)</div>
                        <div i18n class="content--main__content__list__item">Implantation – einphasig bzw. zweiphasig (Nobel BioCare, Alfa-Bio-Implantate)</div>
                        <div i18n class="content--main__content__list__item">Knochenaufbau, Knochenergänzung
                        </div>
                        <div i18n class="content--main__content__list__item">offener oder geschlossener Sinuslift</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck6">
                <label i18n class="content__accordion__label" for="chck6">
                    Diagnostik und sonstige Leistungen
                    <img alt="content accordion icon" i18n-alt src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">kostenlose Erstuntersuchung, Beratung und Konsultation</div>
                        <div i18n class="content--main__content__list__item">digitales Panoramaröntgen und digitale Statusaufnahme</div>
                        <div i18n class="content--main__content__list__item">3D-Röntgendiagnostik</div>
                        <div i18n class="content--main__content__list__item">Erstellung von Kostenvoranschlägen, Heil- und Kostenplänen</div>
                        <div i18n class="content--main__content__list__item">Kofferdammisolierung (damit kann die Behandlung mit völliger Isolierung durchgeführt werden, so gelangt z.B. kein Amalgam in den Organismus)
                        </div>
                        <div i18n class="content--main__content__list__item">Schienentherapie, Zahnregulierung</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck7">
                <label i18n class="content__accordion__label" for="chck7">
                    AM HÄUFIGSTEN DURCHGEFÜHRTE BEHANDLUNGEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Unser Hauptprofil sind Implantologie und ästhetische Zahnheilkunde, wir bieten aber auch alle anderen zahnärztlichen Leistungen an.
                    </p>
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Zahnimplantat</div>
                        <div i18n class="content--main__content__list__item">Zahnkrone</div>
                        <div i18n class="content--main__content__list__item">Zirkonkrone</div>
                        <div i18n class="content--main__content__list__item">Zahnprothese</div>
                        <div i18n class="content--main__content__list__item">Zahnbrücke</div>
                        <div i18n class="content--main__content__list__item">kombinierter Zahnersatz</div>
                        <div i18n class="content--main__content__list__item">Füllung</div>
                        <div i18n class="content--main__content__list__item">Inlay</div>
                        <div i18n class="content--main__content__list__item">Onlay</div>
                        <div i18n class="content--main__content__list__item">Veneer</div>
                        <div i18n class="content--main__content__list__item">ästhetischer Zahnersatz</div>
                        <div i18n class="content--main__content__list__item">fixe Zähne</div>
                        <div i18n class="content--main__content__list__item">All-on-4</div>
                        <div i18n class="content--main__content__list__item">Mundhygiene</div>
                        <div i18n class="content--main__content__list__item">Sedierung</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/11.jpg">
</div>

