<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Kostenloser Abholdienst</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/company-services/top/Abholdienst-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Ein exklusives Angebot für unsere Patient(inn)en!</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Wollen Sie sich bei uns behandeln lassen und es ist Ihnen nicht möglich, mit dem eigenen Auto zu
                        uns
                        zu
                        kommen?<br>
                        Sie können mit unserem Abholdienst kosten- und sorgenlos reisen.</p>

                    <div class="content--top__lead__description__list">
                        <div i18n class="content--top__lead__description__list__title">Vorteile unseres kostenlosen
                            Abholdienstes
                        </div>
                        <div i18n class="content--top__lead__description__list__item">kosten- und sorgenlos</div>
                        <div i18n class="content--top__lead__description__list__item">einfach und bequem</div>
                        <div i18n class="content--top__lead__description__list__item">sichere An- und Abreise</div>
                        <div i18n class="content--top__lead__description__list__item">unkomplizierte Reiseorganisation</div>
                        <div i18n class="content--top__lead__description__list__item">Wir übernehmen gerne auch für Sie das
                            Koffertragen
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">

    <div class="content-container">

        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    WENN SIE AUS WIEN KOMMEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image">
                        <img class="image" src="assets/images/company-services/accordion/Abholdienst-accordion1.png">
                        <div i18n class="text">Wir bieten unseren Patient(inn)en von Montag bis Freitag täglich zweimal einen
                            kostenlosen Abholdienst aus Wien an. Unser Abholdienst steht Ihnen nach vorheriger
                            Vereinbarung
                            zur Verfügung.
                        </div>
                    </div>
                    <div class="content__accordion__content__list" *ngIf="locale === 'de' || locale === 'it'">
                        <h4 class="content--main__title" i18n>Unser gratis Abholdienst aus Wien</h4>
                        <ul>
                            <li i18n>Erste Abfahrt um 9.00 Uhr, Rückfahrt gegen 14.00 Uhr (wenn alle Patienten fertig sind)
                            </li>
                            <li i18n>Zweite Abfahrt um 11.00 Uhr, Rückfahrt gegen 16.00 Uhr (wenn alle Patienten fertig sind)
                            </li>
                            <li i18n>Treffpunkt: Wien Mitte (3. Bezirk, Ecke Landstraßer-Hauptstraße und Gigergasse)</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    WENN SIE MIT DEM FLUGZEUG REISEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image">
                        <img class="image" src="assets/images/company-services/accordion/Abholdienst-accordion2.png">
                        <div class="text">
                            <h4 i18n class="content--main__title">Flughafentransfer Wien-Schwechat und Bratislava</h4>
                            <p i18n>Wenn Sie mit dem Flugzeug reisen und uns Ihre Flugdaten (Datum, Ankunftszeit und Flugnummer)
                            mitteilen, können wir Sie nach vorheriger Vereinbarung vom Flughafen Wien-Schwechat oder vom
                            Flughafen Bratislava mit unserem kostenlosen Fahrdienst abholen.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    WENN SIE MIT DEM ZUG FAHREN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image">
                        <img class="image" src="assets/images/company-services/accordion/Abholdienst-accordion3.png">
                        <div class="text">
                            <h4 i18n class="content--main__title">Taxi vom Bahnhof Mosonmagyaróvár</h4>
                            <p i18n>Wenn Sie mit dem Zug fahren, holen wir Sie gerne vom Bahnhof Mosonmagyaróvár ab. Dafür
                            brauchen
                            Sie uns nur die Ankunftszeit Ihres Zuges mitzuteilen.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <p i18n>Wir von der Eurodent Zahnklinik legen einen hohen Wert auf Ihren Komfort – nicht nur während der
            Behandlung.</p>
        <p i18n>In unseren „Zahntaxis“ können Sie sich gemütlich zurücklehnen und die Anreise stressfrei genießen.</p>
        <p i18n>Reservieren Sie einen Termin, verlangen Sie Ihr unverbindliches Angebot und bestellen Sie dazu unseren
            kostenlosen Abholdienst!</p>
        <p i18n>Zögern Sie bitte nicht, uns zu kontaktieren!</p>
        <h4 i18n class="content--main__title">Fahren Sie aus grösserer Entfernung zu uns?</h4>
        <p i18n>Für Patient(inn)en, die aus größerer Entfernung (mehr als 250 km) zu uns kommen, bieten wir kostenlose
            Unterkunftsmöglichkeiten an.
        </p>

        <a class="navigation-button" routerLink="{{routesService.getRouterLink('free-accommodation')}}">
            <span i18n class="navigation-button__label">unterkunft</span>
            <div class="navigation-button__arrow">
                <img src="assets/icons/navigation-arrow.svg">
            </div>
        </a>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/company-services/bottom/Abholdienst-bottom.jpg">
</div>

