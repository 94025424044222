import { Component, OnInit } from '@angular/core';
import { Faq} from "../../../core/models/faq.model";
import {FaqService} from "../../../core/services/faq.service";
import {RichTextService} from "../../../core/services/rich-text.service";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faq: Faq | undefined;

  constructor(
      private faqService: FaqService,
      public richtextService: RichTextService
  ) { }

  ngOnInit(): void {
    this.faqService.getQuestions().subscribe((value) => {
      this.faq = value;
    })
  }

}
