a
<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Eurodent Zahntechniklabor
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/about-us/top/Zahntechnik-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Professioneller Zahnersatz
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Zum Erfolg unserer Zahnklinik trägt auch der hervorragende technische Hintergrund bei, den unser
                        klinikeigenes Zahntechniklabor gewährleistet. Dank der kontinuierlichen Zusammenarbeit zwischen
                        unseren hochqualifizierten Zahntechnikern und unseren Zahnärzten fertigen wir Zahnersatz von
                        erstklassiger Qualität an.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <p i18n>Bei der Anfertigung von Zahnersatz stehen die persönlichen Ansprüche unserer Patient(inn)en im
                Mittelpunkt.
                Das Eurodent Zahntechniklabor bietet u. a. eine professionelle Beratung, digitale Aufnahmen und eine
                individuelle Zahnfarbbestimmung an, um die Arbeit unserer Zahnärzte zu unterstützen. Mit der Gestaltung
                von
                individuellen, zum Charakter des Gesichts passenden Formen erreichen wir eine hohe Zufriedenheit bei
                unseren
                Patient(inn)en.</p>
            <p i18n>In unserem Zahntechniklabor wird eine breite Palette von Zahnersatz, wie zum Beispiel kombinierter und
                metallfreier Keramikzahnersatz, angefertigt. Wir können Aufbausysteme für fast alle Implantattypen
                herstellen. Dank unserem mehr als 10 Jahre langen Bestehen haben wir umfassende Erfahrungen bezüglich
                der
                Implantatsysteme.</p>
            <p i18n>Die technische Ausstattung und die verwendeten Materialien unseres Zahntechniklabors entsprechen den
                höchsten Standards und den modernsten Techniken.</p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Anwendung von CAD/CAM-TECHNIK UND 3D-DRUCKER FÜR ZAHNERSaTZ
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Es ist sehr wichtig für uns, unser Zahntechniklabor permanent weiterzuentwickeln. Die
                                neuesten Technologien ermöglichen unseren Zahntechnikern, die modernsten Methoden bei
                                ihrer Arbeit anzuwenden. Wir setzen das CAD/CAM-Verfahren und einen 3D-Drucker bei der
                                Anfertigung von Zirkonkronen und Zirkonbrücken ein.
                            </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/about-us/accordion/Zahntechnik-accordion1.png">
                        </div>
                    </div>

                    <p i18n>Im Laufe des CAD/CAM-Verfahrens werden Ihre betroffenen Zähne, Ihr Kiefer und Ihr Gebiss zuerst
                        mit
                        einem Oralscanner optisch abgetastet. Danach wird mit dem Programm Dental Designer ein
                        dreidimensionales Bild erstellt und die CAD-Daten werden an eine Fräsmaschine gesendet. Als
                        dritter
                        Schritt wird Ihr Zahnersatz mit der Fräsmaschine hergestellt.</p>
                    <p i18n>Das 3D-Druckverfahren ist eine der modernsten Technologien. Es wird bei der Anfertigung von
                        Abdrücken und Provisorien verwendet und garantiert ein schnelleres und präziseres Ergebnis.
                        Diese
                        können jederzeit nochmals angefertigt werden, ohne die Zähne wiederholt einscannen zu
                        müssen.</p>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    ZAHNERSaTZ AUS KERAMIK
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Wir sind stolz auf die besonders schönen und ästhetischen Keramikzahnersätze, die in
                                unserem Zahntechniklabor hergestellt werden. Die mehr als 15 Jahre langen praktischen
                                Erfahrungen unserer Fachkollegen garantieren, dass die Kronen und Brücken in höchster
                                Qualität angefertigt werden.
                            </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/about-us/accordion/Zahntechnik-accordion2.png">
                        </div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    WAS SIND DIE VORTEILE EINES KLINIKEIGENEN ZAHNTECHNIKLABORS?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">direkter Kontakt mit den Zahntechnikern vor Ort</div>
                        <div i18n class="content--main__content__list__item">Erfüllung individueller Ansprüche in hoher Qualität</div>
                        <div i18n class="content--main__content__list__item">Zahnfarb- und Zahnfigurbestimmung vor Ort, in der Klinik oder im Zahntechniklabor</div>
                        <div i18n class="content--main__content__list__item">kurze Fertigungszeiten kleinerer Reparaturen</div>
                        <div i18n class="content--main__content__list__item">enge Kooperation zwischen Zahnärzten und Zahntechnikern</div>
                        <div i18n class="content--main__content__list__item">Geltendmachung von Garantieansprüchen vor Ort</div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    WIR ARBEITEN MIT MATERIALIEN IN ERSTKLASSIGER QUALITÄT, WIE ZUM BEISPIEL:
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">NEM-Legierung: Wirobond C, Wironit extrahart (BEGO)</div>
                        <div i18n class="content--main__content__list__item">Modellgusslegierung: Sheralit-Cylindra (Shera)</div>
                        <div i18n class="content--main__content__list__item">Keramik: Initial MC (GC), Vita Enamic (Vita)</div>
                        <div i18n class="content--main__content__list__item">Implantate: Alpha-Bio, Nobel Biocare</div>
                        <div i18n class="content--main__content__list__item">Zirkon: Copra Supreme (Whitepeaks)</div>
                        <div i18n class="content--main__content__list__item">Inlay: Gradia GC
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/about-us/bottom/1.jpg">
</div>
