<ng-container *ngIf="faq">
    <div class="content--top">
        <div class="content--top__title">
            <div class="content-container">
                <h1>{{faq.title}}</h1>
            </div>
        </div>
    </div>
    <div class="content--main">
        <div class="content-container">
            <div class="content__accordions">

                <div class="content__accordion" *ngFor="let q of faq.gyik">
                    <input type="checkbox" id="chck-{{q.id}}">
                    <label class="content__accordion__label" for="chck-{{q.id}}">
                        {{ q.title }}
                        <img src="assets/icons/accordion.svg">
                    </label>
                    <div class="content__accordion__content">
                        <div [innerHTML]="richtextService.trustHTML(q.text)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-container wide">
            <app-contact-form></app-contact-form>
        </div>
    </div>
    <div class="content--bottom">
        <img src="{{faq.mood_picture.largeUrl}}" alt="{{faq.mood_picture.alternativeText}}">
    </div>
</ng-container>
