<div class="homepage--top">
    <div class="content-container">
        <div class="homepage--top__details">
            <div class="homepage--top__title" i18n> EURODENT ZAHNKLINIK UND IMPLANTATZENTRUM</div>
            <div class="homepage--top__text" i18n>Für eine anspruchsvolle und preiswerte Zahnbehandlung in Ungarn</div>
            <a class="navigation-button" routerLink="{{routesService.getRouterLink('our-clinic')}}">
                <span class="navigation-button__label" i18n>WEITERlesen</span>
                <div class="navigation-button__arrow">
                    <img src="assets/icons/navigation-arrow.svg">
                </div>
            </a>
        </div>
        <img class="homepage--top__illustration" src="assets/images/homepage/home-illustration.svg">
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <h2 class="homepage__title" i18n>Unsere Sonderangebote</h2>
        <h5 class="homepage__subtitle" i18n>Kostenlose Leisungen und Aktionen</h5>
        <div class="homepage__separator"></div>
        <div class="homepage__offers" *ngIf="actualOffers">
            <!--only mobile-->
            <ng-container *ngIf="screenWidth < 768">
                <div class="homepage__offer-card" *ngFor="let offer of actualOffers">
                    <img class="homepage__offer-card__icon" src="{{offer.icon}}">
                    <div class="homepage__offer-card__title">{{offer.title}}</div>
                    <div class="homepage__offer-card__text">{{offer.description}}</div>
                    <a class="navigation-button" routerLink="{{offer.link}}">
                        <span class="navigation-button__label" i18n>WEITER</span>
                        <div class="navigation-button__arrow">
                            <img src="assets/icons/navigation-arrow.svg">
                        </div>
                    </a>
                </div>
            </ng-container>
            <!--tablet-->
            <carousel *ngIf="screenWidth >= 768 && screenWidth < 1366" [cellsToShow]="3" [cellsToScroll]="1"
                      [height]="360" [arrows]="true"
                      [dots]="false">
                <div class="carousel-cell" *ngFor="let offer of actualOffers">
                    <div class="homepage__offer-card">
                        <img class="homepage__offer-card__icon"
                             src="{{offer.icon}}">
                        <div class="homepage__offer-card__title">{{offer.title}}</div>
                        <div class="homepage__offer-card__text">{{offer.description}}</div>
                        <a class="navigation-button" routerLink="{{offer.link}}">
                            <span class="navigation-button__label" i18n>WEITER</span>
                            <div class="navigation-button__arrow">
                                <img src="assets/icons/navigation-arrow.svg">
                            </div>
                        </a>
                    </div>
                </div>
            </carousel>
            <!--desktop-->
            <carousel *ngIf="screenWidth >= 1366" [cellsToShow]="3" [cellsToScroll]="1" [height]="360" [arrows]="true"
                      [arrowsOutside]="true"
                      [dots]="false">
                <div class="carousel-cell" *ngFor="let offer of actualOffers">
                    <div class="homepage__offer-card">
                        <img class="homepage__offer-card__icon"
                             src="{{offer.icon}}">
                        <div class="homepage__offer-card__title">{{offer.title}}</div>
                        <div class="homepage__offer-card__text">{{offer.description}}</div>
                        <a class="navigation-button" routerLink="{{offer.link}}">
                            <span class="navigation-button__label" i18n>WEITER</span>
                            <div class="navigation-button__arrow">
                                <img src="assets/icons/navigation-arrow.svg">
                            </div>
                        </a>
                    </div>
                </div>
            </carousel>
        </div>
    </div>
    <div class="homepage__clinic">
        <div class="content-container">
            <div class="homepage__clinic__container">
                <div class="homepage__clinic__details">
                    <h2 i18n class="homepage__title">Eurodent Zahnklinik Ungarn</h2>
                    <h4 i18n class="homepage__subtitle">Zahn- und Implantatzentrum</h4>
                    <div class="homepage__separator"></div>
                    <div i18n class="homepage__clinic__list">
                        <div class="homepage__clinic__list__item">30 Jahre Erfahrung und mehr als 50.000 zufriedene
                            Patienten
                        </div>
                        <div class="homepage__clinic__list__item">Zahnbehandlung ohne Sprachbarriere</div>
                        <div class="homepage__clinic__list__item">bis zu 50-60% Ersparnis bei Ihrer Zahnbehandlung</div>
                        <div class="homepage__clinic__list__item">kostenloser Abholdienst und Unterkunft</div>
                    </div>
                    <a class="navigation-button inverted" routerLink="{{routesService.getRouterLink('your-benefits')}}">
                        <span i18n class="navigation-button__label">WEITERlesen</span>
                        <div class="navigation-button__arrow">
                            <img src="assets/icons/navigation-arrow-dark.svg">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="homepage__middle">
        <div class="homepage__treatments">
            <div class="content-container">
                <h1 class="homepage__title" i18n>AM HÄUFIGSTEN DURCHGEFÜHRTE BEHANDLUNGEN</h1>
                <h5 class="homepage__subtitle" i18n>kurz zusammengefasst</h5>
                <div class="homepage__separator"></div>
                <div class="homepage__treatments__grid">
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('dental-implants')}}">
                        <img class="icon" src="assets/icons/homepage/dental-implant.svg">
                        <div class="text" i18n>Zahn-implantation</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('cad-cam-technologies')}}">
                        <img class="icon" src="assets/icons/homepage/cad-cam.svg">
                        <div class="text" i18n>CAD-CAM digitaler Technologie</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('fixed-denture')}}">
                        <img class="icon" src="assets/icons/homepage/fixed-teeth.svg">
                        <div class="text" i18n>feste zähne</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('dental-crown')}}">
                        <img class="icon" src="assets/icons/homepage/dental-crown.svg">
                        <div class="text" i18n>zahnkrone</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('dental-bridge')}}">
                        <img class="icon" src="assets/icons/homepage/dental-bridge.svg">
                        <div class="text" i18n>zahnbrücke</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('inlay-onlay-filling')}}">
                        <img class="icon" src="assets/icons/homepage/fillings.svg">
                        <div class="text" i18n>inlay / onlay</div>
                    </a>
                    <a class="homepage__treatments__grid__item" routerLink="{{routesService.getRouterLink('veneer')}}">
                        <img class="icon" src="assets/icons/homepage/veneer.svg">
                        <div class="text" i18n>Veneer (Verblendschale)</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('combined-dentures')}}">
                        <img class="icon" src="assets/icons/homepage/combined-dentures.svg">
                        <div class="text" i18n>kombinierter zahnersatz</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('full-denture')}}">
                        <img class="icon" src="assets/icons/homepage/denture.svg">
                        <div class="text" i18n>Vollprothese</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('fixed-braces')}}">
                        <img class="icon" src="assets/icons/homepage/fixed-braces.svg">
                        <div class="text" i18n>Zahnregulierung</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('oral-hygiene')}}">
                        <img class="icon" src="assets/icons/homepage/hygiene.svg">
                        <div class="text" i18n>mundhygiene</div>
                    </a>
                    <a class="homepage__treatments__grid__item"
                       routerLink="{{routesService.getRouterLink('sedation')}}">
                        <img class="icon" src="assets/icons/homepage/sedation.svg">
                        <div class="text" i18n>sedierung</div>
                    </a>
                </div>
            </div>
        </div>
        <div class="homepage__reviews">
            <h2 class="homepage__title" i18n>WAS UNSERE PATIENTEN SAGEN</h2>
            <div class="homepage__separator"></div>
            <div class="content-container">

                <carousel *ngIf="screenWidth < 768" [cellsToShow]="1" [cellsToScroll]="1"
                          [height]="400" [arrows]="false"
                          [dots]="true">
                    <div class="carousel-cell" *ngFor="let review of reviews">
                        <a class="review-card" href="{{review.link}}" target="_blank">
                            <img class="icon" src="assets/icons/homepage/{{review.comment_from}}.svg">
                            <div class="date">{{review.date}}</div>
                            <div class="name">{{review.name}}</div>
                            <div class="separator"></div>
                            <div class="rating">
                                <img class="{{review.comment_from}}"
                                     src="assets/icons/homepage/rating-{{review.comment_from}}.svg">
                                <ng-container *ngIf="review.comment_from === 'Facebook'">
                                    <div class="text">
                                        <ng-template i18n>empfiehlt</ng-template>
                                        <span i18n class="bold">Eurodent Zahnklinik Ungarn</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="review">{{review.rating}}</div>
                        </a>
                    </div>
                </carousel>
                <carousel *ngIf="screenWidth >= 768 && screenWidth < 1366" [cellsToShow]="2" [cellsToScroll]="1"
                          [height]="400" [margin]="32" [arrows]="false"
                          [dots]="true">
                    <div class="carousel-cell" *ngFor="let review of reviews">
                        <a class="review-card" href="{{review.link}}" target="_blank">
                            <img class="icon" src="assets/icons/homepage/{{review.comment_from}}.svg">
                            <div class="date">{{review.date}}</div>
                            <div class="name">{{review.name}}</div>
                            <div class="separator"></div>
                            <div class="rating">
                                <img class="{{review.comment_from}}"
                                     src="assets/icons/homepage/rating-{{review.comment_from}}.svg">
                                <ng-container *ngIf="review.comment_from === 'Facebook'">
                                    <div class="text">
                                        <ng-template i18n>empfiehlt</ng-template>
                                        <span i18n class="bold">Eurodent Zahnklinik Ungarn</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="review">{{review.rating}}</div>
                        </a>
                    </div>
                </carousel>
                <carousel *ngIf="screenWidth >= 1366" [cellsToShow]="3" [cellsToScroll]="1"
                          [height]="400" [margin]="32" [arrows]="true" [arrowsOutside]="true"
                          [dots]="false">
                    <div class="carousel-cell" *ngFor="let review of reviews">
                        <a class="review-card" href="{{review.link}}" target="_blank">
                            <img class="icon" src="assets/icons/homepage/{{review.comment_from}}.svg">
                            <div class="date">{{review.date}}</div>
                            <div class="name">{{review.name}}</div>
                            <div class="separator"></div>
                            <div class="rating">
                                <img class="{{review.comment_from}}"
                                     src="assets/icons/homepage/rating-{{review.comment_from}}.svg">
                                <ng-container *ngIf="review.comment_from === 'Facebook'">
                                    <div class="text">
                                        <ng-template i18n>empfiehlt</ng-template>
                                        <span i18n class="bold">Eurodent Zahnklinik Ungarn</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="review">{{review.rating}}</div>
                        </a>
                    </div>
                </carousel>
            </div>

        </div>
    </div>
    <div class="homepage__quality">
        <div class="content-container">

            <div class="homepage__quality__details">
                <h2 class="homepage__title" i18n>ERSTKLASSIGE QUALITÄT</h2>
                <h5 class="homepage__subtitle" i18n>zu günstigen Preisen denjenigen, die</h5>
                <div class="homepage__separator"></div>
                <ul>
                    <ng-template i18n>Unser Zahn- und Implantatzentrum ist vor allem für Patient(inn)en, die
                    </ng-template>
                    <li i18n>an einem hochwertigen, aber bezahlbaren Zahnersatz interessiert sind</li>
                    <li i18n>Ihre Zahnbehandlung in einer der führenden Zahnkliniken der Region durchführen lassen
                        möchten,
                        wo Ihr persönliches Anliegen im Mittelpunkt steht
                    </li>
                    <li i18n>nach vielfältigen und niveauvollen zahnmedizinischen Leistungen suchen.</li>
                </ul>
                <a class="navigation-button" routerLink="{{routesService.getRouterLink('costs')}}">
                    <span class="navigation-button__label" i18n>WEITERlesen</span>
                    <div class="navigation-button__arrow">
                        <img src="assets/icons/navigation-arrow.svg">
                    </div>
                </a>
            </div>
            <img src="assets/images/homepage/home-bottom.png">
            <img class="illustration" src="assets/images/homepage/home-illustration-mono.svg">
        </div>
    </div>
    <div class="homepage__gallery">
        <div class="content-container">
            <h2 class="homepage__title" i18n>Galerie</h2>
            <div class="homepage__separator"></div>
            <!--mobile-->
            <carousel *ngIf="screenWidth < 768" [cellsToShow]="1" [cellsToScroll]="1"
                      [height]="230" [arrows]="false"
                      [dots]="true">
                <div class="carousel-cell" *ngFor="let gallery of galleries">
                    <a routerLink="{{routesService.getRouterLink('gallery')}}">
                        <img src="{{gallery.cover_picture.url}}">
                    </a>
                </div>
            </carousel>
            <!--tablet-->
            <carousel *ngIf="screenWidth >= 768 && screenWidth < 1366" [cellsToShow]="3" [cellsToScroll]="1"
                      [height]="229" [arrows]="false"
                      [dots]="false">
                <div class="carousel-cell" *ngFor="let gallery of galleries">
                    <a routerLink="{{routesService.getRouterLink('gallery')}}">
                        <img src="{{gallery.cover_picture.url}}">
                    </a>
                </div>
                <div class="carousel-cell">
                    <a class="text" routerLink="{{routesService.getRouterLink('gallery')}}">
                        <div class="more-galeries" i18n>Mehr Bilder ></div>
                    </a>
                </div>
            </carousel>
            <!--desktop-->
            <div class="homepage__gallery__galleries" *ngIf="screenWidth >= 1366">
                <a routerLink="{{routesService.getRouterLink('gallery')}}" *ngFor="let gallery of galleries">
                    <img src="{{gallery.cover_picture.url}}">
                </a>
            </div>
            <a class="more-galeries" routerLink="{{routesService.getRouterLink('gallery')}}" i18n>
                Mehr Bilder >
            </a>
        </div>
    </div>
    <div class="homepage__news">
        <div class="content-container">
            <h2 class="homepage__title" i18n>ZAHNMEDIZINISCHE NEUIGKEITEN</h2>
            <div class="homepage__separator"></div>
            <!--mobile-->
            <carousel *ngIf="screenWidth < 768" [cellsToShow]="1" [cellsToScroll]="1"
                      [height]="464" [arrows]="false"
                      [dots]="true">
                <div class="carousel-cell" *ngFor="let newness of news">
                    <app-blog-card [object]="newness"></app-blog-card>
                </div>
            </carousel>
            <!--tablet-->
            <carousel *ngIf="screenWidth >= 768 && screenWidth < 1366" [cellsToShow]="3" [cellsToScroll]="1"
                      [height]="464" [arrows]="false"
                      [dots]="true">
                <div class="carousel-cell" *ngFor="let newness of news">
                    <app-blog-card [object]="newness"></app-blog-card>
                </div>

            </carousel>
            <!--desktop-->
            <carousel *ngIf="screenWidth >= 1366" [cellsToShow]="3" [cellsToScroll]="1" [height]="464" [arrows]="true"
                      [dots]="false">
                <div class="carousel-cell" *ngFor="let newness of news">
                    <app-blog-card [object]="newness"></app-blog-card>
                </div>
            </carousel>
        </div>
    </div>


    <div class="content--main__newsletter">
        <div class="content-container">
            <div class="newsletter">
                <div class="newsletter__title">
                    <img class="newsletter__title__icon" src="assets/icons/newsletter.svg">
                    <div class="newsletter__title__text">
                        <span class="bold" i18n>NEWSLETTER</span>
                        <span i18n>Abonnieren</span>
                    </div>
                </div>
                <form class="newsletter__form" [formGroup]="newsletterForm">
                    <div class="newsletter__form__fields">
                        <label class="name">
                            <input type="text" placeholder="{{getNewsletterNamePlaceholder()}}"
                                   formControlName="full_name" name="full_name"
                                   [ngClass]="hasError('full_name','required') ? 'invalid' : ''">
                            <div i18n class="error"
                                 *ngIf="hasError('full_name','required')">Bitte geben Sie Ihren Vorname ein.
                            </div>
                        </label>
                        <label class="email">
                            <input type="email" placeholder="E-mail" formControlName="email" name="email"
                                   [ngClass]="hasError('email','required') ? 'invalid' : ''">
                            <div i18n class="error"
                                 *ngIf="hasError('email','required')">Bitte geben Sie Ihre E-Mail-Adresse ein.
                            </div>
                        </label>
                    </div>
                    <div class="newsletter__form__control">
                        <div class="privacy-policy" i18n>Ich habe die
                            <a *ngIf="privacy"
                               target="_blank"
                               href="{{privacy.privacy_policy.url}}"
                               class="bold">Datenschutzerklärung</a> gelesen und verstanden, ich gebe meine persönlichen Daten freiwillig an, um den Newsletter
                            zu abonnieren.
                        </div>
                        <div class="navigation-button" (click)="onSubmit()">
                            <span class="navigation-button__label" i18n>Abonnieren</span>
                            <div class="navigation-button__arrow">
                                <img src="assets/icons/navigation-arrow.svg">
                            </div>
                        </div>
                    </div>
                    <div class="newsletter__form__thankyou" *ngIf="isSubscribed">
                        <span i18n>Vielen Dank!</span><br>
                        <span i18n>Sie haben unseren Newsletter mit Neuigkeiten und Spezialangeboten erfolgreich abonniert.</span>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="content--main__form">
        <div class="content-container">
            <app-contact-form></app-contact-form>
        </div>
    </div>
</div>

