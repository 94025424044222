import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class UrlRedirectService {
    constructor(
        @Inject(LOCALE_ID) public locale: string
    ) {
    }

    public redirectTo(url: string): void {
        window.location.href = environment.apiUrl + "redirect?locale=" + this.locale + "&urlFrom=" + url;
    }
}
