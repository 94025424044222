import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dental-crown',
  templateUrl: './dental-crown.component.html',
  styleUrls: ['./dental-crown.component.scss']
})
export class DentalCrownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
