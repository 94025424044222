<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>ZAHNPROTHESE, VOLLPROTHESE
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Zahnprotese-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>ZAHNPROTHESE – SCHÖNE DRITTE ZÄHNE
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <div class="content--top__lead__description__list">
                        <div i18n class="content--top__lead__description__list__title">Vorteile von Zahnprothesen</div>
                        <div i18n class="content--top__lead__description__list__item">Wiederherstellung der
                            Kauffunktion, der
                            Ästhetik und des Sprechvermögens.
                        </div>
                        <div i18n class="content--top__lead__description__list__item">ermöglicht optisch ansprechendes,
                            schönes Gebiss.
                        </div>
                        <div i18n class="content--top__lead__description__list__item">sehr kostengünstiger Zahnersatz.
                        </div>
                        <div i18n class="content--top__lead__description__list__item">kurze Behandlungsdauer ohne
                            längere
                            Ausheilzeiten.
                        </div>
                    </div>


                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>Zahnprothesen sind klassischer Ersatz aller Zähne</h1>
            <p i18n>Eine Vollprothese ist ein herausnehmbarer Zahnersatz. Sie ist für Patienten geeignet, deren
                Zahnlücken mit Brücken nicht mehr geschlossen werden können oder für diejenigen, die eine herausnehmbare
                Lösung für ihre Zahnprobleme suchen.</p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Wann ist eine Vollprothese zu empfehlen?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Bei einem zahnlosen Kiefer, wo eine Implantation nicht möglich ist – zum Beispiel bei
                        Knochenkrankheiten oder Allergien – empfehlen wir eine Vollprothese als Lösung.
                        Totalprothesen sind aus Kunststoff hergestellt. Auf einen rosafarbigen Teil, der ähnlich wie das
                        Zahnfleisch aussieht, werden Kunststoffzähne gesetzt. Bei dieser Lösung braucht man keine
                        längere Heilungszeit.</p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    Wie gut halten Zahnprothesen?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Diese Vollprothesen halten durch Saugkraft und Speichel als "Kleber" ohne weitere
                        Verankerung an
                        der Schleimhaut oder dem Kieferknochen. Sie können auch Haftcreme Kukident zur Befestigung
                        verwenden.</p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    Soll man Zahnprothesen auch nachts halten?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Es gibt keine allgemein gültigen Empfehlungen, ob der Totalprothese auch nachts getragen
                        werden
                        sollte. Manche Patienten fühlen sich wohler mit, andere stören die Dritten beim Schlafen. Sollte
                        die Schleimhaut robust sein, bestehen keine Bedenken gegen ein nächtliches Tragen.</p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    Kann man mit einer Zahnprothese alles essen?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Unsere Zahnärzte in der Eurodent Zahnklinik empfehlen in der Übungsphase ausschließlich
                        weiche
                        Lebensmittel, die nicht kleben, zu sich zu nehmen. Hackfleisch, grätenfreier Fisch, gekochtes
                        Gemüse oder Bananen lassen sich auch mit künstlichen Zähnen, an die man noch nicht gewöhnt ist,
                        leicht zerkleinern.</p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck6">
                <label i18n class="content__accordion__label" for="chck6">
                    Wie gewöhnt man sich am besten an eine Zahnprothese?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n><span class="bold">Tag 1</span>– Beginnen Sie mit dem Essen von weichen Lebensmitteln, die
                        das Zahnfleisch schonen, wie Kartoffelpüree, Pudding oder Eiscreme.</p>
                    <p i18n><span class="bold">Tag 2 bis 14</span> – Sie beginnen, sich an Ihre Vollprothese zu
                        gewöhnen. Verstärkte Speichelbildung und Druckstellen im Mund sind anfangs normal und stellen
                        keinen Grund zur Besorgnis dar.
                    </p>
                    <p i18n>Einige Menschen gewöhnen sich sofort an Ihren neuen herausnehmbaren Zahnersatz. Bei anderen
                        kann es einige Monate dauern, bevor sie sich an den Sitz und das neue Gefühl gewöhnt haben. Wenn
                        Ihre erste Nachuntersuchung ansteht, haben Sie wahrscheinlich die neue Totalprothese schon für
                        eine Woche getragen.
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck7">
                <label i18n class="content__accordion__label" for="chck7">
                    SCHRITTE UND DAUER DER BEHANDLUNG
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Bei einer Vollprothese sollten Sie mit den folgenden Behandlungsschritten rechnen:
                    </p>

                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Konsultation, Erstuntersuchung, Abdrucknahme
                                </div>
                                <div i18n class="information__text">Wenn Sie ein Röntgenbild haben, das nicht älter als
                                    3
                                    Monate ist, können Sie es für die Konsultation mitbringen, oder wir erstellen hier
                                    vor Ort eine Röntgenaufnahme (im Wert von 40 Euro).
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Zahnprobe</div>
                                <div i18n class="information__text">nach 7-10 Tagen
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div i18n class="information__title">Fertigstellung</div>
                                <div i18n class="information__text">nach weiteren 7-10 Tagen</div>
                            </div>
                        </div>
                    </div>

                    <p i18n><span class="bold">Diese Angaben dienen nur Ihrer Orientierung!</span> Wir planen die
                        Behandlungsschritte für jeden Patienten individuell unter Beachtung der persönlichen Anliegen
                        sowie unter Berücksichtigung des Mundzustandes.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <h4 i18n class="content--main__title">Mit Implantaten befestigte Prothese – für die sehr gute stabilität</h4>

        <p i18n>Wenn auch Sie eine herausnehmbare aber befestigte Zahnprothese haben möchten, mit der Sie wieder
            problemlos kauen und lachen können, während sie stabil bleibt, dann ist die Stegprothese die perfekte Wahl
            für Sie.
        </p>

        <a i18n class="content--main__link" href="/{{routesService.getRouterLink('overdenture')}}">Was ist eine
            Stegprothese? <img src="assets/icons/navigation-arrow-yellow.svg"></a>
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/6.jpg">
</div>

