import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
    ViewChild
} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {MetatagService} from "./core/services/metatag.service";
import {CookieService} from "ngx-cookie-service";
import {RoutesService} from "./core/services/routes.service";
import {ScrollViewService} from './core/services/scroll-view.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    @ViewChild('teszt') teszt: ElementRef | undefined;

    title = 'eurodent-fe';

    showFloatingContact = false;
    showMore = false;
    showCookieBar = true;
    contentMargin = false;
    actualRoute = "";

    constructor(
        private router: Router,
        private metatagService: MetatagService,
        private cookieService: CookieService,
        public routesService: RoutesService,
        private scrollViewService: ScrollViewService,
    ) {
    }

    ngOnInit(): void {
        this.setShowFloatingContact();
        this.router.events.subscribe((evt) => {
            setTimeout(() => {
                this.setContentMargin(this.router.url === "/");
            }, 501);

            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.actualRoute = document.location.host + this.router.url;
            this.metatagService.setActualRoute(this.actualRoute);
            this.metatagService.updateStaticPageMetaTags();

            // do not scroll to top if the url contains an anchor
            if (this.router.url.indexOf("#") === -1) {
                this.scrollViewService.scrollViewToPositionWithTimeout(0, 502);
            }
        });
        if (this.cookieService.get('accepted') !== '') {
            this.showCookieBar = false;
        }
    }

    ngAfterViewInit() {
        this.setContentMargin(this.contentMargin);
    }

    setShowFloatingContact() {
        this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        ).subscribe((e: any) => {
            if (window.innerWidth > 767) {
                this.showFloatingContact = this.router.url !== this.routesService.getRouterLink('contact');
            } else {
                this.showFloatingContact = false;
            }

        });
    }

    setDisplay(display: boolean){
        this.contentMargin = display;
        if (display === false) {
           this.setContentMargin(display);
        }
    }

    setContentMargin(display: boolean) {
        const information = document.getElementById('actualInformation');
        const content = document.getElementById('site-content');

        if (display) {
            information!.style.maxHeight = "100vh"
            setTimeout(() => {
                const informationBlockHeight = information?.offsetHeight || 0;
                content!.style.marginTop = informationBlockHeight + "px";
                content!.style.transition = "margin-top 0.5s"
            }, 75);

        } else {
            content!.style.marginTop = "0px";
        }
    }

    onToggleShowMore(show: boolean) {
        this.showMore = show;
    }

    @HostListener("window:resize", ["$event"])
    onResize(event: any) {
        if (window.innerWidth > 767) {
            this.showFloatingContact = this.router.url !== this.routesService.getRouterLink('contact');
        } else {
            this.showFloatingContact = false;
        }
    }

}
