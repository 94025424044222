<p i18n class="bold" *ngIf="showText">Gehören auch Sie zu unseren zufriedenen Patienten und kehren Sie mit perfekten, wunderschönen
    Zähnen nach Hause zurück!</p>
<div class="bottom__help">
    <img class="bottom__help__icon" src="assets/icons/help.svg">

    <div i18n class="bottom__help__title">Haben Sie Fragen?</div>
    <div i18n class="bottom__help__text">Wir schicken Ihnen gerne detaillierte Preisbeispiele und
        beantworten
        wir alle Ihre Fragen ohne Sprachbarrieren. Unsere Kundenberater sprechen deutsch, französisch,
        portugiesisch, englisch, italienisch und arabisch. Bitte kontaktieren Sie uns! Wir helfen Ihnen
        gerne
        weiter!
    </div>

    <a class="navigation-button inverted" routerLink="{{routesService.getRouterLink('contact')}}">
        <span i18n class="navigation-button__label">kontakt</span>
        <div class="navigation-button__arrow">
            <img src="assets/icons/navigation-arrow-dark.svg">
        </div>
    </a>
</div>
