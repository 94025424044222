<ngb-carousel data-interval="false" *ngIf="data" [activeId]="data.pictureId" [animation]="false">
    <ng-template ngbSlide *ngFor="let image of data.gallery.pictures" [id]="image?.id">
        <div class="img-wrapper">
            <img src="{{image?.zoomed_picture?.url}}"
                 [alt]="image?.zoomed_picture?.alternativeText"/>
        </div>
    </ng-template>
</ngb-carousel>

<div class="dialog__close"></div>
