<div class="card" *ngIf="object">
    <div class="card__image">
        <img src="{{image}}">
    </div>
    <div class="card__content">
        <div>
            <div class="card__content__title"><span>{{ object.title}}</span></div>
            <div class="card__content__lead" [innerHTML]="richtextService.trustHTML(object.lead)"></div>
        </div>
        <a class="navigation-button card__content__navigation" routerLink="{{object.slug}}">
            <span i18n class="navigation-button__label">WEITERlesen</span>
            <div class="navigation-button__arrow">
                <img src="assets/icons/navigation-arrow.svg">
            </div>
        </a>
    </div>
</div>
