import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';

@Component({
  selector: 'app-all-on-four',
  templateUrl: './all-on-four.component.html',
  styleUrls: ['./all-on-four.component.scss']
})
export class AllOnFourComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) public locale: string,) { }

  ngOnInit(): void {
  }

}
