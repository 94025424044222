<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
        </div>
    </div>
</div>

<div class="content--main">
    <div class="content--main__form">
        <div class="content-container">
            <app-callback-form></app-callback-form>
        </div>
    </div>
</div>
