<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Kostenlose Unterkunft</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/company-services/top/Unterkunft-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Gratis Übernachtungsmöglichkeit bei Eurodent</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Wir bieten unseren Patient(inn)en, die aus größerer Entfernung (mehr als 250 km) zu uns kommen
                        und eine Behandlung bei uns durchführen lassen, <span class="bold">eine kostenlose Übernachtungsmöglichkeit</span>während
                        des Aufenthaltes in einer unserer Wohnungen oder in unserer Pension an. (Unsere Patien(inn)en
                        müssen die Übernachtungskosten von 40 € pro Nacht nicht bezahlen, sondern bei ihrer Abreise
                        lediglich eine Reinigungsgebühr von 30 € entrichten*.)</p>

                    <p i18n>Sie sind so nicht an die Zeiteinteilung eines Hotels gebunden, Sie können über Ihre Zeit zwischen
                        den Behandlungen frei verfügen.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    EURODENT CITY APPARTEMENTS
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Die 13 Appartements befinden sich im gleichen Gebäude wie die Zahnklinik.
                                Sie liegen in ruhiger Lage und zentrumsnah (ca. 2 Minuten zu Fuß vom Zentrum
                                entfernt).</p>
                            <p i18n>Die geschmacksvoll eingerichteten Appartements garantieren unseren Gästen eine erholsame
                                Entspannung.</p>
                            <p i18n>Unsere Gäste können auf dem klinikeigenen Hof <span class="bold">kostenlos parken.</span></p>

                            <p i18n>Die Fußgängerzone der Stadt, die gleich neben der Klinik liegt, bietet unseren
                                Patient(inn)en mit ihren kleinen Geschäften, guten Restaurants, Friseur- und
                                Kosmetiksalons vielfältige Entspannungsmöglichkeiten.</p>
                            <div class="list">
                                <div class="list__title" i18n>Ausrüstung/Dienstleistungen der Appartements:
                                </div>
                                <div class="list__item" i18n>Doppelbett</div>
                                <div class="list__item" i18n>Badezimmer</div>
                                <div class="list__item" i18n>praktisch eingerichtete Küche</div>
                                <div class="list__item" i18n>TV-Gerät</div>
                                <div class="list__item" i18n>kostenloser WIFI-Zugang
                                </div>
                                <div class="list__item" i18n>kostenloser Parkplatz
                                </div>
                            </div>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/company-services/accordion/Appartements-1.png">
                            <img class="image" src="assets/images/company-services/accordion/Appartements-2.png">
                            <img class="image" src="assets/images/company-services/accordion/Appartements-3.png">
                        </div>
                    </div>
                    <a class="navigation-button" routerLink="/{{routesService.getRouterLink('apartments')}}">
                        <span i18n class="navigation-button__label">Appartements Galerie</span>
                        <div class="navigation-button__arrow">
                            <img src="assets/icons/navigation-arrow.svg">
                        </div>
                    </a>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    PENSION JASMIN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>In der Pension Jasmin befinden sich 13 Zimmer von verschiedenen Größen.</p>
                            <p i18n>Sie können die Pension von unserer Klinik in 15 Minuten zu Fuß oder in 5 Minuten mit dem
                                Auto erreichen. In der Nähe der Pension befinden sich das Thermalbad, die Burg der Stadt
                                und mehrere Restaurants. Die Pension liegt direkt neben dem Fluss Lajta in einem
                                ruhigen, grünen Teil der Stadt.</p>
                            <div class="list">
                                <div i18n class="list__title">Ausrüstung/Dienstleistungen der Pension:
                                </div>
                                <div i18n class="list__item">eigenes Badezimmer</div>
                                <div i18n class="list__item">TV-Gerät und Kühlschrank im Zimmer</div>
                                <div i18n class="list__item">kostenloses Frühstück</div>
                                <div i18n class="list__item">kostenloser WIFI-Zugang
                                </div>
                                <div i18n class="list__item">kostenloser Parkplatz
                                </div>
                            </div>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/company-services/accordion/Pension-1.png">
                            <img class="image" src="assets/images/company-services/accordion/Pension-2.png">

                        </div>
                    </div>
                    <a class="navigation-button" routerLink="/{{routesService.getRouterLink('guesthouse')}}">
                        <span  i18n class="navigation-button__label">Pension Galerie</span>
                        <div class="navigation-button__arrow">
                            <img src="assets/icons/navigation-arrow.svg">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <p i18n class="bold">*Das Reinigungsgebühr muss nur einmal bezahlt werden und ist von der Länge des Aufenthaltes
            unabhängig. Die kostenlose Unterkunft ist nur für die behandelten Patient(inn)en vorgesehen. Begleitpersonen
            bezahlen 20 Euro pro Nacht.
        </p>
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/company-services/bottom/Unterkunft-bottom.jpg">
</div>

