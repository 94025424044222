import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Blog} from "../../../core/models/blog.model";
import {BlogService} from "../../../core/services/blog.service";
import {Newness} from "../../../core/models/homepage.model";
import {RichTextService} from "../../../core/services/rich-text.service";

@Component({
    selector: 'app-blog-card',
    templateUrl: './blog-card.component.html',
    styleUrls: ['./blog-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BlogCardComponent implements OnInit {

    @Input() object: Blog | Newness | undefined | any;
    image = "";

    constructor(
        public richtextService: RichTextService
    ) {
    }

    ngOnInit(): void {
        if (this.object?.main_picture) {
            this.image = this.object.main_picture.largeUrl;
        } else {
            this.image = this.object.cover_image;
        }
    }

}
