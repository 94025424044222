import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UrlRedirectService} from "../../../core/services/url-redirect.service";

@Component({
    selector: 'app-url-redirect',
    templateUrl: './url-redirect.component.html',
    styleUrls: ['./url-redirect.component.scss']
})
export class UrlRedirectComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private redirectService: UrlRedirectService,
    ) {
    }

    ngOnInit(): void {
        const urlString = this.route.snapshot.url.join('/');
        this.redirectService.redirectTo(document.location.host + '/' + urlString);
    }
}
