import { Component, Inject, LOCALE_ID } from '@angular/core';
import { RoutesService } from '../../../core/services/routes.service';

@Component({
    selector: 'app-top-navigation',
    templateUrl: './top-navigation.component.html',
    styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent {

    constructor(@Inject(LOCALE_ID) public locale: string,
                public routesService: RoutesService) {
    }

}
