import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class NewsletterService {
    constructor(
        private http: HttpClient,
        @Inject(LOCALE_ID) public locale: string
    ) {
    }

    public postNewsletterSubscription (payload: any): Observable<any> {
        return this.http.post<any>(environment.apiUrl + "subscribe?locale=" + this.locale, payload);
    }

}
