<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content--main__form">
        <div class="content-container">
            <div class="thank-you-container">
                <h2 i18n class="thank-you__title">
                    Danke für Ihre Anfrage
                </h2>
                <div class="thank-you__text">
                    <p i18n>Wir haben die Angaben Ihrer Anfrage dankend erhalten.</p>
                    <p i18n>Unsere Kollegen werden Ihre Fragen schnellstmöglich beantworten. Falls wir uns innerhalb von einem Tag nicht
                        bei
                        Ihnen melden, versuchen Sie bitte Ihre Anfrage noch einmal einzusenden.</p>
                </div>
                <div class="thank-you__navigation"></div>
                <a class="navigation-button" routerLink="/">
                    <span i18n class="navigation-button__label">Startseite</span>
                    <div class="navigation-button__arrow">
                        <img src="assets/icons/navigation-arrow.svg">
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
