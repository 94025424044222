<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Kombinierter Zahnersatz</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/KombinierterZahnersatz-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Was ist ein kombinierter Zahnersatz?
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Von einem kombinierten Zahnersatz spricht man, wenn der Zahnersatz sowohl festsitzende als auch
                        herausnehmbare Bestandteile hat. Ein kombinierter Zahnersatz kann sowohl im Oberkiefer als auch
                        im Unterkiefer eingesetzt werden.
                    </p>
                    <div class="content--top__lead__description__list">
                        <div i18n class="content--top__lead__description__list__title">Vorteile von kombinierten Zahnersatz
                        </div>
                        <div i18n class="content--top__lead__description__list__item">keine sichtbaren Haltelemente</div>
                        <div i18n class="content--top__lead__description__list__item">hoher Tragekomfort</div>
                        <div i18n class="content--top__lead__description__list__item">sehr gute Abstützung bzw. sehr guter
                            Halt an der Restbezahnung und/oder den Implantaten
                        </div>
                        <div i18n class="content--top__lead__description__list__item">Erweiterungen oder Reparaturen sind
                            möglich
                        </div>
                    </div>
                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <div class="content--main__information__image-container">
                <div class="image">
                    <img src="assets/images/treatments/main/KombinierterZahnersatz-main1.png">
                    <span i18n class="before">Vorher</span>
                </div>
                <div class="image">
                    <img src="assets/images/treatments/main/KombinierterZahnersatz-main2.png">
                    <span i18n class="after">Nachher</span>
                </div>
            </div>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    KOMBINIERTER ZAHNERSATZ ÜBERSICHT
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Eine Teilprothese wird in der Regel über einen festsitzenden Zahnersatz am Restgebiss befestigt.
                        Auf diese Weise erhält die Teilprothese einen deutlich besseren Halt. Sie ist auch viel
                        ästhetischer als eine einfache Teilprothese, da die Befestigung des herausnehmbaren Teiles von
                        außen nicht zu sehen ist.
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    SCHRITTE DER ZAHNBEHANDLUNG
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Sie sollten mit den folgenden Behandlungsschritten rechnen:</p>
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Vorbereitungen des festsitzenden Teiles</div>
                                <div i18n class="information__text">Um dem kombinierten Zahnersatz einen festen Halt zu
                                    sichern, versehen wir die gesunden Pfeilerzähne oder Implantate mit einer Krone. In
                                    der Zahnkrone werden präzise und feinmechanische Verbindungselemente eingesetzt.
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Fertigstellung des Zahnersatzes</div>
                                <div i18n class="information__text">Nach mindestens einer Gerüstprobe wird der in unserem
                                    klinikeigenen Zahntechniklabor gefertigte herausnehmbare Zahnersatz auf dem fixen
                                    Teil befestigt. Die abnehmbare Prothese muss für die Reinigung täglich
                                    herausgenommen werden.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    DIE TEILPROTHESE KANN ÜBER FOLGENDE VERANKERUNGSELEMENTE AN DEN RESTZÄHNEN BEFESTIGT WERDEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Geschiebe</div>
                        <div i18n class="content--main__content__list__item">Stege</div>
                        <div i18n class="content--main__content__list__item">Doppelkronen – Teleskop- und Konuskronen</div>
                        <div i18n class="content--main__content__list__item">Knopfanker</div>
                        <div i18n class="content--main__content__list__item">Gelenke</div>
                        <div i18n class="content--main__content__list__item">Riegel</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    GESCHIEBEPROTHESE - Behandlungsablauf und Behandlungsdauer
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Ein Geschiebe besteht aus zwei Teilen, nämlich aus der an einer Krone oder Brücke
                                befestigten sogenannten Matrize und aus der in die Prothese integrierten Patrize, die
                                genau in die Matrize hineinpasst.
                            </p>
                            <p i18n>Die Matrize kann entweder in die Krone eingearbeitet werden und ist in diesem Falle fast
                                unsichtbar, oder sie wird außen auf die Krone aufgebracht.
                            </p>
                            <p i18n>Die beiden Teile halten durch Haftreibung, die Prothese sitzt fest an ihrem Platz.</p>
                        </div>
                        <div class="images">
                            <img class="image"
                                 src="assets/images/treatments/accordion/KombinierterZahnersatz-accordion.png">
                        </div>
                    </div>
                    <p i18n>Bei einer Geschiebeprothese auf sog. Pfeilerzähne sollten Sie mit den folgenden
                        Behandlungsschritten rechnen.*</p>
                    <p class="bold" i18n>Sie brauchen mindestens 4 Sitzungen.</p>
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Erstuntersuchung
                                </div>
                                <div i18n class="information__text">Konsultation, Erstuntersuchung, Vorbereitung der Zähne,
                                    Abdrucknahme, provisorische Kronen
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Gerüstprobe</div>
                                <div i18n class="information__text">nach ca. einer Woche: Gerüstprobe und Gebissnahme</div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div i18n class="information__title">Zahnprobe
                                </div>
                                <div i18n class="information__text">nach ca. einer weiteren Woche Zahnprobe des fixen und
                                    abnehmbaren Teils
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">4</div>
                            <div class="information">
                                <div i18n class="information__title">Fertigstellung
                                </div>
                                <div i18n class="information__text">
                                    nach ca. einer weiteren Woche: Befestigung des fixen Teils und Fertigstellung der
                                    abnehmbaren Prothese
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><span i18n class="bold">Diese Daten dienen nur Ihrer Orientierung!</span><ng-template i18n> Wir planen die
                        Behandlungsschritte für jeden Patienten individuell unter Beachtung der persönlichen Anliegen
                        sowie unter Berücksichtigung des Mundzustandes.</ng-template></p>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/9.jpg">
</div>

