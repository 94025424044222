<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Feste Zahnspange
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/FesteZahnspange-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>FESTE ZAHNSPANGE -
                        „Damon Brackets“ selbstligierend
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <span i18n class="bold">Eine Zahnspange hat mehr Vorteile als nur schöne, gerade Zähne!</span>
                    <p i18n>Eine Zahnspange kann nicht nur Ihr Aussehen, sondern auch Ihre Zahngesundheit verbessern. Für
                        schöne, gerade Zähne lassen sich viele Heranwachsende und Erwachsene, die unter einem
                        kieferorthopädischen Problem leiden, Ihre Zähne behandeln.
                    </p>

                    <div class="content--top__lead__description__list">
                        <div i18n class="content--top__lead__description__list__title">Vorteile einer Zahnspange</div>
                        <div i18n class="content--top__lead__description__list__item">Verbesserung der Mundgesundheit</div>
                        <div i18n class="content--top__lead__description__list__item">Hilfe bei der Verdauung durch
                            regelgerechtes Kauen
                        </div>
                        <div i18n class="content--top__lead__description__list__item">Vorbeugung von Karies</div>
                        <div i18n class="content--top__lead__description__list__item">Verhinderung von
                            Zahnfleischerkrankungen
                        </div>
                        <div i18n class="content--top__lead__description__list__item">Stärkung des Selbstbewusstseins</div>
                    </div>

                    <app-top-navigation></app-top-navigation>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">

        <div class="content__accordions">

            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    LÖSUNGEN MIT DAMON-SYSTEM
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <h4 i18n>Damon Clear</h4>
                            <span i18n class="bold">Ästhetischste Lösung mit zahnfarbenen Brackets</span>
                            <p i18n>Ein Bracket, dass durch sein zahnfarbenes Erscheinungsbild besonders attraktiv und durch
                                seine Effektivität Ihre Zähne besonders schnell und angenehm korrigiert.</p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/FesteZahnspange-accordion1.png">
                        </div>
                    </div>
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <h4 i18n>Damon Q</h4>
                            <span i18n class="bold">Metallzahnspange mit Metallbrackets</span>
                            <p i18n>Damon Q ist das innovative Metallbracket des Damon-Systems. Es hat sich millionenfach
                                bewährt und steht für schnelle, kontrollierte Zahnbewegungen und herausragende
                                Ergebnisse.
                                Die Behandlung mit Damon Q Zahnspangen verläuft in aller Regel schonend und sanft.</p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/FesteZahnspange-accordion2.png">
                        </div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    QUALITÄT, KOMFORT UND ÄSTHETIK - ZAHNREGULIERUNG MIT DAMON-SYSTEM
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Das Damon-System wurde von Dr. Dwight Damon, einem bekannten amerikanischen Zahnarzt, entwickelt.
                        Zurzeit ist dieses System das modernste zahnregulierende Verfahren der Welt. Alle Fehlstellungen
                        sind damit zu korrigieren. Diese feste Zahnspange besteht aus sogenannten Brackets, also kleinen
                        Knöpfen aus Metall, Kunststoff oder Keramik, die auf die Zähne des Patienten geklebt und mit
                        Draht
                        verbunden werden. Die Zähne des Patienten werden durch die von den Bögen übertragenen Kräfte der
                        festen Zahnspange in die gewünschte Position gezogen.
                    </p>
                    <div class="content--main__table">
                        <div i18n class="header">Eigenschaften</div>
                        <div i18n class="header">Selbstligierende Damon-Zahnspange</div>
                        <div i18n class="header">Traditionelle Zahnspange</div>

                        <div i18n class="header">Stärke</div>
                        <div>
                            <ul *ngIf="locale !== 'fr'">
                                <li i18n>arbeitet mit sanfteren Kräften, ist deswegen <span class="bold">weniger schmerzhaft und erreicht schnellere Ergebnisse</span>
                                </li>
                                <li i18n><span class="bold">weniger Untersuchungen zahn- und knochenfreundlich</span></li>
                            </ul>

                            <ul *ngIf="locale === 'fr'">
                                <li><span class="bold">opère avec des forces plus douces,</span></li>
                                <li><span class="bold">est donc moins douloureux</span></li>
                                <li><span class="bold">et on peut obtenir des résultats plus rapides</span></li>
                                <li>moins d'examens respectueux des dents et des os</li>
                            </ul>

                        </div>
                        <div>
                            <ul>
                                <li i18n>Zähne lassen sich wegen des Gummisystems oft nur mit hohem Kraftaufwand bewegen,
                                    dadurch
                                    schmerzhaft
                                </li>
                                <li i18n>zahlreiche Kontrollen</li>
                            </ul>
                        </div>

                        <div i18n class="header">Ästhetik
                        </div>
                        <div>
                            <ul *ngIf="locale !== 'fr'">
                                <li i18n>als <span class="bold">zahnfarbenes Zirkonkristall</span> erhältlich</li>
                                <li i18n><span class="bold">keine leichten Verfärbungen</span> wie frühere Brackets</li>
                                <li i18n><span class="bold">viel ästhetischer,</span> bequem</li>
                            </ul>
                            <ul *ngIf="locale === 'fr'">
                                <li>accessible en cristal de zirconium adapté à la couleur des dents</li>
                                <li><span class="bold">pas de décoloration sur les dents, contrairement aux anciens appareils</span></li>
                                <li><span class="bold">beaucoup plus ésthétique</span></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li i18n>Startbogen werden meistens in Metallic-Farben verwendet.</li>
                                <li i18n>hervorstehende Kanten</li>
                            </ul>
                        </div>

                        <div i18n class="header">Dauer der Zahnregulierung</div>
                        <div i18n *ngIf="locale !== 'fr'">ca. <span class="bold">1-2 Jahre</span></div>
                        <div *ngIf="locale === 'fr'">environ 1 à 2 ans</div>
                        <div i18n>mindestens 2 Jahre</div>

                        <div i18n class="header">Empfohlenes Alter</div>
                        <div i18n *ngIf="locale !== 'fr'"><span class="bold">in jedem Alter empfohlen</span></div>
                        <div *ngIf="locale === 'fr'">recommandé à tout âge</div>
                        <div i18n>eher in der Kindheit</div>

                        <div i18n class="header">Reinigungsfähigkeit</div>
                        <div i18n>einfache Reinigung</div>
                        <div i18n>schwer zu reinigen</div>

                        <div i18n class="header">Metallallergie</div>
                        <div i18n *ngIf="locale !== 'fr'"><span class="bold">nicht typisch,</span> da Metallteile kaum vorhanden sind</div>
                        <div *ngIf="locale === 'fr'">peu fréquente, car l’appareil a peu de pièces métalliques.</div>
                        <div i18n>kommt häufiger vor</div>

                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    BEHANDLUNGSABLAUF
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Die Zahnregulierung mit festen Zahnspangen besteht aus einer aktiven und einer passiven Phase.
                    </p>
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Erste Phase (aktiv)
                                </div>
                                <div class="information__text">
                                    <ul class="information__text__list">
                                        <li i18n>Empfehlung einer festen Zahnspange im Ober- und Unterkiefer aufgrund des
                                            vorhandenen Modells, des Fernröntgenbilds, der Panoramaröntgenaufnahme, und
                                            Fotos.
                                        </li>
                                        <li i18n>Aufkleben der Zahnspange (ca. 60 Minuten / Kiefer)</li>
                                        <li i18n>Kontrolle und Aktivierung (Häufigkeit ca. alle 4-8 Wochen, Dauer jeweils ca.
                                            20
                                            Minuten)
                                        </li>
                                        <li i18n>Entfernung der festen Zahnspange, Polieren und Abdruck für herausnehmbare
                                            Zahnspange (Dauer ca. 60 Minuten)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Zweite Phase (passiv)</div>
                                <div class="information__text">
                                    <ul class="information__text__list">
                                        <li i18n>Retainer (fix oder herausnehmbar) / ist langfristig zu tragen (Tragedauer
                                            unterschiedlich)
                                        </li>
                                        <li i18n>Kontrolle alle 3 Monate</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>

</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/4.jpg">
</div>








