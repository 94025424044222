<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Galerie
            </h1>
        </div>
    </div>
</div>
<div class="content--main content--gallery">
    <div class="content-container">
        <carousel *ngIf="screenWidth < 768" [cellsToShow]="1" [cellsToScroll]="1" [height]="275" [arrows]="false"
                  [dots]="true">
            <div class="carousel-cell" *ngFor="let gallery of galleries">
                <a class="gallery-card" routerLink="{{gallery.slug}}">
                    <div class="gallery-card__thumbnail">
                        <img src="{{gallery.cover_picture.url}}">
                    </div>
                    <div class="gallery-card__details">
                        <div class="gallery-card__details__name">{{gallery.galery_name}}</div>
                        <div i18n class="gallery-card__details__number">{{gallery.pictures.length}} Bilder</div>
                    </div>
                    <div class="gallery-card__bottom"></div>
                </a>
            </div>
        </carousel>

        <carousel *ngIf="screenWidth >= 768 && screenWidth < 1366" [height]="355" [cellsToShow]="2" [cellsToScroll]="1"
                  [margin]="20" [arrows]="false" [dots]="true">
            <div class="carousel-cell" *ngFor="let gallery of galleries">
                <a class="gallery-card" routerLink="{{gallery.slug}}">
                    <div class="gallery-card__thumbnail">
                        <img src="{{gallery.cover_picture.url}}">
                    </div>
                    <div class="gallery-card__details">
                        <div class="gallery-card__details__name">{{gallery.galery_name}}</div>
                        <div i18n class="gallery-card__details__number">{{gallery.pictures.length}} Bilder</div>
                    </div>
                    <div class="gallery-card__bottom"></div>
                </a>
            </div>
        </carousel>

        <carousel *ngIf="screenWidth >= 1366" [height]="355" [cellsToShow]="3" [cellsToScroll]="1" [margin]="20"
                  [arrows]="true" [arrowsOutside]="true">
            <div class="carousel-cell" *ngFor="let gallery of galleries">
                <a class="gallery-card" routerLink="{{gallery.slug}}">
                    <div class="gallery-card__thumbnail">
                        <img src="{{gallery.cover_picture.url}}">
                        <div class="overlay">
                        </div>
                        <div class="arrow">
                            <img src="assets/icons/navigation-arrow.svg">
                        </div>
                    </div>
                    <div class="gallery-card__details">
                        <div class="gallery-card__details__name">{{gallery.galery_name}}</div>
                        <div i18n class="gallery-card__details__number">{{gallery.pictures.length}} Bilder</div>
                    </div>
                    <div class="gallery-card__bottom"></div>

                </a>
            </div>
        </carousel>


    </div>
</div>
