import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class CountrycodeService {
    constructor(
        private http: HttpClient
    ) {
    }

    getCountryCodes() {
        return this.http.get<string[]>('assets/countrycodes.json');
    }
}
