<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>EMS DENTAL Spa </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/EMS-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Meister der Prophylaxe - EMS Dental SPA
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n class="bold">GARANTIERT SCHMERZFREIE TECHNOLOGIE
                        1 Stunde für nur 50 Euro pro Kiefer</p>

                    <p i18n>Mit EMS DENTAL Spa-Behandlung bieten wir für unseren Patienten ein völlig neuer Ansatz in der
                        Mundhygiene. Wir empfehlen die EMS DENTAL Spa-Behandlung, wenn Ihnen die Gesundheit Ihrer Zähne
                        am
                        Herzen liegt und Sie eine bequemere, effektivere, schnellere und schmerzlose Lösung für Ihre
                        jährliche Zahnsteinentfernung suchen.</p>
                    <app-top-navigation></app-top-navigation>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <p i18n>ES IST BEKANNT, dass Zahnstein ohne Behandlung zu Zahnfleischentzündung und Parodontitis führen kann.
                Parodontalerkrankungen können sogar das Risiko von Herz-Kreislauf-Erkrankungen, Arthritis, Diabetes und
                Frühgeburten erhöhen. Deshalb empfehlen wir Ihnen eine regelmäßige Plaque-Entfernung 1-2 Mal pro
                Jahr.</p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Vorteile von EMS DENTAL Spa-Behandlung
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <div class="list">
                                <div i18n class="list__item">Neue schmerzlose Technologie</div>
                                <div i18n class="list__item">Kann die Lebensdauer Ihrer Zähne verlängert werden</div>
                                <div i18n class="list__item">Höchste Intensität und Präzision</div>
                                <div i18n class="list__item">Ihre Zähne sehen heller aus</div>
                                <div i18n class="list__item">Können Zahnfleischentzündungen verhindert werden</div>
                                <div i18n class="list__item">Schnelle und effiziente Entfernung von Belägen</div>
                                <div i18n class="list__item">Schonend, biokompatibel und gewebefreundlich</div>
                                <div i18n class="list__item">Reinigung von Implantatoberflächen und Zahnfleischtaschen</div>
                                <div i18n class="list__item">Werden schmerzhafte Parodontalerkrankungen verhindert</div>
                            </div>
                        </div>
                        <div class="images">
                            <img alt="EMS" i18n-alt class="image" src="assets/images/treatments/accordion/EMS-accordion1.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Wem empfehlen wir die Behandlung?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Wird besonders für Träger von Zahnspangen
                            empfohlen, da sie Biofilm und Zahnstein um die Zahnspange herum effektiv entfernen kann,
                            ohne dass Klebstoffe oder Gummibänder entfernt werden müssen.
                        </div>
                        <div i18n class="content--main__content__list__item">Diese Technik stellt eine umfassende Lösung für
                            Prophylaxe- und Parodontalbehandlungen dar.
                        </div>
                        <div i18n class="content--main__content__list__item">Dank der hochpräzisen Pulverstrahltechnologie
                            ermöglicht die Düse minimalinvasiven Zugang zu Zahnfleischtaschen, Restaurationen und
                            Implantaten, ohne dass Kronen oder Prothesen entfernt werden müssen.
                        </div>
                        <div i18n class="content--main__content__list__item">Zahnfleischtaschen und die Implantatoberflächen
                            können damit gereinigt werden.
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    Was ermöglicht die neue EMS Technologie?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Das neueste Prophylaxegerät aus der Schweiz macht Ihren Zahnarztbesuch bei der Eurodent
                                Zahnklinik zu einem angenehmen Erlebnis. Wir bei Eurodent haben diese neue Technologie
                                eingeführt, weil wir bestrebt sind, die Bedürfnisse unserer Patient(inn)en immer bestens
                                zu erfüllen.</p>
                            <p i18n>Dank dieses neuen Gerätes und des speziellen Pulvers können wir Zahnstein, Plaque und
                                Verfärbungen schmerzfrei, effizient, sanft und sicher entfernen. Es ist für Sie ein viel
                                angenehmeres und effektiveres Verfahren als herkömmliche Mundhygieneverfahren. Das Gerät
                                ermöglicht eine intensive professionelle Anwendung und hohe Genauigkeit. Das verwendete
                                einzigartige Pulver basiert auf Erythritol, einem wasserlöslichen, biokompatiblen
                                Material, das den Komfort für den Patienten erhöht. </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/EMS-accordion2.png">
                            <img class="image" src="assets/images/treatments/accordion/EMS-accordion3.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <h4 i18n class="content--main__title">Genießen Sie wieder Ihr gesundes, strahlendes Lächeln!<br>
            Vereinbaren Sie einen Termin für eine EMS Dental Spa-Behandlung!
        </h4>
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/2.jpg">
</div>

