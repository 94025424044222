import { Component, OnInit } from '@angular/core';
import {CoronavirusService} from "../../core/services/coronavirus.service";
import {Coronavirus} from "../../core/models/coronavirus.model";
import {RichTextService} from "../../core/services/rich-text.service";

@Component({
  selector: 'app-coronavirus',
  templateUrl: './coronavirus.component.html',
  styleUrls: ['./coronavirus.component.scss']
})
export class CoronavirusComponent implements OnInit {

  coronavirus: Coronavirus | undefined;

  constructor(
      private coronavirusService: CoronavirusService,
      public richtextService: RichTextService
  ) { }

  ngOnInit(): void {
    this.coronavirusService.getCoronavirus().subscribe((value) => {
      this.coronavirus = value;
    })
  }

  changeListStyle(text: any): string {
    return text.replaceAll('<ul style="list-style-type:circle;">', '<ul class="circle">')
        .replaceAll('<ol style="list-style-type:decimal;">', '<ol class="decimal">')
        .replaceAll('<ol style="list-style-type:upper-roman;">', '<ol class="upper-roman">')
        .replaceAll('<ul style="list-style-type:disc;">', '<ul class="disc">')
        .replaceAll('<li>', '<li><span>')
        .replaceAll('</li>', '</span></li>');
  }

}
