import {Inject, Injectable, LOCALE_ID} from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class LanguageService {

    constructor(
        @Inject(LOCALE_ID) public locale: string
    ) {
    }

    public getLang() {
        return this.locale;
    }
}
