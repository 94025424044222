import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';

@Component({
  selector: 'app-dental-bridge',
  templateUrl: './dental-bridge.component.html',
  styleUrls: ['./dental-bridge.component.scss']
})
export class DentalBridgeComponent implements OnInit {

  constructor(
      @Inject(LOCALE_ID) public locale: string,
  ) { }

  ngOnInit(): void {
  }

}
