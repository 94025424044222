import {AfterViewChecked, AfterViewInit, Component, EventEmitter, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ActualInformationService} from "../../../core/services/actual-information.service";
import {ActualInformation} from "../../../core/models/actual-information.model";
import {filter} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-actual-informations',
    templateUrl: './actual-informations.component.html',
    styleUrls: ['./actual-informations.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ActualInformationsComponent implements OnInit {

    actualInformation: ActualInformation | undefined;
    @Output() setInformationDisplay = new EventEmitter<boolean>();
    showInformation = true;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private actualInformationService: ActualInformationService
    ) {
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        ).subscribe((e: any) => {
            if (this.router.url === '/') {
                this.showInformation = true;
                this.actualInformationService.getActualInformation().subscribe((value) => {
                    this.actualInformation = value;
                    this.onResponse(this.actualInformation.display)
                })
            } else {
                this.onCloseClick();
            }
        });

    }

    ngAfterViewInit() {
        if (this.actualInformation) {
            this.onResponse(this.actualInformation.display)
        }
    }

    onCloseClick() {
        this.showInformation = false;
        this.setInformationDisplay.emit(false);
    }
    
    onResponse(display: Boolean) {
        if (display) {
            this.setInformationDisplay.emit(true);
        }
    }

}
