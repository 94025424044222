<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Feste Zähne</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/FesteZahne-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Wählen Sie Qualität und 100 % Tragekomfort!
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n class="bold">Leiden Sie unter den Folgen von Zahnverlust und wünschen sich feste Zähne?</p>

                    <p i18n>Wir zeigen Ihnen die Lösung, womit das ästhetische Ergebnis perfekt und Ihre Lebensqualität
                        besser werden.</p>

                    <p i18n>Es ist für Sie kaum möglich, die neuen festen Zähne von natürlichen Zähnen zu unterscheiden.
                        Gerade Patienten, die vor den festen Zähnen eine Prothese trugen, sind von dem Ergebnis
                        regelrecht begeistert. Fixer Zahnersatz auf Implantaten gibt Ihnen das Gefühl eigener fester
                        Zähne.</p>

                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>Feste Zähne auf 6-8 Implantaten</h1>
            <p i18n>Mit festen Zähnen bei einer „auf 6-8 Implantaten befestigten fixen Brücke“ - im Oberkiefer sind
                mindestens acht und im Unterkiefer sechs Implantate erforderlich, um eine gute Stabilität zu erreichen -
                werden Sie endlich wieder selbstsicher essen und lachen können, ohne sich dabei Gedanken über Ihre Zähne
                machen zu müssen, wobei Sie auch noch mindestens 10 Jahre jünger aussehen als vorher.</p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Die Vorteile eines festsitzenden Zahnersatzes
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <div class="list">
                                <div i18n class="list__item">keine Gaumenplatte, keine Übelkeit</div>
                                <div i18n class="list__item">bester Tragekomfort durch festen Halt</div>
                                <div i18n class="list__item">Kaufunktion und Ästhetik wie bei den eigenen Zähnen</div>
                                <div i18n class="list__item">Topqualität</div>
                                <div i18n class="list__item">kein Fremdkörpergefühl</div>
                                <div i18n class="list__item">schönes Lächeln, wunderbare Ästhetik</div>
                                <div i18n class="list__item">hervorragende Stabilität</div>
                            </div>
                        </div>
                        <div class="images">
                            <div class="image">
                                <img src="assets/images/treatments/accordion/FesteZahne-accordion.png">
                            </div>
                        </div>
                    </div>
                    <p i18n>Die optimale Lösung bei einem zahnlosen Kiefer ist die Versorgung mit festsitzenden Zähnen, wenn
                        der Patient keinen herausnehmbaren Zahnersatz wünscht. Viele Patienten können und wollen sich
                        nicht an eine Vollprothese gewöhnen. Die meisten unserer Patient(inn)en, die an einem
                        vollständig zahnlosen Kiefer leiden, entscheiden sich für <span class="bold">die Methode der festen Zähne einer mit
                        sechs bzw. acht Implantaten befestigten Brücke.</span></p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Wie läuft die Behandlung ab?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Die Behandlung besteht aus mehreren Schritten. Bei festen Zähnen müssen Sie mit den folgenden
                        Behandlungsschritten rechnen:</p>
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Konsultation, Erstuntersuchung, CT-Aufnahme</div>
                                <div i18n class="information__text">Bei den Behandlungen streben wir nach höchstmöglicher
                                    Sicherheit. Deshalb ist die Anfertigung und Auswertung einer aktuellen CT-Aufnahme
                                    vor Erstellung von Kosten- und Behandlungsplänen sowie chirurgischen Eingriffen
                                    unerlässlich.
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Implantation
                                </div>
                                <div i18n class="information__text">Unser Kieferchirurg setzt die Implantate im Kieferknochen
                                    ein. In einer Sitzung ist es nur möglich, einen Kiefer mit Implantaten zu versorgen.
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div i18n class="information__title">Kontrolle und Nahtentfernung</div>
                                <div i18n class="information__text">nach 10 Tagen Kontrolle und Nahtentfernung</div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">4</div>
                            <div class="information">
                                <div i18n class="information__title">Freilegung von Implantaten, Abdrucknahme</div>
                                <div i18n class="information__text">nach 3-6 Monate Heilungszeit folgen die Freilegung von
                                    Implantaten, das Einsetzen von Heilungsschrauben und das Anfertigen eines Abdrucks.
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">5</div>
                            <div class="information">
                                <div i18n class="information__title">Gerüstprobe
                                </div>
                                <div i18n class="information__text">nach einer Woche Gerüstprobe, sofern erforderlich</div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">6</div>
                            <div class="information">
                                <div class="information__title" i18n>Fertigstellung</div>
                                <div class="information__text" i18n>Fertigstellung nach einer weiteren Woche</div>
                            </div>
                        </div>
                    </div>
                    <p><span i18n class="bold">Diese Daten dienen nur Ihrer Orientierung!</span><ng-template i18n> Wir planen die
                        Behandlungsschritte für alle Patienten
                        individuell, aufgrund ihrer persönlichen Anliegen, CT-Aufnahme und Knochensubstanz. Zu weiteren
                        Kosten kann es bei einer Implantation kommen, wenn ein vorheriger Knochenaufbau (mittels eines
                        künstlichen Knochenersatzmaterials) erforderlich ist. Das ist jedoch nur dann notwendig, wenn
                        die vorhandene Knochenmenge im Ober- oder Unterkiefer für eine Implantation nicht ausreichend
                        ist.</ng-template>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <h4 i18n class="content--main__title">Bei uns sind Sie in den besten Händen</h4>
        <p i18n>Als größte private Zahnklinik in Mosonmagyaróvár haben wir uns seit der Eröffnung 1992 auf das Thema feste
            Zähne
            spezialisiert und verfügen über umfangreiches Wissen sowie die Erfahrung aus tausenden von Implantationen.
            Unser
            klinikeigenes Labor ermöglicht es uns, schnell und optimal einen auf Ihre Bedürfnisse abgestimmten
            Zahnersatz
            herzustellen.
            <br>
            Unsere Zahnärzte haben bereits mehr als 50.000 Patient(inn)en aus dem deutschsprachigen Raum behandelt, die
            schon ein völlig neues Lebensgefühl mit ihren schönen, neuen Zähnen genießen.</p>
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/1.jpg">
</div>

