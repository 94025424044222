import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Offer, OfferSingletonWrapper, OfferWrapper} from "../models/offer.model";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ImageUrlService} from "./image-url.service";

@Injectable({
    providedIn: "root",
})
export class OfferService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService,
        private imageUrlService: ImageUrlService
    ) {
    }

    public getOffers(): Observable<Offer[]> {
        return this.http
            .get<OfferWrapper>(
                environment.apiUrl +
                "offers?locale=" +
                this.lang.getLang()
            )
            .pipe(map((value) => value.data))
            .pipe(map((value) =>
                value.map((item) => (
                    this.transformOfferData(item)
                ))
            ));

    }

    public getOffer(slug: string): Observable<Offer> {
        return this.http
            .get<OfferSingletonWrapper>(
                environment.apiUrl +
                "offers/" +
                slug +
                "?locale=" +
                this.lang.getLang()
            )
            .pipe(
                map((value) => (
                    this.transformOfferData(value.data)
                ))
            );
    }

    private transformOfferData(value: any) {
        return {
            id: value.id,
            title: value.attributes.title,
            slug: value.attributes.slug,
            subtitle: value.attributes.subtitle,
            lead: value.attributes.lead,
            main_picture: {
                alternativeText: value.attributes.main_picture.data.attributes.alternativeText,
                largeUrl: this.imageUrlService.getImageUrl(value.attributes.main_picture.data.attributes)
            },
            mood_picture: {
                alternativeText: value.attributes.mood_picture.data.attributes.alternativeText,
                largeUrl: this.imageUrlService.getImageUrl(value.attributes.mood_picture.data.attributes)
            },
            blog_text: value.attributes.blog_text,
            seo: value.attributes.seo,
        }
    }
}
