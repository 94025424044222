<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>GARANTIEBeSTIMMUNGEN
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/prices/top/Garantie-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Bei uns sind Sie garantiert in guten Händen</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Unsere Eurodent Zahnklinik und unsere Zahntechniklabor in Mosonmagyaróvár gewähren unseren
                        Patient(inn)en ausschließlich für die technischen Arbeiten der bei uns angefertigten Zahnersätze
                        eine Garantie. Die Garantien werden stets nach einheitlichen Richtlinien erbracht. Hierbei
                        stehen die hohe Qualität der Behandlungen und deren Gewährleistung stets im Mittelpunkt des
                        Handelns.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1></h1>
            <p i18n>Die Garantie kann ausschließlich hierorts in der Eurodent Zahnklinik in Ungarn in Anspruch genommen
                werden, wo Sie Ihren Zahnersatz angefertigt und erhalten haben. Voraussetzung der Garantie ist die
                jährliche Kontrolluntersuchung.</p>

            <p i18n>Bitte beachten Sie, dass Ihr Zahnarzt bzw. Ihre Zahnärztin nur aufgrund unserer Garantiebestimmungen
                verpflichtet ist, Mängel innerhalb von drei Jahren nach Eingliederung des Zahnersatzes für Sie
                kostenfrei zu beseitigen.
            </p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Sie erhalten 3 Jahre Garantie für den folgenden Zahnersatz bzw. zahntechnische Arbeit:
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">wenn das Porzellan von der Krone abbricht
                        </div>
                        <div i18n class="content--main__content__list__item">wenn der fixe Zahnersatz (Brücke) zerbricht
                        </div>
                        <div i18n class="content--main__content__list__item">wenn sich im Fall eines fixen Zahnersatzes
                            (Krone, Brücke) der Zement ablöst
                        </div>
                        <div i18n class="content--main__content__list__item">auf die gegossenen Teile und
                            Retentionsstifte,
                            wenn der Zement sich ablöst
                        </div>
                        <div i18n class="content--main__content__list__item">auf den Bruch der Klammer, das Geschiebe
                            oder
                            des Stegs bei kombiniertem Zahnersatz
                        </div>
                        <div i18n class="content--main__content__list__item">für den Implantatbruch
                        </div>
                        <div i18n class="content--main__content__list__item">für die Implantataufbauten</div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Sie erhalten 6 Monate Garantie:
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">bei einer Kompositfüllung oder
                            Kompositverblendung
                        </div>
                        <div i18n class="content--main__content__list__item">bei Inlay, Onlay und Veneer</div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    Sie erhalten 1 Jahr Garantie:
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Auf eine herausnehmbare Zahnprothese
                        </div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    Eingewöhnung! Wenn der Zahnersatz zwickt:
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Bei der Eingliederung von Zahnersatz sind Eingliederungsschwierigkeiten normal.</p>

                    <p i18n class="bold">Deshalb bitte: </p>
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">nicht ungeduldig werden, wenn Beschwerden
                            auftreten
                        </div>
                        <div i18n class="content--main__content__list__item">den Zahnersatz mindestens 2 Stunden vor Ihr
                            Kontrolltermin zu tragen
                        </div>
                        <div i18n class="content--main__content__list__item">keine eigenständigen Änderungen vornehmen
                        </div>
                        <div i18n class="content--main__content__list__item">Ratschläge des Zahnarztes befolgen</div>
                        <div i18n class="content--main__content__list__item">keinesfalls den Zahnersatz wechseln</div>
                    </div>
                    <p i18n>Wenden Sie sich stattdessen vertrauensvoll an uns, wir veranlassen die weiteren notwendigen
                        Schritte. Bei anhaltenden Beschwerden sollten Sie Ihren Zahnarzt aufsuchen. Dieser wird Sie
                        beraten und – sofern notwendig – Nachbesserungen vornehmen.</p>
                </div>
            </div>
        </div>

        <p i18n class="bold">Wichtig: Wir übernehmen jegliche Garantiereparaturen nur im Fall entsprechender Mundhygiene
            und
            sofern der Patient alle 6-12 Monate bei uns zu einer Nachkontrolle erscheint.</p>

        <p i18n>Die Garantie gilt nur, wenn Sie das Problem in unserer Eurodent Zahnklinik im Voraus per E-Mail oder
            telefonisch melden, und hierorts reparieren lassen. Wenn Sie sich bei einem anderen Arzt behandeln lassen,
            verfällt die Garantie. Die Garantie enthält keine Geld-Zurückzahlung. Fälle, welche nicht oben angeführt
            wurden, werden gesondert behandelt.
        </p>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/prices/bottom/2.jpg">
</div>
