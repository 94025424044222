<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Stegprothese</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Steghprothese-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>IMPLANTATGETRAGENE PROTHESE (Stegprothese)
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Die Stegprothese ist eine sehr gute Versorgungsmöglichkeit bei völliger Zahnlosigkeit. Wenn auch
                        Sie eine herausnehmbare aber befestigte Zahnprothese haben möchten, mit der Sie wieder
                        problemlos kauen können, während sie stabil bleibt, dann ist die Stegprothese die perfekte Wahl
                        für Sie.
                    </p>

                    <div class="content--top__lead__description__list">
                        <div i18n class="content--top__lead__description__list__title">Vorteile einer Stegprothese</div>
                        <div i18n class="content--top__lead__description__list__item">Es ist kostenfreundlich und effektiv.
                        </div>
                        <div i18n class="content--top__lead__description__list__item">Hat einen festen Halt, die Implantate
                            halten ihn stabil.
                        </div>
                        <div i18n class="content--top__lead__description__list__item">Gibt das Erlebnis des Kauens zurück.
                        </div>
                        <div i18n class="content--top__lead__description__list__item">Ist beim Sprechen und Lachen nicht
                            störend.
                        </div>
                        <div i18n class="content--top__lead__description__list__item">Verbessert Ihr Lebensqualität</div>
                    </div>

                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main" [ngClass]="locale === 'fr' ? 'margin':''">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>implantatgetragene Prothese
                mit Stegkonstruktion</h1>
            <p i18n>Implantatgetragene Prothesen haben einen festen Halt und ermöglichen somit die notwendige Kaufunktion und
                eine sichere Aussprache. Der feste Halt verschafft den Patienten ein angenehmes Sicherheitsgefühl und im
                Vergleich zur normalen Vollprothese viel mehr Kaukomfort.</p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Was ist eine Stegprothese?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div i18n class="text">
                            Wir fertigen für unsere Patienten eine sog. Stegprothese an, die über einen
                            Steg an den Implantaten fixiert wird. <span class="bold">Die Stegprothese ist eine auf 4 Implantaten befestigte
                            Zahnprothese. Um die gute Stabilität erreichen zu können sind pro Kiefer mindestens 4
                                Implantate erforderlich.</span>
                        </div>
                        <div class="images">
                            <div class="image">
                                <img src="assets/images/treatments/accordion/Stegprothese-accordion.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Schritte und DAUER Der BEHANDLUNG
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Konsultation, Erstuntersuchung, CT-Aufnahme</div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">falls nötig Zahnentfernung
                                </div>
                                <div i18n class="information__text">und Einsetzen eines Provisoriums (nach der Zahnentfernung
                                    sind 2-3 Monate Heilungszeit vorgeschrieben)
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div i18n class="information__title">Implantation</div>
                                <div i18n class="information__text">Untersuchung und Einsetzen der Implantatschrauben
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">4</div>
                            <div class="information">
                                <div i18n class="information__title">Abrucknahme</div>
                                <div i18n class="information__text">nach 3-6 Monaten Einheilphase erfolgt eine Kontrolle und
                                    Abdrucknahme
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">5</div>
                            <div class="information">
                                <div i18n class="information__title">Probe</div>
                                <div i18n class="information__text">nach einer Woche Stegprobe</div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">6</div>
                            <div class="information">
                                <div i18n class="information__title">Zahnprobe</div>
                                <div i18n class="information__text">nach einer weiteren Woche
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">7</div>
                            <div class="information">
                                <div i18n class="information__title">Fertigstellung</div>
                                <div i18n class="information__text">Fertigstellung nach einer weiteren Woche</div>
                            </div>
                        </div>
                    </div>
                    <p><span i18n class="bold">Diese Daten dienen nur Ihrer Orientierung!</span><span i18n> Wir planen die
                        Behandlungsschritte für alle Patienten
                        individuell, aufgrund ihrer persönlichen Anliegen, CT-Aufnahme und Knochensubstanz. Zu weiteren
                        Kosten kann es bei einer Implantation kommen, wenn ein vorheriger Knochenaufbau (mittels eines
                        künstlichen Knochenersatzmaterials) erforderlich ist. Das ist jedoch nur dann notwendig, wenn
                        die vorhandene Knochenmenge im Ober- oder Unterkiefer für eine Implantation nicht ausreichend
                        ist.</span>
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    WAS KOSTET EINE STEGPROTHESE?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-price">
                        <div class="text">
                            <p i18n>Beim Einbringen einer Stegprothese beinhalten die Kosten u.a. die
                            Panoramaröntgenaufnahme(n), das Implantat selber mit Aufbau, das Provisorium, die
                            Operationsgebühr, Medikamente, Prothese, den Steg, sowie die Metallplatte und Halteelemente.</p>

                            <p i18n>Die Gesamtkosten der Behandlung werden von mehreren Elementen, z.B. vom Typ und dem Material
                            der Bestandteile, beeinflusst. Erst nach einer Erstuntersuchung können wir Ihnen einen
                            individuellen Heil- und Kostenplan erstellen.</p>

                        </div>
                        <div class="price-card">
                            <div i18n class="title">Die Kosten einer implantatgetragenen Prothese auf Stegkonstruktion
                                belaufen sich auf mindestens
                            </div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag"><span class="bold">2940 </span>€</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/1.jpg">
</div>

