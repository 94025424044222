<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Veneer (Verblendschale)</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Veneer-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Veneer - richtiges „Hollywood-Lächeln”
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Veneers sind hauchdünne Keramikschalen, die mittels Spezialkleber auf der Zahnoberfläche der
                        Frontzähne aufgebracht werden. Durch Veneers können Sie somit ein perfektes sog.
                        „Hollywood-Lächeln“ erhalten. Diese Technologie wird besonders bei Vorderzähnen angewendet, wenn
                        Ihre Zähne aus ästhetischen Gründen verschönert werden sollen.
                    </p>


                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Wem empfehlen wir Veneers?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Wer unter einem Diastema leidet</div>
                        <div i18n class="content--main__content__list__item">Wer verfärbte Zähne hat</div>
                        <div i18n class="content--main__content__list__item">Wer leichte Zahnfehlstellungen hat</div>
                        <div i18n class="content--main__content__list__item">Wem Zahn nach einer Wurzelbehandlung verfärbt hat</div>
                        <div i18n class="content--main__content__list__item">Wem Zahn abgebrochen oder beschädigt ist</div>
                        <div i18n class="content--main__content__list__item">Wer verdrehte Frontzähne hat</div>
                        <div i18n class="content--main__content__list__item">Wem Zahnlücken zu korrigieren sind</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Vorteile der Veneers
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Bei der Behandlung verlieren Sie fast keine natürliche Zahnsubstanz
                        </div>
                        <div i18n class="content--main__content__list__item">Sie erhalten ein neues sog. „Hollywood–Lächeln”</div>
                        <div i18n class="content--main__content__list__item">Hervorragende, dauerhafte Ästhetik und natürliches Aussehen</div>
                        <div i18n class="content--main__content__list__item">Sehr hohe Bioverträglichkeit</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    Ablauf der Behandlung
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Unsere Zahnärzte in der Eurodent Zahnklinik können die Veneers in 2 Sitzungen anfertigen</div>
                        <div i18n class="content--main__content__list__item">Die Anfertigungszeit beträgt ca. 5 Arbeitstage
                        </div>
                        <div i18n class="content--main__content__list__item">Bis zur Fertigstellung der Veneers tragen Sie eine provisorische Schale.
                        </div>
                    </div>
                    <p class="no-margin"><span i18n class="bold">Diese Daten dienen nur Ihrer Orientierung!</span><ng-template i18n>Wir planen die
                        Behandlungsschritte für jeden Patienten individuell unter Beachtung der persönlichen Anliegen
                        sowie unter Berücksichtigung des Mundzustandes.</ng-template></p>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/8.jpg">
</div>

