import {AfterViewInit, Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Opening} from "../../../core/models/opening.model";
import {OpeningService} from "../../../core/services/opening.service";
import * as moment from 'moment';
import {RichTextService} from "../../../core/services/rich-text.service";
import {Router} from "@angular/router";
import {RoutesService} from "../../../core/services/routes.service";
import {ScrollViewService} from '../../../core/services/scroll-view.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {

    opening: Opening | undefined;
    showHoliday = false;

    constructor(
        private openingService: OpeningService,
        public richtextService: RichTextService,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string,
        public routesService: RoutesService,
        private scrollViewService: ScrollViewService,
    ) {
    }

    ngOnInit(): void {
        this.openingService.getOpening().subscribe((value) => {
            this.opening = value;
            let from = this.opening.holiday_hours.from;
            let to = this.opening.holiday_hours.to;
            let today = moment().format('YYYY-MM-DD');

            if (today >= from && today <= to) {
                this.showHoliday = true;
            }
        });
    }

    ngAfterViewInit(): void {
        // scroll down to contact form if width is smaller than desktop and url contains anchor
        if (this.router.url.indexOf("#webform") > -1 && window.innerWidth < 768) {
            this.scrollViewService.scrollViewToPositionWithTimeout(665, 502);
        } else if (this.router.url.indexOf("#webform") > -1 && window.innerWidth < 1440) {
            this.scrollViewService.scrollViewToPositionWithTimeout(332, 502);
        } else {
            this.scrollViewService.scrollViewToPositionWithTimeout(0, 502);
        }
    }
}
