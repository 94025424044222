import {Component, EventEmitter, HostListener, Inject, LOCALE_ID, OnInit, Output} from '@angular/core';
import {OfferService} from "../../services/offer.service";
import {Offer} from "../../models/offer.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {LanguageService} from "../../services/language.service";
import {filter} from "rxjs";
import {OpeningService} from "../../services/opening.service";
import {Opening} from "../../models/opening.model";
import {RichTextService} from "../../services/rich-text.service";
import {RoutesService} from "../../services/routes.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    hamburgerToggle = false;
    languageToggle = false;
    hasBackground = false;
    offers: Offer[] = [];

    openedSubmenu = '';
    isPrimary = true;

    implantToggle = false;
    orthodonticsToggle = false;
    denturesToggle = false;
    prophylaxisToggle = false;
    diagnosisToggle = false;
    opening: Opening | undefined;

    clickedSubmenu: any;

    languages = [
        'de',
        'fr',
        'it',
        'en'
    ]

    prefixes = [
        'en.',
        'it.',
        'fr.'
    ]

    // set host for language prefixes
    baseUrl = document.location.host;
    host = (this.prefixes.indexOf(this.baseUrl.slice(0, 3)) < 0) ? this.baseUrl : this.baseUrl.slice(3);

    @Output() setInformationDisplay = new EventEmitter<boolean>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private offerService: OfferService,
        private openingService: OpeningService,
        public langService: LanguageService,
        public richtextService: RichTextService,
        @Inject(LOCALE_ID) public locale: string,
        public routesService: RoutesService
    ) {
    }

    ngOnInit(): void {
        if (window.innerWidth > 1365) {
            this.hamburgerToggle = true;
        }
        this.offerService.getOffers().subscribe((value) => {
            this.offers = value;
        })
        this.openingService.getOpening().subscribe((value) => {
            this.opening = value;
        });
        // navbar background color from url
        this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        ).subscribe((e: any) => {
            this.isPrimary = window.location.href.split("/").slice(-1)[0] === "";
        });
        this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        ).subscribe((e: any) => {
            if (window.innerWidth < 1366) {
                this.hamburgerToggle = false;
            }
        });
    }

    openSubmenu(submenu: string) {
        if (window.innerWidth < 1366) {
            this.openedSubmenu = submenu;
            const information = document.getElementById('actualInformation');
            const informationBlockHeight = information?.offsetHeight || 0;
            const menu = document.getElementById(submenu);
            this.clickedSubmenu = document.querySelector<HTMLElement>('.navbar__collapse__item__submenu');

            if (window.innerWidth < 1366 && information) {
                if (window.innerWidth > 767) {
                    menu!.style.top = informationBlockHeight + 91 + "px";
                } else {
                    menu!.style.top = informationBlockHeight + 139 + "px";
                }
            } else {
                if (window.innerWidth > 767) {
                    menu!.style.top = 91 + "px";
                } else {
                    menu!.style.top = 139 + "px";
                }
            }
        }
    }

    closeSubmenu(submenu: string) {
        this.openedSubmenu = '';
        this.clickedSubmenu = null;
    }

    onInformationDisplay(display: boolean) {
        this.setInformationDisplay.emit(display);
        if (!display) {
            this.openSubmenu(this.openedSubmenu);
            const menu = document.getElementById(this.openedSubmenu);
            if (window.innerWidth > 767) {
                menu!.style.top = 91 + "px";
            } else {
                menu!.style.top = 139 + "px";
            }
        }
    }

    onToggleHamburger() {
        this.hamburgerToggle = !this.hamburgerToggle;
        if (window.innerWidth < 1366) {
            this.hasBackground = this.hamburgerToggle;
        }
        this.closeSubmenu(this.openedSubmenu);
    }

    onToggleLanguage() {
        this.languageToggle = !this.languageToggle;
    }

    setAllSubToggleFalse() {
        this.implantToggle = false;
        this.orthodonticsToggle = false;
        this.denturesToggle = false;
        this.prophylaxisToggle = false;
        this.diagnosisToggle = false;
    }

    onToggleImplant() {
        if (this.implantToggle === true) {
            this.setAllSubToggleFalse();
        } else {
            this.setAllSubToggleFalse();
            this.implantToggle = true;
        }
    }

    onToggleOrthodontics() {
        if (this.orthodonticsToggle === true) {
            this.setAllSubToggleFalse();
        } else {
            this.setAllSubToggleFalse();
            this.orthodonticsToggle = true;
        }
    }

    OnToggleDentures() {
        if (this.denturesToggle === true) {
            this.setAllSubToggleFalse();
        } else {
            this.setAllSubToggleFalse();
            this.denturesToggle = true;
        }
    }

    onToggleProphylaxis() {
        if (this.prophylaxisToggle === true) {
            this.setAllSubToggleFalse();
        } else {
            this.setAllSubToggleFalse();
            this.prophylaxisToggle = true;
        }
    }

    onToggleDiagnosis() {
        if (this.diagnosisToggle === true) {
            this.setAllSubToggleFalse();
        } else {
            this.setAllSubToggleFalse();
            this.diagnosisToggle = true;
        }
    }

    onChangeLang() {
        this.languageToggle = !this.languageToggle;
    }

    @HostListener("window:resize", ["$event"])
    onResize(event: any) {
        if (window.innerWidth < 1366) {
            this.hamburgerToggle = false;
        } else {
            this.hamburgerToggle = true;
        }
    }

    @HostListener("window:scroll") scrolling() {
        if (window.scrollY > 0) {
            this.hasBackground = true;
        } else this.hasBackground = false;
    }

}
