import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {RoutesService} from "../../../core/services/routes.service";

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {


  constructor(
      @Inject(LOCALE_ID) public locale: string,
  ) { }

  ngOnInit(): void {
  }

}
