<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Unser Team</h1>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <ng-container *ngFor="let employee of employeeRoles">
            <h2>{{employee.title}}</h2>
            <div class="employee-card-container">
                <div class="employee-card" *ngFor="let person of employee.person">
                    <div class="employee-card__image">
                        <img src="{{person.picture.largeUrl}}"
                             alt="{{person.picture.alternativeText}}">
                    </div>
                    <div class="employee-card__details">
                        <div class="employee-card__details__name">{{person.name}}</div>
                        <div class="employee-card__details__position">{{person.position}}</div>
                        <div class="employee-card__details__separator"></div>
                        <div class="employee-card__details__language">{{person.languages}}</div>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div>

