import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {RoutesService} from "../../../../core/services/routes.service";

@Component({
  selector: 'app-free-transport',
  templateUrl: './free-transport.component.html',
  styleUrls: ['./free-transport.component.scss']
})
export class FreeTransportComponent implements OnInit {

  constructor(
      @Inject(LOCALE_ID) public locale: string,
      public routesService: RoutesService
  ) { }

  ngOnInit(): void {
  }

}
