import {Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {filter} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {Opening} from "../../models/opening.model";
import {OpeningService} from "../../services/opening.service";
import * as moment from "moment";
import {RichTextService} from "../../services/rich-text.service";
import {PrivacyPolicyService} from "../../services/privacy-policy.service";
import {PrivacyPolicy} from "../../models/privacy-policy.model";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  isPrimary = true;
  opening: Opening | undefined;
  privacy: PrivacyPolicy | undefined;

  prefixes = [
    'en.',
    'it.',
    'fr.'
  ]

  // set host for language prefixes
  baseUrl = document.location.host;
  host = (this.prefixes.indexOf(this.baseUrl.slice(0, 3)) < 0) ? this.baseUrl : this.baseUrl.slice(3);
  privacyLink = '';
  privacyLinkHun = '';

  constructor(
      private router: Router,
      private openingService: OpeningService,
      public richtextService: RichTextService,
      private privacyPolicyService: PrivacyPolicyService,
      @Inject(LOCALE_ID) public locale: string,
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd)
    ).subscribe((e: any) => {
      this.isPrimary = window.location.href.split("/").slice(-1)[0] === "" || window.location.href.split("/").slice(-1)[0] === "kontakt" || window.location.href.split("/").slice(-1)[0] === "danke";
    });
    this.openingService.getOpening().subscribe((value) => {
      this.opening = value;
    });
    this.privacyPolicyService.getPrivacyPolicy().subscribe((value) => {
      this.privacy = value;
      //create privacy download link
      this.privacyLink = 'https://en.' + this.host + this.privacy.privacy_policy.url;
      this.privacyLinkHun = 'https://en.' + this.host + this.privacy.hungarian_privacy_policy.url;
    })
  }

}
