<div class="actual-information" id="actualInformation" *ngIf="actualInformation && actualInformation.display" [ngClass]="actualInformation? 'show': ''">
    <div class="actual-information-container" *ngIf="showInformation">
        <img class="actual-information__icon" src="assets/icons/help.svg">
        <div class="actual-information__description">
            <div class="actual-information__description__title">{{actualInformation.title}}</div>
            <div class="actual-information__description__text" [innerHTML]="actualInformation.description"></div>
            <a i18n class="actual-information__description__link" *ngIf="actualInformation.link" routerLink="{{actualInformation.link}}">Weiter</a>
        </div>
        <img class="actual-information__close" src="assets/icons/info-close.svg" (click)="onCloseClick()">
    </div>
</div>
