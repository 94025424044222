<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Kontakt</h1>
        </div>
    </div>
</div>
<div class="content--main" >
    <div class="content--main__form">
        <div class="content-container">
            <app-contact-form></app-contact-form>
        </div>
    </div>
    <div class="content--main__contact-container">
        <div class="content-container">

            <div class="contact-card">
                <div class="contact-card__icon">
                    <img src="assets/icons/marker.svg">
                </div>
                <div i18n class="contact-card__title">Adresse</div>
                <div i18n class="contact-card__details">H-9200 Mosonmagyaróvár<br>Győri kapu utca 7.</div>
                <div class="contact-card__bottom"></div>
            </div>
            <div class="contact-card">
                <div class="contact-card__icon">
                    <img src="assets/icons/envelope.svg">
                </div>
                <div i18n class="contact-card__title">E-Mail</div>
                <div i18n class="contact-card__details">eurodent@eurodent.hu</div>
                <div class="contact-card__bottom"></div>
            </div>
            <div class="contact-card">
                <div class="contact-card__icon">
                    <img src="assets/icons/marker.svg">
                </div>
                <div i18n class="contact-card__title">Facebook</div>
                <div i18n class="contact-card__details">/EurodentZahnklinik</div>
                <div i18n class="contact-card__bottom"></div>
            </div>
        </div>
    </div>
    <div class="content--main__opening" *ngIf="opening">
        <div class="content-container">
            <h2 i18n class="title">ÖFFNUNGSZEITEN</h2>
            <div class="holiday" *ngIf="showHoliday">{{opening.holiday_hours.opening_hours}}</div>
            <div class="separator"></div>
            <div class="opening" [innerHTML]="richtextService.trustHTML(opening.default_openning)"></div>
            <img src="assets/illustrations/opening.svg">
        </div>
    </div>
    <div class="content-container">
        <div class="content--main__tips">
            <h2 i18n>Reisetipps</h2>
            <div class="separator"></div>
            <p i18n>Unsere Zahnklinik befindet sich in Mosonmagyaróvár in Westungarn, direkt an der Grenze zu Österreich und
                zur Slowakei. Wir helfen Ihnen, egal ob Sie mit dem Auto, dem Zug oder dem Flugzeug zu uns reisen!</p>
            <a class="navigation-button" routerLink="/{{routesService.getRouterLink('travel-information')}}">
                <span i18n class="navigation-button__label">reisetipps</span>
                <div class="navigation-button__arrow">
                    <img src="assets/icons/navigation-arrow.svg">
                </div>
            </a>
        </div>
    </div>
    <div class="map__background"></div>
    <div class="map__container">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2676.146468278106!2d17.2690625!3d47.8754914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c77e1473d6ad1%3A0xb5ca6ffa7a3ab1ab!2zTW9zb25tYWd5YXLDs3bDoXIsIEd5xZFyaWthcHUgdS4gNywgOTIwMA!5e0!3m2!1shu!2shu!4v1656627396841!5m2!1shu!2shu"
                width="800" height="420" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>

