import { Component, OnInit } from '@angular/core';
import {RoutesService} from "../../../../core/services/routes.service";

@Component({
  selector: 'app-free-examination',
  templateUrl: './free-examination.component.html',
  styleUrls: ['./free-examination.component.scss']
})
export class FreeExaminationComponent implements OnInit {

  constructor(
      public routesService: RoutesService
  ) { }

  ngOnInit(): void {
  }

}
