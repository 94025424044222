<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Krankenversicherung
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/prices/top/Krankenversicherung-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Krankenkassenzuschuss</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Seit 2004 gehört auch Ungarn zur EU, daher müssen die Krankenkassen u. a. aus Österreich,
                        Deutschland, Italien und der Schweiz denselben Pflichtanteil der Kosten bei einer Behandlung in
                        Ungarn wie zu Hause übernehmen.
                    </p>
                    <app-top-navigation></app-top-navigation>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">

        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Wie können Sie den Krankenversicherungsbeitrag beanspruchen?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Von unseren Preisen ist der Krankenkassenzuschuss noch nicht abgezogen. Wir erstellen Ihnen einen
                    Heil- und Kostenplan, den Sie dann bei Ihrer Krankenkasse einreichen können.</p>

                    <p i18n>Wenn die Krankenkasse diesen genehmigt hat, können Sie mit der Behandlung bei uns anfangen.</p>

                    <p i18n>Am Ende der Behandlung erhalten Sie von uns einen abgerechneten Heil- und Kostenplan (Rechnung), den
                    Sie wieder bei Ihrer Krankenkasse abgeben und danach erhalten Sie den Krankenkassenzuschuss. Die von
                    uns ausgestellten Rechnungen können Sie auch beim Finanzamt einreichen.</p>

                    <p class="bold" i18n>Nehmen Sie bitte Kontakt mit Ihrer Krankenkasse vor dem Beginn der Behandlung auf und erkundigen Sie
                    sich, was für Dokumente benötigt werden und ob der Kostenvoranschlag vor dem Behandlungsanfang
                    genehmigt werden muss.</p>

                    <p i18n>Bei Bedarf können wir Ihnen weitere Dokumente zur Verfügung stellen.</p>

                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/prices/bottom/1.jpg">
</div>








