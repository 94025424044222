<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>3D CT Digitale Volumentomographie</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Volumentomographie-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>um 100prozentige Sicherheit
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Bei den Behandlungen streben wir uns nach 100prozentiger Sicherheit. Deswegen ist die Anfertigung
                        und Auswertung einer aktuellen CT-Aufnahme vor Erstellung von Kosten- und Behandlungsplänen und
                        chirurgischen Eingriffen in unserer Eurodent Zahnklinik unerlässlich.</p>
                    <p i18n>In unserer Eurodent Zahnklinik werden die Kiefer mithilfe eines zahnmedizinischen Computer Tomographs
                        untersucht. Volumentomographie ermöglicht eine Aufnahme anzufertigen, welche um Größenordnungen
                        detaillierter und präziser als die beste Panoramaröntgenaufnahme ist. Die Aufnahme kann in wenigen
                        Minuten (2-3 Minuten) angefertigt werden. </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <div class="content--main__content__list">
                <div i18n class="content--main__content__list__item"><span>Die Benutzung des Volumentomographie Gerätes ist
                            völlig sicher: Das Gerät <span class="bold">kann von Kindern, älteren Menschen, sogar während der
                                Schwangerschaft in Anspruch genommen werden.</span></span>
                </div>
                <div i18n class="content--main__content__list__item">Mithilfe des Volumentomographie Gerätes können
                    kieferchirurgische Eingriffe völlig sicher geplant und durchgeführt werden, da der
                    Kieferchirurg, der die Behandlungen durchführt, das Operationsgebiet aus allen Richtungen
                    detailliert untersuchen kann.
                </div>
                <div i18n class="content--main__content__list__item">Die Volumentomographie Aufnahme macht es
                    möglich, durch andere Methoden unmerkbare, Zehntelmillimeter große Veränderungen zu
                    entdecken, zu lokalisieren und zu untersuchen.
                </div>
                <div i18n class="content--main__content__list__item">Mithilfe des Gerätes kann eine Untersuchung
                    und Zustandsermessung in 3 Dimensionen durchgeführt werden.
                </div>
            </div>
        </div>

    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/11.jpg">
</div>

