import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';

@Component({
  selector: 'app-fixed-braces',
  templateUrl: './fixed-braces.component.html',
  styleUrls: ['./fixed-braces.component.scss']
})
export class FixedBracesComponent implements OnInit {

  constructor(
      @Inject(LOCALE_ID) public locale: string,
  ) { }

  ngOnInit(): void {
  }

}
