import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {WelcomePackage} from "../../../core/models/welcome-package.model";
import {WelcomePackageService} from "../../../core/services/welcome-package.service";

@Component({
  selector: 'app-welcome-package',
  templateUrl: './welcome-package.component.html',
  styleUrls: ['./welcome-package.component.scss']
})
export class WelcomePackageComponent implements OnInit {
  welcomePackage: WelcomePackage | undefined;

  constructor(
      private welcomePackageService: WelcomePackageService,
      @Inject(LOCALE_ID) public locale: string,
  ) { }

  ngOnInit(): void {
    this.welcomePackageService.getWelcomePackage().subscribe((value) => {
      this.welcomePackage = value;
    });

  }

}
