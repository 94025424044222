<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>CAD/CAM Technologie
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/CAD-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Volldigitale Technologie bei der Eurodent Zahnklinik
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Unter CAD/CAM-Zahnersatz versteht man die Anfertigung von Kronen, Brücken oder Implantatzubehör
                        anhand computergestützter Technologie.</p>
                    <p i18n> Für die Herstellung von optisch ansprechendem und hochwertigem Zahnersatz arbeiten wir in der
                        Eurodent Zahnklinik mit computergesteuerter CAD/CAM-Technik, die sehr präzise Ergebnisse
                        erzielt. Das Ziel von CAD/CAM in unserer Eurodent Dentaltechnik ist, die Entwicklungs- und
                        Herstellungsprozesse von Zahnersatz zu verbessern.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Die Vorteile von CAD/CAM-Zahnersatz
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <div class="list">
                                <div i18n class="list__item"><span><span class="bold">Ästhetik:</span> Die verwendete Vollkeramik
                                    weist überragende ästhetische Eigenschaften auf und wirkt täuschend echt.</span>
                                </div>
                                <div i18n class="list__item"><span><span class="bold">Funktion:</span> Durch die präzise
                                    Produktionstechnik ist die Krone in aller Regel besonders passgenau, was das Risiko
                                    einer Randspaltkaries verringert, und hält langfristig.</span>
                                </div>
                                <div i18n class="list__item"><span><span class="bold">Verträglichkeit:</span> Die Vollkeramik ist
                                    außerdem metallfrei und daher bioverträglich (antiallergen) sowie unempfindlich
                                    gegenüber Hitze- und Kältereizen.</span>
                                </div>
                                <div i18n class="list__item"><span><span class="bold">Schnellere Anfertigung:</span> Mit dieser
                                    Methode ist kein konventioneller Abdruck mehr notwendig, und auch die zeitaufwändige
                                    Herstellung entfällt.</span>
                                </div>
                            </div>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/CAD-accordion.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Ihre gesamte Zahnbehandlung erfolgt digital
                    <img alt="content accordion icon" i18n-alt src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Digitale Panoramaröntgenaufnahme</div>
                        <div i18n class="content--main__content__list__item">Digitale 3D-Volumentomographie</div>
                        <div i18n class="content--main__content__list__item">3D-Laserscannerabdruck</div>
                        <div i18n class="content--main__content__list__item">Digitalisierte CAD/CAM-Zahnersatzplanung und
                            High-Tech-Anfertigung
                        </div>
                    </div>
                    <p i18n>Mit einem Laserscanner und dem Einsatz der CAD-Technik wird ein 3D-Abdruck des betroffenen Zahns
                        gemacht, digital eingelesen und gespeichert. Mit Hilfe des Computersystems werden die
                        3D-Aufnahmen zu einem virtuellen 3D-Modell zusammengefügt, sodass Ihr Zahn in bis zu 100-facher
                        Vergrößerung betrachtet werden kann. Im digitalen Modell werden die fehlenden Zahnpartien
                        rekonstruiert. Das CAD/CAM-System ermöglicht so die schnelle, einfache und sehr präzise
                        Anfertigung von individualisiertem Zahnersatz.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/11.jpg">
</div>

