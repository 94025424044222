import {Component, OnInit} from '@angular/core';
import {BlogService} from "../../../core/services/blog.service";
import {Blog} from "../../../core/models/blog.model";
import {ActivatedRoute} from "@angular/router";
import {RichTextService} from "../../../core/services/rich-text.service";
import {MetatagService} from "../../../core/services/metatag.service";

@Component({
    selector: 'app-blog-page',
    templateUrl: './blog-page.component.html',
    styleUrls: ['./blog-page.component.scss'],
})
export class BlogPageComponent implements OnInit {

    blog: Blog | undefined;

    constructor(
        private route: ActivatedRoute,
        private blogService: BlogService,
        public richtextService: RichTextService,
        private metaTagService: MetatagService
    ) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((paramMap) => {
            const slug = paramMap.get("slug");
            this.blogService.getBlog(slug!).subscribe((value) => {
                this.blog = value;
                //  set metatags for seo
                this.metaTagService.updateMetaTags(this.blog.seo);
            })
        })
    }

    changeListStyle(text: any): string {
        return text.replaceAll('<ul style="list-style-type:circle;">', '<ul class="circle">')
            .replaceAll('<ol style="list-style-type:decimal;">', '<ol class="decimal">')
            .replaceAll('<ol style="list-style-type:upper-roman;">', '<ol class="upper-roman">')
            .replaceAll('<ul style="list-style-type:disc;">', '<ul class="disc">')
            .replaceAll('<li>', '<li><span>')
            .replaceAll('</li>', '</span></li>');
    }

}
