<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Kostenlose<br>Erstuntersuchung</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/company-services/top/Erstuntuntersuchung-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>KONSULTATIONEN SIND IN UNSERER KLINIK GRATIS</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n class="content--top__lead__description__title">Sind Sie an einer schmerzfreien Zahnbehandlung auf
                        hohem Niveau interessiert?<br>
                        Dann sehen wir Ihrer Terminanfrage mit Interesse entgegen!</p>
                    <p i18n>Win unserem Eurodent Zahn- und Implantatzentrum in Ungarn erhalten Sie schöne neue Zähne – zu
                        einem guten Preis, ohne dabei Abstriche an der Qualität befürchten zu müssen. Einen genauen
                        Kosten- und Behandlungsplan können wir erst nach einer aktuellen (d.h. nicht älter als 3 Monate
                        alten) Panorama-Röntgenaufnahme und einer kostenlosen und unverbindlichen Erstuntersuchung bei
                        uns zusammenstellen.</p>
                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">

    <div class="content-container">

        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    WIE LÄUFT EINE ERSTUNTERSUCHUNG AB?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div class="information__title"></div>
                                <div i18n class="information__text">Wenn Sie ein Röntgenbild haben, das nicht älter als 3
                                    Monate ist, können Sie es für die Konsultation mitbringen, oder wir erstellen hier
                                    vor Ort eine Röntgenaufnahme (im Wert von 50 Euro).
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div class="information__title"></div>
                                <div i18n class="information__text">Anhand der Röntgenaufnahme sowie einer Untersuchung, bei
                                    der Sie auch Ihre persönlichen Anliegen mit dem Zahnarzt besprechen können,
                                    erstellen wir einen individuellen Heil- und Kostenplan.
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div class="information__title"></div>
                                <div i18n class="information__text">Wenn der von uns erstellte Therapie- und Kostenplan Ihren
                                    Vorstellungen entspricht, können wir mit Ihnen die nächsten Behandlungstermine
                                    vereinbaren und mit der Zahnbehandlung anfangen. Zu der Behandlung selbst werden wir
                                    weitere Röntgenbilder benötigen und erstellen.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    VORHERIGER HEIL- UND KOSTENPLAN, WENN SIE AUS GRÖßERER ENTFERNUNG ZU UNS FAHREN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div i18n class="content__accordion__content">
                    Wenn Sie aus größerer Entfernung zu uns kommen, können wir Ihnen einen vorherigen kostenlosen und
                    unverbindlichen Heil- und Kostenplan aufgrund einer aktuellen Panorama-Röntgenaufnahme oder eines
                    von Ihrem derzeitigen Zahnarzt verfassten Befund- und Therapieplans erstellen.
                </div>
            </div>
        </div>

        <div class="content--main__content__text-image">
            <img class="image" src="assets/images/company-services/accordion/Erstuntuntersuchung-accordion.png">
            <div i18n class="text">Unsere Zahnärzte haben bereits mehr als 50.000 Patient(inn)en aus dem deutschsprachigen
                Raum behandelt, die schon ein völlig neues Lebensgefühl mit ihren schönen, neuen Zähnen genießen.
            </div>
        </div>

        <p i18n class="bold">Für weitere Informationen, eine Terminreservierung oder individuelle Beratung kontaktieren Sie
            uns bitte!</p>
        <p i18n>Gehören auch Sie zu unseren zufriedenen Patienten und kehren Sie mit perfekten, wunderschönen Zähnen nach
            Hause zurück!</p>

        <a class="navigation-button" routerLink="{{routesService.getRouterLink('contact')}}">
            <span i18n class="navigation-button__label">kontakt</span>
            <div class="navigation-button__arrow">
                <img src="assets/icons/navigation-arrow.svg">
            </div>
        </a>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/company-services/bottom/Erstuntuntersuchung-bottom.jpg">
</div>

