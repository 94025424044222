<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Panoramaröntgenaufnahme
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Panoramarontgen-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Digitales Röntgenbild, Röntgendiagnostik
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Für die Erstellung eines individuellen Behandlungsplanes ist eine aktuelle Röntgenaufnahme
                        unerlässlich. Ohne sie kann keine Behandlung erfolgen.</p>
                    <p i18n>Anhand eines Panoramaröntgenbildes können der Zahnarzt und der Kieferchirurg detaillierte
                        Informationen über das Gebiss und die Kieferknochen erhalten. Knochensubstanz und Gewebe werden
                        kontrolliert und man prüft, inwieweit die Zähne belastet werden können. So kann man die
                        Zahnbehandlung genau planen.
                    </p>
                    <app-top-navigation></app-top-navigation>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <div class="content--main__content__list">
                <div class="content--main__content__list__item"><ng-template i18n>Das von uns benutzte digitale Panoramaröntgen
                    ist eine moderne Lösung. Ein unschlagbarer Vorteil der digitalen Röntgentechnik ist die um</ng-template>
                    <span i18n class="bold" i18n>bis zu 90 Prozent reduzierte Strahlenbelastung</span><ng-template i18n> gegenüber der klassischen Analogtechnik.</ng-template>
                </div>
                <div i18n class="content--main__content__list__item">Eine Röntgenaufnahme dauert ca. 2-5 Minuten. Vor
                    der Aufnahme ist keine komplizierte Vorbereitung nötig, eine Bleischürze schützt Sie vor der
                    Röntgenstrahlung.
                </div>
                <div i18n class="content--main__content__list__item">Ein Detektor speichert die Röntgenstrahlen in
                    digitaler Form und das Röntgenbild kann sofort auf dem Computerbildschirm angezeigt werden.
                </div>
                <div i18n class="content--main__content__list__item">Mithilfe einer Panoramaröntgenaufnahme können
                    etliche Zahnprobleme aufgedeckt werden, wie zum Beispiel Karies, Parodontose, Knochenabbau,
                    Zysten oder Entzündungen, was mit dem bloßen Auge nicht erkennbar ist.
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/10.jpg">
</div>

