<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Unsere Klinik
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/about-us/top/UnsereKlinik-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Wo Ihre Wünsche und Ansprüche im Vordergrund stehen</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <div class="content--top__lead__description__list">
                        <div i18n class="content--top__lead__description__list__title">Eurodent Zahn- und Implantatzentrum
                            ist vor allem für Patient(inn)en, die:
                        </div>
                        <div i18n class="content--top__lead__description__list__item">an einem hochwertigen, aber bezahlbaren
                            Zahnersatz interessiert sind
                        </div>
                        <div i18n class="content--top__lead__description__list__item">ihre Zahnbehandlung in einem der
                            führenden Zahn- und Implantatzentren der Region durchführen lassen möchten, wo ihr
                            persönliches Anliegen im Mittelpunkt steht
                        </div>
                        <div i18n class="content--top__lead__description__list__item">nach vielfältigen und niveauvollen
                            zahnmedizinischen Leistungen suchen.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>Die Welt ist offen. Lassen Sie Ihre Zähne dort machen, wo es sich am meisten lohnt!</h1>
            <p i18n>Beim EURODENT Zahn- und Implantatzentrum stehen Ihre Wünsche und Ansprüche im Vordergrund. Ihr Aufenthalt
                und Ihre Sitzungen werden so angenehm wie möglich gestaltet.</p>
            <p i18n>Unser renommiertes Zahn- und Implantatzentrum befindet sich in Mosonmagyaróvár, in Westungarn, gleich an
                der
                österreichisch-ungarischen Grenze, nur 50 Autominuten von Wien entfernt.</p>
            <p i18n>Die meisten Patient(inn)en von uns kommen aus dem Ausland, ihre Behandlung ist von der Kontaktaufnahme
                bis
                zur Heimkehr ohne Sprachbarriere garantiert.</p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    WARUM SOLLTEN SIE UNS WÄHLEN?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">mehr als 30 Jahre Erfahrung</div>
                        <div i18n class="content--main__content__list__item">mehr als 60.000 zufriedene Patienten</div>
                        <div i18n class="content--main__content__list__item">bis zu 50-60% Ersparnis bei Ihrer
                            Zahnbehandlung
                        </div>
                        <div i18n class="content--main__content__list__item">hauseigenes Zahntechniklabor</div>
                        <div i18n class="content--main__content__list__item">deutsche und andere Krankenkassen akzeptieren
                            unsere Rechnungen
                        </div>
                        <div i18n class="content--main__content__list__item">professionelle Therapien auf höchsten Niveau mit
                            den besten Materialien
                        </div>
                        <div i18n class="content--main__content__list__item">kostenlose Anrufmöglichkeit</div>
                        <div i18n class="content--main__content__list__item">kostenloser Abholdienst</div>
                        <div i18n class="content--main__content__list__item">kostenlose Unterkunft</div>
                        <div i18n class="content--main__content__list__item">Organisation von Freizeitprogrammen während
                            Ihres Aufenthaltes
                        </div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    DIE GESCHICHTE VON EURODENT
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Seit 1992 stehen wir für eine qualitativ hochwertige und anspruchsvolle Zahnheilkunde.</p>
                    <p i18n>Seit 2004 erwarten wir Sie in unserem anspruchsvollen Gebäude im Zentrum von Mosonmagyaróvár.</p>
                    <p i18n>Im Jahre 2000 haben wir den Status “Ausbildungsstätte für Zahnarztresidenten” erhalten.</p>
                    <p i18n>2011 wurde unser klinikeigenes Zahntechniklabor gegründet. Die enge Zusammenarbeit zwischen
                        unseren
                        Zahnärzten und unserem Zahntechniklabor ermöglicht es uns, Ihre Zahnersätze in höchster
                        Qualität,
                        binnen kürzester Zeit und zu einem günstigen Preis anzufertigen.</p>
                    <p i18n>Zurzeit arbeiten bei uns 16 Fachärzte.</p>
                    <p i18n> Wir haben 30 Jahre Erfahrung. Während dieser Zeit haben wir enorm viel über Wünsche und
                        Erwartungen
                        unserer Patient(inn)en aus ganz Europa gelernt und aufgrund der vielen Behandlungsfälle sind wir
                        in
                        der Lage, für jedes Zahnproblem die ideale Lösung zu finden.</p>
                    <p i18n>Wir möchten die Behandlungen in unserem Zahn- und Implantatzentrum für unsere Patient(inn)en so
                        angenehm wie möglich gestalten und freuen uns auf Patient(inn)en, die sich für einen Zahnersatz
                        in
                        Ungarn in einem angenehmen und gemütlichen Ambiente interessieren.</p>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    MODERNSTE TECHNOLOGIE UND MATERIALIEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Wir verfügen über zwölf professionell ausgestattete Behandlungsräume, ein hauseigenes
                                Zahntechniklabor sowie Röntgen- und CT-Geräte auf dem neusten Stand der
                                zahnmedizinischen
                                Technologie.</p>

                            <p i18n>Bei uns werden ausschließlich geprüfte Materialien von hoher Qualität verwendet, die alle
                                EU-kompatibel sind.</p>

                            <p i18n>Unser Zahntechniklabor arbeitet mit der CAD-CAM-Technologie, 3D-Scannern und 3D-Druckern,
                                die es uns ermöglichen, unseren Patient(inn)en modernste Lösungen anzubieten.</p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/about-us/accordion/UnsereKlinik-accordion1.png">
                        </div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    KOMPETENTES und BESTENS AUSGEBILDETES TEAM
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Entscheidendes Erfolgskriterium für qualitativ hochwertige Zahnmedizin ist nicht nur
                                modernste Technik, sondern auch ein kompetentes, gut ausgebildetes Praxisteam.</p>
                            <p i18n>Unsere deutsch sprechenden Zahnärzte sorgen mit ihrer professionellen und anspruchsvollen
                                Arbeit für die Zufriedenheit unserer Patient(inn)en. Dabei werden sie von erfahrenen
                                zahnärztlichen Assistentinnen unterstützt. Unsere Zahnärzte, Fachzahnärzte,
                                Implantologen,
                                Kieferchirurgen und Assistentinnen nehmen regelmäßig an in- und ausländischen
                                Konferenzen
                                und Weiterbildungen teil, damit sie sich ständig weiterentwickeln und immer einen hohen
                                Leistungsstandard sichern können.</p>
                            <p i18n>Unsere Mitarbeiter(inn)en am Empfang und unsere Kundenberater(inn)en verfügen über
                                hervorragende organisatorische Fähigkeiten und sorgen für den stressfreien Ablauf Ihres
                                Aufenthaltes bei uns. Von Ihrer ersten Kontaktaufnahme bis zum Ende Ihrer Behandlung
                                stehen
                                Ihnen unsere erfahrenen Kolleg(inn)en auf Deutsch, Englisch, Französisch, Portugiesisch,
                                Arabisch und Italienisch zur Verfügung.</p>
                            <ul>
                                <li i18n>Sie können sich mit folgenden Fragen an sie wenden:</li>
                                <li i18n>Kontaktaufnahme</li>
                                <li i18n>Informationen über unsere Dienstleistungen, Preise und Verlauf Ihrer Behandlung</li>
                                <li i18n>Terminvereinbarung</li>
                                <li i18n>Organisation Ihres Aufenthaltes</li>
                                <li i18n>Bezahlung und Rechnung</li>
                                <li i18n>Garantieschein</li>
                            </ul>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/about-us/accordion/UnsereKlinik-accordion2.png">
                            <img class="image" src="assets/images/about-us/accordion/UnsereKlinik-accordion3.png">
                            <img class="image" src="assets/images/about-us/accordion/UnsereKlinik-accordion4.png">
                        </div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    ANGENEHMES AMBIENTE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Unser Zahn- und Implantatzentrum liegt im Zentrum von Mosonmagyaróvár. Wenn Sie freie
                                Zeit zwischen Ihren Behandlungsterminen haben, können Sie einen angenehmen Spaziergang
                                in der Stadt machen. Die Fußgängerzone, die gleich neben dem Zahn- und Implantatzentrum
                                liegt, bietet unseren Patient(inn)en mit ihren kleinen Geschäften, guten Restaurants,
                                Friseur- und Kosmetiksalons vielfältige Möglichkeiten, ihre Freizeit genussvoll zu
                                gestalten.
                            </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/about-us/accordion/UnsereKlinik-accordion5.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/about-us/bottom/1.jpg">
</div>
