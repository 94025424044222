import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {EmployeeWrapper, Role, RoleWrapper} from "../models/employee.model";
import {environment} from "../../../environments/environment";
import {ImageUrlService} from "./image-url.service";

@Injectable({
    providedIn: "root",
})
export class EmployeeService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService,
        private imageUrlService: ImageUrlService
    ) {
    }

    public getRoles(): Observable<Role[]> {
        return this.http.get<EmployeeWrapper[]>(
            environment.apiUrl +
            "employees?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) =>
                value.map((item) => ({
                    id: item.role.id,
                    title: item.role.title,
                    person: item.role.person.map((person) => ({
                        id: person.id,
                        name: person.name,
                        languages: person.languages,
                        position: person.position,
                        picture: {
                            alternativeText: (person.picture == null ? null : person.picture.alternativeText),
                            largeUrl: this.imageUrlService.getImageUrl(person.picture),
                        }
                    }))
                })))
            );
    }
}
