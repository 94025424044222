import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';

@Component({
    selector: 'app-stegprothese',
    templateUrl: './stegprothese.component.html',
    styleUrls: ['./stegprothese.component.scss']
})
export class StegprotheseComponent implements OnInit {

    constructor(
        @Inject(LOCALE_ID) public locale: string,
    ) {
    }

    ngOnInit(): void {
    }

}
