<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>HERAUSNEHMBARE ZAHNSPANGE
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Herausnehmbare-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>UNSICHTBARE ZAHNSPANGE („Invisalign® Technologie“)
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n class="bold">Eine Zahnspange hat mehr Vorteile als nur schöne, gerade Zähne!</p>
                    <p i18n>Eine Zahnspange kann nicht nur Ihr Aussehen, sondern auch Ihre Zahngesundheit verbessern. Für
                        schöne, gerade Zähne lassen sich viele Heranwachsende und Erwachsene, die unter einem
                        kieferorthopädischen Problem leiden, Ihre Zähne behandeln.</p>
                    <p i18n><span class="bold">Invisalign® ist eine herausnehmbare und unsichtbare Zahnspange. </span>Hierbei
                        werden spezielle,
                        herausnehmbare Kunststoffschienen verwendet. Sie sind völlig unsichtbar und können vor dem Essen
                        entfernt werden.</p>
                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    VORTEILE EINER ZAHNSPANGE
                    <img alt="content accordion icon" i18n-alt src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Verbesserung der Mundgesundheit
                        </div>
                        <div i18n class="content--main__content__list__item">Hilfe bei der Verdauung durch regelgerechtes
                            Kauen
                        </div>
                        <div i18n class="content--main__content__list__item">Vorbeugung von Karies
                        </div>
                        <div i18n class="content--main__content__list__item">Verhinderung von Zahnfleischerkrankungen
                        </div>
                        <div i18n class="content--main__content__list__item">Stärkung des Selbstbewusstseins
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    FÜR WEM EMPFEHLEN WIR HERAUSNEHMBARE ZAHNSPANGEN?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Wir empfehlen die herausnehmbare Invisalign Zahnspange für jedes Alter. Diese Regulierungsart
                        kann jedoch nicht in allen Fällen angewendet werden, mit dieser Methode können nur kleinere
                        Fehlstellungen gelöst werden.
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    WAS KOSTET DIE ZAHNREGULIERUNG MIT INVISALIGN-SCHIENEN?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Die Kosten für die Behandlung hängen davon ab, wieviel Schienen benötigt werden.
                    </p>
                </div>
            </div>
            <div class="content__accordion" *ngIf="locale === 'it'">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    PROCESSO DI TRATTAMENTO
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <ul class="list">
                            <li style="list-style-position: inside;">Consultazione per apparecchi ortodontici rimovibili e invisibili nella mascella superiore e inferiore in base all'impronta disponibile, alla radiografia cefalometrica laterale, alla radiografia panoramica e alle foto</li>
                            <li style="list-style-position: inside;">Piano terapeutico utilizzando un programma per computer (piano 3D, "piano sorriso"), decidendo il numero richiesto di tutori</li>
                            <li style="list-style-position: inside;">molto importante: controlli regolari ogni 2-3 mesi</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    BEHANDLUNGSSCHRITTE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Die Zahnbegradigung mit unsichtbaren Zahnspangen besteht aus einer aktiven und einer passiven
                        Phase.
                    </p>
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title"> Aktive Phase</div>
                                <div class="information__text">
                                    <ul class="information__text__list" *ngIf="locale === 'it'">
                                        <p>La durata dei trattamenti varia:</p>
                                        <li>casi più semplici possono essere risolti in pochi mesi</li>
                                        <li>casi medi in 6-9 mesi</li>
                                        <li>I casi complicati richiedono fino a 18 mesi o più</li>
                                    </ul>
                                    <ul class="information__text__list" *ngIf="locale !== 'it'">
                                        <li i18n>Empfehlung einer herausnehmbaren, unsichtbaren Zahnspange im Ober- und
                                            Unterkiefer aufgrund des vorhandenen Abdrucks, des Fernröntgenseitenbilds,
                                            der Panoramaröntgenaufnahme, und Fotos
                                        </li>
                                        <li i18n>Planung mithilfe eines Computerprogramms (3D-Plan, „Lächelplan“),
                                            Entscheidung über die benötigte Anzahl der Schienen
                                        </li>
                                        <li i18n>Sehr wichtig: regelmäßige Kontrollen alle 2-3 Monate</li>
                                        <li style="list-style-type: none;"><ng-template i18n>Der Zeitraum der Behandlungen ist unterschiedlich:</ng-template>
                                            <ul class="information__text__list sublist">
                                                <li i18n>einfachere Fälle können in ein paar Monaten gelöst werden</li>
                                                <li i18n>mittlere Fälle in 6-9 Monaten</li>
                                                <li i18n>für komplizierte Fälle sind bis zu 18 Monate oder mehr nötig</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Passive Phase</div>
                                <div i18n class="information__text">Retention ist notwendig! Vivera® Retainer by AlignTech
                                    muss langfristig getragen werden.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck6">
                <label i18n class="content__accordion__label" for="chck6">
                    WIE OFT MUSS DIE ZAHNSPANGE GETRAGEN WERDEN?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Um das gewünschte Ergebnis zu erreichen, ist das Tragen der unsichtbaren Zahnspange an 22 Stunden
                        am Tag erforderlich. Die Zahnspange soll nur für die Dauer der Mahlzeiten und zum Zähneputzen
                        herausgenommen werden. Nach ca. 7-10 Tagen wechseln Sie zu der nächsten Schiene.
                    </p>
                </div>
            </div>
        </div>

        <p class="bold" i18n><a class="bottom-text-link" target="_blank"
                           href="https://www.invisalign.co.hu/SV/1381522#start">Hier</a> können Sie in
            60 Sekunden Ihr eigenes Lächeln anfertigen (vorher-nachher)</p>

        <img class="image--before-after" src="assets/images/treatments/main/Herausnehmbare-main.png">

    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/3.jpg">
</div>

