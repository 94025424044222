import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {ActualInformation, ActualInformationWrapper} from "../models/actual-information.model";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})

export class ActualInformationService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService
    ) {
    }

    public getActualInformation(): Observable<ActualInformation> {
        return this.http.get<ActualInformationWrapper>(
            environment.apiUrl +
            "actual-info?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) => ({
                title: value.data.attributes.title,
                description: value.data.attributes.description,
                link: value.data.attributes.link,
                display: value.data.attributes.display
            })))
    }
}
