<form class="contact__form" [formGroup]="contactForm">
    <div i18n class="contact__form__title">Kontaktieren Sie Uns</div>
    <div class="contact__form__information">
        <div class="contact__form__information__item phone">
            <div class="contact__form__information__item__details">
                <img class="icon" src="assets/icons/form/pointer.svg">
                <div class="info">
                    <div i18n class="title">Click to call</div>
                    <div class="text">00 36 96 578 250</div>
                </div>
            </div>
            <a i18n class="contact__form__information__item__link" href="tel:003696578250">Anruf</a>
        </div>
        <div class="contact__form__information__item free">
            <div class="contact__form__information__item__details">
                <img class="icon" src="assets/icons/form/phone.svg">
                <div class="info">
                    <div i18n class="title">Nulltarif</div>
                    <div class="text">00 800 1000 7000</div>
                </div>
            </div>
            <a i18n class="contact__form__information__item__link" href="tel:00 800 1000 7000">Anruf</a>
        </div>
        <div class="contact__form__information__item recall">
            <div class="contact__form__information__item__details">
                <img class="icon" src="assets/icons/form/call.svg">
                <div class="info">
                    <div i18n class="title">Gratis Rückruf</div>
                </div>
            </div>
            <a i18n class="contact__form__information__item__link"
               routerLink="/{{routesService.getRouterLink('callback')}}">Weiter</a>
        </div>
    </div>
    <div class="contact__form__fields">
        <div class="contact__form__fields__title">
            <h1 i18n>Kontaktformular</h1>
            <span i18n><span class="required">*</span>obligatorisch</span>
        </div>
        <div class="contact__form__fields__column">
            <div class="name">
                <label class="firstname">
                    <span i18n>Vorname <span class="required">*</span></span>
                    <input type="text" placeholder="{{getPlaceholders('firstname')}}" formControlName="first_name"
                           name="first_name"
                           (blur)="checkValidation($event)"
                           [ngClass]="hasError('first_name','required') ? 'invalid' : ''">
                    <div i18n class="error"
                         *ngIf="hasError('first_name','required')">Bitte geben Sie Ihren Vorname ein.
                    </div>
                </label>
                <label class="lastname">
                    <span i18n>Nachname <span class="required">*</span></span>
                    <input type="text" placeholder="{{getPlaceholders('lastname')}}" formControlName="last_name"
                           name="last_name"
                           (blur)="checkValidation($event)"
                           [ngClass]="hasError('last_name','required') ? 'invalid' : ''">
                    <div i18n class="error"
                         *ngIf="hasError('last_name','required')">Bitte geben Sie Ihren Nachname ein.
                    </div>
                </label>
            </div>
            <label class="email">
                <span i18n>E-Mail <span class="required">*</span></span>
                <input type="email" placeholder="{{getPlaceholders('email')}}" formControlName="email" name="email"
                       (blur)="checkValidation($event)" [ngClass]="hasError('email','required') ? 'invalid' : ''">
                <div i18n class="error"
                     *ngIf="hasError('email','required')">Bitte geben Sie Ihre E-Mail-Adresse ein.
                </div>
            </label>
            <div class="phone">
                <label>
                    <span i18n>Telefonnummer <span class="required">*</span></span>
                </label>
                <div class="phonenumber">
                    <label class="country-code">
                        <span i18n>Landesvorwahl</span>
                        <select formControlName="country" name="number"
                                (blur)="checkValidation($event)" [ngClass]="hasError('number','required') ? 'invalid' : ''">
                            <option [ngValue]="code" *ngFor="let code of countryCodes">{{code}}</option>
                        </select>
                    </label>
                    <label class="area-code">
                        <span i18n>Vorwahl</span>
                        <input formControlName="area" type="text" placeholder="000" pattern="[0-9]+" name="number"
                               (blur)="checkValidation($event)" [ngClass]="hasError('number','required') ? 'invalid' : ''">
                    </label>
                    <label class="number">
                        <span i18n>Individuelle Nummer</span>
                        <input formControlName="number" type="text" placeholder="1234567" pattern="[0-9]+" name="number"
                               (blur)="checkValidation($event)" [ngClass]="hasError('number','required') ? 'invalid' : ''">
                        <div i18n class="error"
                             *ngIf="hasError('number','required')">Bitte geben Sie Ihre Telefonnummer ein.
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="contact__form__fields__column">
            <label class="description">
                <span i18n>Nachricht <span class="required">*</span></span>
                <textarea rows="6" formControlName="comment" name="comment"
                          (blur)="checkValidation($event)"
                          [ngClass]="hasError('comment','required') ? 'invalid' : ''"></textarea>
                <div i18n class="error"
                     *ngIf="hasError('comment','required')">Bitte geben Sie Ihre Frage ein (zB.: Was kostet eine
                    Zahnkrone?).
                </div>
            </label>

            <div class="file-upload">
                    <span i18n class="file-upload__info">
                        Heil- und Kostenplan, Panoramaröntgenaufnahme oder andere relevante Dokumente hochladen (jpg, gif, png, pdf).
                    </span>
                <div *ngFor="let in of [].constructor(uploadedFiles.length + 1); let i = index"
                     class="file-upload__item">
                    <label for="file{{i}}" class="file-upload__choose">
                        <span *ngIf="i === 0" i18n>Datei auswählen</span>
                        <span *ngIf="i > 0" i18n>+ Datei auswählen</span>
                    </label>
                    <input #file{{i}}
                           type="file"
                           name="file{{i}}"
                           id="file{{i}}"
                           formControlName="file{{i}}"
                           accept=".jpg, .gif, .png, .pdf"
                           (change)="uploadFile($event, i);"
                           onclick="this.value = null"
                    />
                    <div class="file-upload__chosen-file">
                        <span i18n class="file-upload__chosen-file__default"
                              *ngIf="uploadedFiles[i] === undefined">Keine Datei ausgewählt</span>
                        <span class="file-upload__chosen-file__name" *ngIf="uploadedFiles[i] !== undefined">
                            {{  uploadedFiles[i].name }}
                            <img (click)="deleteFile(i)" src="assets/icons/delete.svg">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p i18n class="bottom">Mit dem Absenden dieser Nachricht erklären Sie sich damit einverstanden, dass Sie unserer
        Datenschutzerklärung
        gelesen und verstanden haben, sowie wir Ihre Daten nach unserer <a *ngIf="privacy" target="_blank" rel=noopener
                                                                           href="{{privacy.privacy_policy.url}}"
                                                                           class="bold">Datenschutzerklärung.</a>
        zwecks
        Beantwortung Ihrer
        Anfrage weiterverarbeiten.</p>
    <button [disabled]="disabledSubmitButton" class="contact__form__control sm" (click)="onSubmit()">
        <span i18n class="navigation-button__label">weiter</span>
        <div class="navigation-button__arrow">
            <img src="assets/icons/navigation-arrow.svg">
        </div>
        <img *ngIf="disabledSubmitButton" class="loader" src="assets/loader.gif">
    </button>
    <button [disabled]="disabledSubmitButton" class="contact__form__control" (click)="onSubmit()">
        <span i18n class="navigation-button__label">nachricht senden</span>
        <div class="navigation-button__arrow">
            <img src="assets/icons/navigation-arrow.svg">
        </div>
        <img *ngIf="disabledSubmitButton" class="loader" src="assets/loader.gif">
    </button>
    <div class="contact__form__bottom"></div>
</form>
