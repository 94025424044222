<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Zahnbrücke - Ästhetische Zahnmedizin
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Zahnbrucke-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>DIE ZIRKONBRÜCKE WIRD ALS KÖNIGIN DER ZAHNBRÜCKEN BEZEICHNET

                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Die Zahnärzte unserer Zahnklinik empfehlen Ihnen aus ästhetischen Gründen – insbesondere für die
                        Behandlung der sichtbaren Frontzähne - Zirkonbrücken. Zahnbrücken aus Zirkon überzeugen durch
                        ihre Ästhetik und einzigartige Langlebigkeit.</p>


                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 *ngIf="locale === 'fr'">De belles dents saines ont toujours été un symbole de statut social</h1>
            <div class="separator" *ngIf="locale === 'fr'"></div>
            <p i18n>Schöne, gesunde Zähne waren schon immer ein Statussymbol. Viele Menschen leiden unter beschädigten,
                krummen oder verfärbten Zähnen und schämen sich, richtig zu lachen. Unsere Zahnärzte bieten Ihnen mit
                Zahnbrücken hierfür eine perfekte Lösung.
            </p>
            <div class="content--main__information__image-container">
                <div class="image">
                    <img src="assets/images/treatments/main/Zahnbrucke-main1.png">
                    <span i18n class="before">Vorher</span>
                </div>
                <div class="image">
                    <img src="assets/images/treatments/main/Zahnbrucke-main2.png">
                    <span i18n class="after">Nachher</span>
                </div>
            </div>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Wann ist eine Zahnbrücke zu empfehlen?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Wenn ein Zahn oder mehrere Zähne fehlen, müssen die fehlenden Zähne ersetzt werden. Nur so kann
                        man eine vollständige Kaufunktion und eine normale Aussprache sichern.

                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    BRÜCKE AUF EIGENE ZÄHNE - BEHANDLUNGSABLAUF<img alt="content accordion icon" i18n-alt src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Wenn sich neben der Lücke noch eigene Zähne befinden, können auf diese Kronen gesetzt
                                werden. Die Kronen wiederum halten die Brücke über dem fehlenden Zahn, die Lücke wird
                                damit überbrückt.
                            </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/Zahnbrucke-accordion1.png">
                        </div>
                    </div>
                    <p i18n>Sie sollten mit den folgenden Behandlungsschritten rechnen:
                    </p>
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Konsultation, Erstuntersuchung</div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Vorbereitung der Zähne, Abdrucknahme, Einsetzen eines
                                    Provisoriums
                                </div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div i18n class="information__title">Nach einer Woche ist Gerüstprobe</div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">4</div>
                            <div class="information">
                                <div i18n class="information__title">Fertigstellung nach einer weiteren Woche</div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                    </div>
                    <p><span i18n class="bold">Diese Daten dienen nur Ihrer Orientierung!</span><ng-template i18n> Wir planen die
                        Behandlungsschritte für jeden Patienten individuell unter Beachtung der persönlichen Anliegen
                        sowie unter Berücksichtigung des Mundzustandes.</ng-template></p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    IMPLANTATBRÜCKE - BEHANDLUNGSABLAUF
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Bei größeren Zahnlücken oder wenn die eigenen Zähne nicht als Brückenanker genutzt werden
                                können, empfehlen wir als Lösung einen implantatgetragenen Zahnersatz. In diesen Fällen
                                wird die Brücke von Implantaten getragen.
                            </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/Zahnbrucke-accordion2.png">
                        </div>
                    </div>
                    <p i18n>Sie sollten mit den folgenden Behandlungsschritten rechnen:
                    </p>
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Konsultation, Erstuntersuchung</div>
                                <div class="information__text"></div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Sofern erforderlich: Zahnentfernung</div>
                                <div i18n class="information__text">und Einsetzen eines Provisoriums (nach der Zahnentfernung
                                    sind 2-3 Monate Heilungszeit vorgeschrieben)
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div i18n class="information__title">Implantation</div>
                                <div i18n class="information__text">Einsetzen der Implantatschrauben</div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">4</div>
                            <div class="information">
                                <div i18n class="information__title">Abdrucknahme</div>
                                <div i18n class="information__text">nach 3-6 Monaten Einheilphase erfolgt eine Untersuchung
                                    und die Abdrucknahme
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">5</div>
                            <div class="information">
                                <div i18n class="information__title">Gerüstprobe</div>
                                <div i18n class="information__text">nach einer Woche Gerüstprobe</div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">6</div>
                            <div class="information">
                                <div i18n class="information__title">Fertigstellung</div>
                                <div i18n class="information__text">nach einer weiteren Woche</div>
                            </div>
                        </div>
                    </div>
                    <p i18n><span class="bold">Diese Daten dienen nur Ihrer Orientierung!</span> Wir planen die
                        Behandlungsschritte für jeden Patienten individuell unter Beachtung der persönlichen Anliegen
                        sowie unter Berücksichtigung des Mundzustandes.</p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    VORTEILE VON IMPLANTATBRÜCKEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Festsitzender Zahnersatz
                        </div>
                        <div i18n class="content--main__content__list__item">Mit Implantatkronen ist es möglich, mehrere
                            Zähne oder sogar einen vollständig zahnlosen Kiefer mit festsitzendem Zahnersatz zu
                            ersetzen.
                        </div>
                        <div i18n class="content--main__content__list__item">Hohe Ästhetik</div>
                        <div i18n class="content--main__content__list__item">Perfekte Biss- und Kaufunktion</div>
                    </div>

                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    WAS KOSTET EINE ZAHNBRÜCKE?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-price">
                        <div class="text">
                            <p i18n>Bei einer Behandlung mit Brücke(n) sollen Sie mit weiteren Kosten rechnen wie z.B.:
                                Panorama-Röntgenaufnahme(n), temporären Zahnersatz (Provisorium).</p>

                            <p i18n>Die Gesamtkosten einer Brücke werden von mehreren Elementen, z.B. von der Größe und dem
                                Typ der Brücke, beeinflusst. Erst nach einer Erstuntersuchung können wir Ihnen einen
                                individuellen Heil- und Kostenplan erstellen.
                            </p>

                        </div>
                        <div class="price-card">
                            <div i18n class="title">Die Kosten einer Brücke beginnen</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span i18n class="price"><span class="pre">bei</span><span class="bold">570</span> €</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/5.jpg">
</div>

