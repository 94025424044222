import {Component, Input, OnInit} from '@angular/core';
import {RoutesService} from "../../../core/services/routes.service";

@Component({
  selector: 'app-floating-contact',
  templateUrl: './floating-contact.component.html',
  styleUrls: ['./floating-contact.component.scss']
})
export class FloatingContactComponent implements OnInit {

  @Input() isShow = false;

  constructor(
      public routesService: RoutesService
  ) { }

  ngOnInit(): void {
  }

}