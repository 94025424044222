<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1><ng-template i18n>Galerie</ng-template>
                <h2 i18n>Unser Klinik</h2>
            </h1>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>Eurodent Zahnklinik Ungarn Galerie</h1>
            <div class="separator"></div>
        </div>
        <div class="gallery-container" *ngIf="gallery">
            <div *ngFor="let pic of gallery.pictures!.slice(0, 9)" (click)="onGalleryClick($event, gallery, pic.id)">
                <img src="{{pic.standard_picture.url}}">
                <div class="overlay"></div>
            </div>
        </div>
        <div class="gallery-container" *ngIf="gallery && gallery.pictures.length > 9">
            <div *ngFor="let pic of gallery.pictures!.slice(9, 18)" (click)="onGalleryClick($event, gallery, pic.id)">
                <img src="{{pic.standard_picture.url}}">
                <div class="overlay"></div>
            </div>
        </div>
        <div class="gallery-container" *ngIf="gallery && gallery.pictures.length > 18">
            <div *ngFor="let pic of gallery.pictures!.slice(18, 27)" (click)="onGalleryClick($event, gallery, pic.id)">
                <img src="{{pic.standard_picture.url}}">
                <div class="overlay"></div>
            </div>
        </div>
    </div>
</div>
