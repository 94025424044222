import {Inject, Injectable, LOCALE_ID} from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class RoutesService {
    routesModel = new RoutesModel();
    constructor(
        @Inject(LOCALE_ID) public locale: string,
    ) {
    }

    getRouterLink(pageType: string) {
        return this.routesModel.routes.find((x: { pageType: string; lang: string; }) => x.pageType === pageType && x.lang === this.locale)?.url;
    }
}

export class RoutesModel {
    public routes = [
        {lang: "de", pageType: "welcome-package", url: "begrussungspaket"},
        {lang: "en", pageType: "welcome-package", url: "welcome-package"},
        {lang: "it", pageType: "welcome-package", url: "pacchetto-di-benvenuto"},
        {lang: "fr", pageType: "welcome-package", url: "package-dentaire"},
        {lang: "de", pageType: "offer", url: "angebote"},
        {lang: "en", pageType: "offer", url: "offers"},
        {lang: "it", pageType: "offer", url: "offerte"},
        {lang: "fr", pageType: "offer", url: "des-offres"},
        {lang: "de", pageType: "free-pick-up", url: "kostenloser-abholdienst"},
        {lang: "en", pageType: "free-pick-up", url: "free-pick-up-service"},
        {lang: "it", pageType: "free-pick-up", url: "servizio-di-transfer-gratuito"},
        {lang: "fr", pageType: "free-pick-up", url: "transfert-gratuit"},
        {lang: "de", pageType: "free-consultation", url: "kostenlose-erstuntersuchung"},
        {lang: "en", pageType: "free-consultation", url: "free-consultation"},
        {lang: "it", pageType: "free-consultation", url: "prima-visita-gratuita"},
        {lang: "fr", pageType: "free-consultation", url: "examen-personnalisé-gratuit"},
        {lang: "de", pageType: "free-accommodation", url: "/kostenlose-unterkunft"},
        {lang: "en", pageType: "free-accommodation", url: "/free-accommodation"},
        {lang: "it", pageType: "free-accommodation", url: "/alloggio-gratuito"},
        {lang: "fr", pageType: "free-accommodation", url: "/hebergement"},
        {lang: "de", pageType: "weekend-treatment", url: "wochenendbehandlung"},
        {lang: "en", pageType: "weekend-treatment", url: "weekend-treatment"},
        {lang: "it", pageType: "weekend-treatment", url: "trattamento-del-fine-settimana"},
        {lang: "fr", pageType: "weekend-treatment", url: "soins-pendant-le-weekend"},
        {lang: "de", pageType: "dental-implants", url: "zahnimplantate"},
        {lang: "en", pageType: "dental-implants", url: "dental-implants"},
        {lang: "it", pageType: "dental-implants", url: "impianti-dentali"},
        {lang: "fr", pageType: "dental-implants", url: "implants-dentaires"},
        {lang: "de", pageType: "overdenture", url: "stegprothese"},
        {lang: "en", pageType: "overdenture", url: "overdenture"},
        {lang: "it", pageType: "overdenture", url: "protesi-a-barra"},
        {lang: "fr", pageType: "overdenture", url: "prothese-dentaire-semi-amovible"},
        {lang: "de", pageType: "fixed-denture", url: "feste-zahne"},
        {lang: "en", pageType: "fixed-denture", url: "fixed-denture"},
        {lang: "it", pageType: "fixed-denture", url: "denti-fissi"},
        {lang: "fr", pageType: "fixed-denture", url: "dents-fixes"},
        {lang: "de", pageType: "all-on-4", url: "all-on-4-de"},
        {lang: "en", pageType: "all-on-4", url: "all-on-4-en"},
        {lang: "it", pageType: "all-on-4", url: "all-on-4-it"},
        {lang: "fr", pageType: "all-on-4", url: "all-on-4-fr"},
        {lang: "de", pageType: "removable-braces", url: "herausnehmbare-zahnspange"},
        {lang: "en", pageType: "removable-braces", url: "removable-braces"},
        {lang: "it", pageType: "removable-braces", url: "apparecchio-dentale-rimovibile"},
        {lang: "fr", pageType: "removable-braces", url: "appareil-orthodontique-invisible"},
        {lang: "de", pageType: "fixed-braces", url: "feste-zahnspange"},
        {lang: "en", pageType: "fixed-braces", url: "fixed-braces"},
        {lang: "it", pageType: "fixed-braces", url: "apparecchio-dentale-fisso"},
        {lang: "fr", pageType: "fixed-braces", url: "bagues-ortodontie"},
        {lang: "de", pageType: "dental-crown", url: "zahnkrone"},
        {lang: "en", pageType: "dental-crown", url: "dental-crown"},
        {lang: "it", pageType: "dental-crown", url: "corona-dentale"},
        {lang: "fr", pageType: "dental-crown", url: "couronne-dentaire"},
        {lang: "de", pageType: "dental-bridge", url: "zahnbrucke"},
        {lang: "en", pageType: "dental-bridge", url: "dental-bridge"},
        {lang: "it", pageType: "dental-bridge", url: "ponte-dentale"},
        {lang: "fr", pageType: "dental-bridge", url: "bridge-dentaire"},
        {lang: "de", pageType: "full-denture", url: "zahnprothese"},
        {lang: "en", pageType: "full-denture", url: "full-denture"},
        {lang: "it", pageType: "full-denture", url: "dentiera"},
        {lang: "fr", pageType: "full-denture", url: "prothese-dentaire"},
        {lang: "de", pageType: "combined-dentures", url: "kombinierter-zahnersatz"},
        {lang: "en", pageType: "combined-dentures", url: "combined-dentures"},
        {lang: "it", pageType: "combined-dentures", url: "protesi-combinate"},
        {lang: "fr", pageType: "combined-dentures", url: "protheses-combinees"},
        {lang: "de", pageType: "inlay-onlay-filling", url: "inlay-onlay-fullung"},
        {lang: "en", pageType: "inlay-onlay-filling", url: "inlay-onlay-filling"},
        {lang: "it", pageType: "inlay-onlay-filling", url: "inlay-onlay-otturazione"},
        {lang: "fr", pageType: "inlay-onlay-filling", url: "inlay-onlay-obturation"},
        {lang: "de", pageType: "veneer", url: "veneer-verblendschale"},
        {lang: "en", pageType: "veneer", url: "veneer"},
        {lang: "it", pageType: "veneer", url: "facetta-dentale"},
        {lang: "fr", pageType: "veneer", url: "facette"},
        {lang: "de", pageType: "cad-cam-technologies", url: "cad-cam-technologie"},
        {lang: "en", pageType: "cad-cam-technologies", url: "cad-cam-technologies"},
        {lang: "it", pageType: "cad-cam-technologies", url: "tecnologia-cad-cam"},
        {lang: "fr", pageType: "cad-cam-technologies", url: "la-technologie-cad-cam"},
        {lang: "de", pageType: "ems-dental-spa", url: "ems-dental-spa"},
        {lang: "en", pageType: "ems-dental-spa", url: "ems-dental-spa"},
        {lang: "it", pageType: "ems-dental-spa", url: "ems-dentale-spa"},
        {lang: "fr", pageType: "ems-dental-spa", url: "ems-dentaire-spa"},
        {lang: "de", pageType: "oral-hygiene", url: "mundhygiene"},
        {lang: "en", pageType: "oral-hygiene", url: "oral-hygiene"},
        {lang: "it", pageType: "oral-hygiene", url: "igiene-orale"},
        {lang: "fr", pageType: "oral-hygiene", url: "hygiene-buccale"},
        {lang: "de", pageType: "sedation", url: "angebote/sedierung-ab-290"},
        {lang: "en", pageType: "sedation", url: "offers/sedation-from-290"},
        {lang: "it", pageType: "sedation", url: "offerte/sedazione-da-290"},
        {lang: "fr", pageType: "sedation", url: "des-offres/sedation-a-partir-de-290"},
        {lang: "de", pageType: "3D-volume-tomography", url: "3D-volumentomographie"},
        {lang: "en", pageType: "3D-volume-tomography", url: "3D-volume-tomography"},
        {lang: "it", pageType: "3D-volume-tomography", url: "tac-3D"},
        {lang: "fr", pageType: "3D-volume-tomography", url: "tomographie-volumique-numerisee-ct-3D"},
        {lang: "de", pageType: "panoramic-x-ray", url: "panoramarontgenaufnahme"},
        {lang: "en", pageType: "panoramic-x-ray", url: "panoramic-x-ray"},
        {lang: "it", pageType: "panoramic-x-ray", url: "radiografia-panoramica"},
        {lang: "fr", pageType: "panoramic-x-ray", url: "radiographie-panoramique"},
        {lang: "de", pageType: "costs", url: "preisbeispiele"},
        {lang: "en", pageType: "costs", url: "costs-price-examples"},
        {lang: "it", pageType: "costs", url: "esempi-dei-prezzi"},
        {lang: "fr", pageType: "costs", url: "nos-prix"},
        {lang: "de", pageType: "health-insurance", url: "krankenversicherung"},
        {lang: "en", pageType: "health-insurance", url: "health-insurance"},
        {lang: "it", pageType: "health-insurance", url: "assicurazione-sanitaria"},
        {lang: "fr", pageType: "health-insurance", url: "remboursement-des-soins-dentaires"},
        {lang: "de", pageType: "warranty", url: "garantie"},
        {lang: "en", pageType: "warranty", url: "warranty"},
        {lang: "it", pageType: "warranty", url: "garanzia"},
        {lang: "fr", pageType: "warranty", url: "conditions-de-garantie"},
        {lang: "de", pageType: "your-benefits", url: "ihre-vorteile"},
        {lang: "en", pageType: "your-benefits", url: "your-benefits"},
        {lang: "it", pageType: "your-benefits", url: "vantaggi"},
        {lang: "fr", pageType: "your-benefits", url: "vos-avantages-avec-nous"},
        {lang: "de", pageType: "our-clinic", url: "unsere-klinik"},
        {lang: "en", pageType: "our-clinic", url: "our-clinic"},
        {lang: "it", pageType: "our-clinic", url: "la-nostra-clinica"},
        {lang: "fr", pageType: "our-clinic", url: "notre-clinique"},
        {lang: "de", pageType: "our-team", url: "unser-team"},
        {lang: "en", pageType: "our-team", url: "our-team"},
        {lang: "it", pageType: "our-team", url: "la-nostra-personale"},
        {lang: "fr", pageType: "our-team", url: "notre-equipe"},
        {lang: "de", pageType: "gallery", url: "galerie"},
        {lang: "en", pageType: "gallery", url: "gallery"},
        {lang: "it", pageType: "gallery", url: "galleria"},
        {lang: "fr", pageType: "gallery", url: "galerie"},
        {lang: "de", pageType: "apartments", url: "galerie/city-appartements"},
        {lang: "en", pageType: "apartments", url: "gallery/city-apartments"},
        {lang: "it", pageType: "apartments", url: "galleria/Appartamenti_city"},
        {lang: "fr", pageType: "apartments", url: "galerie/city-appartements-1"},
        {lang: "de", pageType: "guesthouse", url: "galerie/pension-jasmin"},
        {lang: "en", pageType: "guesthouse", url: "gallery/pension-jasmin-en"},
        {lang: "it", pageType: "guesthouse", url: "galleria/pension-jasmin-it"},
        {lang: "fr", pageType: "guesthouse", url: "galerie/pension-jasmin-fr"},
        {lang: "de", pageType: "faq", url: "haufig-gestellte-fragen"},
        {lang: "en", pageType: "faq", url: "frequently-asked-questions"},
        {lang: "it", pageType: "faq", url: "domande-frequenti"},
        {lang: "fr", pageType: "faq", url: "questions-frequentes"},
        {lang: "de", pageType: "eurodent-dental-laboratory", url: "eurodent-zahntechniklabor"},
        {lang: "en", pageType: "eurodent-dental-laboratory", url: "eurodent-dental-laboratory"},
        {lang: "it", pageType: "eurodent-dental-laboratory", url: "laboratorio-odontotecnico-eurodent"},
        {lang: "fr", pageType: "eurodent-dental-laboratory", url: "laboratoire-dentaire-eurodent"},
        {lang: "de", pageType: "blog", url: "blog"},
        {lang: "en", pageType: "blog", url: "blog"},
        {lang: "it", pageType: "blog", url: "blog"},
        {lang: "fr", pageType: "blog", url: "blog"},
        {lang: "de", pageType: "list-of-treatments", url: "liste-der-behandlungen"},
        {lang: "en", pageType: "list-of-treatments", url: "list-of-treatments"},
        {lang: "it", pageType: "list-of-treatments", url: "elenco-dei-trattamenti"},
        {lang: "fr", pageType: "list-of-treatments", url: "liste-des-traitements"},
        {lang: "de", pageType: "contact", url: "/kontakt"},
        {lang: "en", pageType: "contact", url: "/contact"},
        {lang: "it", pageType: "contact", url: "/contatto"},
        {lang: "fr", pageType: "contact", url: "/contact"},
        {lang: "de", pageType: "contact-thankyou", url: "kontakt/danke"},
        {lang: "en", pageType: "contact-thankyou", url: "contact/thankyou"},
        {lang: "it", pageType: "contact-thankyou", url: "contatto/grazie"},
        {lang: "fr", pageType: "contact-thankyou", url: "contact/merci"},
        {lang: "de", pageType: "travel-information", url: "anreise"},
        {lang: "en", pageType: "travel-information", url: "travel-information"},
        {lang: "it", pageType: "travel-information", url: "come-arrivare-da-noi"},
        {lang: "fr", pageType: "travel-information", url: "comment-venir"},
        {lang: "de", pageType: "corona-virus-and-current-travel-infos", url: "coronavirus-und-reiseinfos"},
        {lang: "en", pageType: "corona-virus-and-current-travel-infos", url: "corona-virus-and-current-travel-infos"},
        {lang: "it", pageType: "corona-virus-and-current-travel-infos", url: "corona-virus-e-informazioni-di-viaggio"},
        {lang: "fr", pageType: "corona-virus-and-current-travel-infos", url: "coronavirus-et-informations-sur-les-voyages"},
        {lang: "de", pageType: "callback", url: "gratis-ruckruf"},
        {lang: "en", pageType: "callback", url: "free-call-back"},
        {lang: "it", pageType: "callback", url: "richiamo-gratuito"},
        {lang: "fr", pageType: "callback", url: "rappel-gratuit"},
        {lang: "de", pageType: "callback-thankyou", url: "gratis-ruckruf/danke"},
        {lang: "en", pageType: "callback-thankyou", url: "free-call-back/thankyou"},
        {lang: "it", pageType: "callback-thankyou", url: "richiamo-gratuito/grazie"},
        {lang: "fr", pageType: "callback-thankyou", url: "rappel-gratuit/merci"},
    ];
}

