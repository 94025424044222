import { Component, OnInit } from '@angular/core';
import {RoutesService} from "../../../../core/services/routes.service";

@Component({
  selector: 'app-weekend-treatments',
  templateUrl: './weekend-treatments.component.html',
  styleUrls: ['./weekend-treatments.component.scss']
})
export class WeekendTreatmentsComponent implements OnInit {

  constructor(
      public routesService: RoutesService
  ) { }

  ngOnInit(): void {
  }

}
