<div class="content__accordion no-margin" *ngIf="accordion">
    <input type="checkbox" id="chck-{{accordion.id}}">
    <label class="content__accordion__label" for="chck-{{accordion.id}}">
        {{ accordion.title }}
        <img src="assets/icons/accordion.svg">
    </label>
    <div class="content__accordion__content">
        <div [innerHTML]="richtextService.trustHTML(changeListStyle(accordion.text))"></div>
        <a *ngIf="accordion.button && accordion.button.length > 0" class="navigation-button card__content__navigation"
           routerLink="{{accordion.button}}">
            <span i18n class="navigation-button__label">weiterlesen</span>
            <div class="navigation-button__arrow">
                <img src="assets/icons/navigation-arrow.svg">
            </div>
        </a>
    </div>
</div>
