<form class="contact__form" [formGroup]="callbackForm">
    <h2 i18n class="contact__form__title">Gratis Rückruf</h2>
    <div class="contact__form__information">
        <p i18n>Hier können Sie um einen gratis Rückruf bitten.</p>
        <p i18n>Tippen Sie bitte Ihren Name und Ihre Telefonnummer ein. Unsere Kollegen werden Sie bald zurückrufen.</p>
    </div>
    <div class="contact__form__fields">
        <div class="contact__form__fields__title"></div>
        <div class="contact__form__fields__column">
            <label class="name">
                <span i18n>Name <span class="required">*</span></span>
                <input type="text" placeholder="{{getPlaceholders('name')}}" formControlName="full_name" name="full_name"
                       (blur)="checkValidation($event)" [ngClass]="hasError('full_name','required') ? 'invalid' : ''">
                <div i18n class="error"
                     *ngIf="hasError('full_name','required')">Bitte geben Sie Ihren Vorname ein.
                </div>
            </label>
        </div>
        <div class="contact__form__fields__column">
            <label i18n class="phonenumber">
                <span>Telefonnummer<span class="required"> *</span></span>
            </label>
            <div class="phonenumber">
                <div class="input-container country-code">
                    <span i18n class="label">Landesvorwahl</span>
                    <select class="country-code" formControlName="country" name="country"
                            [ngClass]="hasError('country','required') ? 'invalid' : ''">
                        <option [ngValue]="code" *ngFor="let code of countryCodes"
                                (blur)="checkValidation($event)">{{code}}</option>
                    </select>
                    <div class="error"
                         *ngIf="hasError('country','required')">Error required
                    </div>
                </div>
                <div class="input-container area-code">
                    <span i18n class="label">Vorwahl</span>
                    <input type="text" pattern="[0-9]+" placeholder="000" formControlName="area" name="area"
                           (blur)="checkValidation($event)"
                           [ngClass]="hasError('area','required') ? 'invalid' : ''">
                    <div class="error"
                         *ngIf="hasError('area','required')">Error required
                    </div>
                </div>
                <div class="input-container number">
                    <span i18n class="label">Individuelle Nummer</span>
                    <input type="text" pattern="[0-9]+" placeholder="1234567" formControlName="number" name="number"
                           (blur)="checkValidation($event)" [ngClass]="hasError('number','required') ? 'invalid' : ''">
                    <div class="error"
                         *ngIf="hasError('number','required')">Error required
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p i18n class="bottom">Mit dem Absenden dieser Nachricht erklären Sie sich damit einverstanden, dass Sie unserer
        Datenschutzerklärung
        gelesen und verstanden haben, sowie wir Ihre Daten nach unserer <a *ngIf="privacy" target="_blank" rel=noopener
                                                                           href="{{privacy.privacy_policy.url}}"
                                                                           class="bold">Datenschutzerklärung.</a>
        zwecks
        Beantwortung Ihrer
        Anfrage weiterverarbeiten.</p>

    <div class="contact__form__control" (click)="onSubmit()">
        <span i18n class="navigation-button__label">abschicken</span>
        <div class="navigation-button__arrow">
            <img src="assets/icons/navigation-arrow.svg">
        </div>
    </div>
    <div class="contact__form__bottom"></div>
</form>
