<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n class="mobile">KOSTEN
            </h1>
            <h1 i18n class="desktop">KOSTEN UND PREISBEISPIELE
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/prices/top/Kosten-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Wir erstellen unseren Patient(inn)en einen individuellen Heil- und Kostenplan aufgrund eines
                        aktuellen (d.h. nicht mehr als 3 Monate alten) Röntgenbildes oder nach einer
                        Erstuntersuchung.</p>
                    <p i18n>Die von uns erstellten Dokumente, Kostenvoranschläge und Rechnungen sind in deutscher Sprache
                        und werden in den europäischen Ländern akzeptiert. Für unsere Patient(inn)en aus Deutschland
                        erstellen wir für ihre Krankenkasse Heil- und Kostenpläne.
                    </p>
                    <p i18n>Die Preise einer Zahnbehandlung sind bei jedem Patienten unterschiedlich, da es immer spezielle
                        zahnärztliche Gegebenheiten und individuelle Erfordernisse gibt. In unserer Klinik tun wir alles, um die
                        perfekte Lösung für Ihre Zahnprobleme zu finden.</p>
                    <p i18n>Hier finden Sie Preisbeispiele für die Behandlungen, die am häufigsten vorkommen.</p>
                    <app-top-navigation></app-top-navigation>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>PREISBEISPIELE</h1>

            <p i18n>Von diesen Preisen ist der Krankenkassenzuschuss noch nicht abgezogen, aber sie beinhalten das
                zahnärztliche
                Honorar, die Material- und die Laborkosten, den Transfer und die Übernachtung in unserer hauseigenen
                Unterkunft. Die von uns ausgestellten Rechnungen können Sie auch beim Finanzamt einreichen. Sämtliche
                Materialien und Geräte entsprechen dem EU-Standard, aber die Preise sind bei uns bis zu 40% - 60%
                günstiger.</p>
        </div>

        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    IMPLANTATE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-price">
                        <div class="text">
                            <p i18n>Bei einer Behandlung mit Implantat(en) sollen Sie mit weiteren Kosten rechnen wie
                                z.B.: Panorama-Röntgenaufnahme(n), Aufbauteil(e), Krone(n), Operationsgebühr und
                                Medikamente.</p>

                            <p i18n>Die Gesamtkosten der Behandlung werden von mehreren Elementen, z.B. Zahl der Implantate,
                                Typ der Implantate und Kronen, beeinflusst. Erst nach einer Erstuntersuchung können wir
                                Ihnen einen individuellen Heil- und Kostenplan erstellen.</p>

                        </div>
                        <div class="price-card">
                            <div class="title" i18n>Ein Implantat kostet</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span class="price"><span class="bold">450</span> €</span>
                                <span class="price">
                                    <span i18n *ngIf="locale !== 'fr'"class="pre">bis</span>
                                    <span *ngIf="locale === 'fr'"class="pre">à</span>
                                    <span class="bold">640</span> €</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label class="content__accordion__label" for="chck2" i18n>
                    KRONE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-price">
                        <div class="text">
                            <p i18n>Bei einer Behandlung mit Krone(n) sollen Sie mit weiteren Kosten rechnen wie z.B.:
                                Panorama-Röntgenaufnahme(n), temporäre Krone(n) (Provisorium).</p>

                            <p i18n>Die Gesamtkosten beim Einbringen einer Krone werden von mehreren Elementen, z.B. von der
                                Zahl der betroffenen Zähne und vom Typ der Krone, beeinflusst. Erst nach einer
                                Erstuntersuchung können wir Ihnen einen individuellen Heil- und Kostenplan
                                erstellen.</p>
                        </div>
                        <div class="price-card">
                            <div i18n class="title">Eine Zahnkrone kostet</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span class="price"><span class="bold">200</span> €</span>
                                <span class="price"><span class="pre" i18n>bis</span><span class="bold">350</span> €</span> </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    BRÜCKEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-price">
                        <div class="text">
                            <p i18n>Bei einer Behandlung mit Brücke(n) sollen Sie mit weiteren Kosten rechnen wie z.B.:
                                Panorama-Röntgenaufnahme(n), temporären Zahnersatz (Provisorium).</p>

                            <p i18n>Die Gesamtkosten einer Brücke werden von mehreren Elementen, z.B. von der Größe und dem
                                Typ
                                der Brücke, beeinflusst. Erst nach einer Erstuntersuchung können wir Ihnen einen
                                individuellen Heil- und Kostenplan erstellen.</p>
                        </div>
                        <div class="price-card">
                            <div i18n class="title">Die Kosten einer Brücke beginnen</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span i18n class="price"><span class="pre">bei</span><span class="bold">570</span> €</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    STEGPROTHESE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-price">
                        <div class="text">
                            <p i18n>Beim Einbringen einer Stegprothese beinhalten die Kosten u.a. die
                                Panoramaröntgenaufnahme(n), das Implantat selber mit Aufbau, das Provisorium, die
                                Operationsgebühr, Medikamente, Prothese, den Steg, sowie die Metallplatte und
                                Halteelemente.</p>

                            <p i18n>Die Gesamtkosten der Behandlung werden von mehreren Elementen, z.B. vom Typ und dem
                                Material
                                der Bestandteile, beeinflusst. Erst nach einer Erstuntersuchung können wir Ihnen einen
                                individuellen Heil- und Kostenplan erstellen.</p>

                        </div>
                        <div class="price-card">
                            <div i18n class="title">Die Kosten einer implantatgetragenen Prothese auf Stegkonstruktion belaufen sich auf mindestens</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span class="price"><span class="bold">2940</span> €</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    MUNDHYGIENE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__price-double">
                        <div class="price-card">
                            <div i18n class="title">Eine Zahnsteinentfernung pro Kiefer kostet</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span class="price"><span class="bold">30</span> €</span>
                            </div>
                        </div>
                        <div class="price-card">
                            <div i18n class="title">EMS Dental Spa Behandlung pro Kiefer kostet</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span class="price"><span class="bold">50</span> €</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>
