import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dental-laboratory',
  templateUrl: './dental-laboratory.component.html',
  styleUrls: ['./dental-laboratory.component.scss']
})
export class DentalLaboratoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
