import { Component, OnInit } from '@angular/core';
import {Blog} from "../../../core/models/blog.model";
import {BlogService} from "../../../core/services/blog.service";

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {

  blogs: Blog[] = [];

  constructor(
      private blogService: BlogService
  ) { }

  ngOnInit(): void {
    this.blogService.getBlogs().subscribe( (value) => {
      this.blogs = value;
    })
  }

}
