<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>BLOG - Nützliche Informationen und Neuigkeiten</h1>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="card-container">
            <app-blog-card *ngFor="let blog of blogs" [object]="blog"></app-blog-card>
        </div>
    </div>
</div>
