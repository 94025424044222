<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Mundhygiene</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Mundhygenie-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Zahnsteinentfernung, Zahnreinigung</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Die grundlegende Voraussetzung für die Gesundheit und Festigkeit der Zähne ist eine gründliche
                        und
                        effektive Mundpflege. Ziel ist es, die sich an der Zahnoberfläche ständig bildenden Beläge zu
                        entfernen.</p>

                    <p i18n>Die in diesen Belägen lebenden Bakterien produzieren Säuren, die dann Karies verursachen. Auch in
                        einem kariesfreien Mund können die Bakterien zum Lockerwerden der Zähne und zur Parodontitis
                        führen.</p>

                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>Um die Zähne und das Zahnfleisch langfristig gesund zu erhalten,
            </h1>
            <p i18n>sollte man regelmäßig eine professionelle Mundhygiene in einer Zahnarztpraxis durchführen lassen. Die
                plombierten bzw. mit Kronen versehenen Zähne benötigen ebenfalls eine regelmäßige Zahnpflege und
                zahnärztliche Kontrolle.</p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label class="content__accordion__label" for="chck1" i18n>
                    KONTROLLE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Jährlich zweimal sollten die Zähne vom Zahnarzt kontrolliert werden. Die Prophylaxe, zum
                                Beispiel eine Kariesverhütung oder eine Zahnreinigung bei einem Zahnarzt, ist für den
                                Erhalt der Zähne unerlässlich.
                                Zur Kariesfrüherkennung sollte alle zwei Jahre ein Röntgenbild erstellt werden.
                            </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/Mundhygenie-accordion1.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    ZAHNSTEINENTFERNUNG, PROFESSIONELLE MUNDHYGIENE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Zahnstein ist die Einlagerung von Mineralien, die durch Plaque und Speichel hervorgerufen
                                wird. Es ist lediglich verkalkter Zahnbelag.</p>

                            <p i18n>Je härter und damit älter der Zahnstein ist, umso mehr Zeit nimmt die Entfernung in
                                Anspruch. Die professionelle Zahnreinigung kann nur von Dentalhygienikern oder
                                Zahnärzten durchgeführt werden.</p>

                            <p i18n>Zahnstein kann mit Zahnbürste oder Zahnpaste nicht entfernt werden. In unserer Klinik
                                wird die Zahnsteinentfernung mithilfe eines Ultraschalldepurators schmerzfrei
                                durchgeführt.</p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/Mundhygenie-accordion2.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    AUSFÜHRUNG EINER PROFESSIONELLEN MUNDHYGIENE
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Eine Zahnreinigung dauert etwa eine halbe Stunde. Als erster Schritt werden die Mundhöhle und das
                        Gebiss vom Zahnarzt untersucht. Danach wird der Zahnstein von der Zahnoberfläche gründlich
                        entfernt.
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    WAS KOSTET EINE PROFESSIONELLE MUNDHYGIENE?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__price-double">
                        <div class="price-card">
                            <div i18n class="title">Eine Zahnsteinentfernung pro Kiefer kostet</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span class="price"><span class="bold">30</span> €</span>
                            </div>
                        </div>
                        <div class="price-card">
                            <div i18n class="title">EMS Dental Spa Behandlung pro Kiefer kostet</div>
                            <div class="title-chevron">
                                <div class="left"></div>
                                <div class="right"></div>
                            </div>
                            <div class="price-tag">
                                <span class="price"><span class="bold">50</span> €</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/7.jpg">
</div>

