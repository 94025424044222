<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Wochenendebehandlungen</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/company-services/top/Wochenendbehandlung-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Haben Sie keine Zeit an Wochentagen?
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Sie können Ihre Behandlungstermine auch für das Wochenende vereinbaren, da <span class="bold">unsere Klinik am Samstag von 9 bis 13 Uhr geöffnet ist.</span></p>
                    <p i18n>Für eine Terminvereinbarung kontaktieren Sie uns bitte!</p>
                    <p i18n>Gehören auch Sie zu unseren zufriedenen Patienten und kehren Sie mit perfekten, wunderschönen
                        Zähnen nach Hause zurück!</p>
                    <app-top-navigation></app-top-navigation>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <p i18n class="bold">Für weitere Informationen, eine Terminreservierung oder individuelle Beratung kontaktieren Sie uns bitte!
        </p>

        <p i18n>Gehören auch Sie zu unseren zufriedenen Patienten und kehren Sie mit perfekten, wunderschönen Zähnen nach Hause zurück!
        </p>

        <a class="navigation-button" routerLink="{{routesService.getRouterLink('contact')}}">
            <span i18n class="navigation-button__label">Kontakt</span>
            <div class="navigation-button__arrow">
                <img src="assets/icons/navigation-arrow.svg">
            </div>
        </a>
    </div>
</div>

<div class="content--bottom">
</div>

