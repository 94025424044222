import {DomSanitizer} from "@angular/platform-browser";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class RichTextService {
    constructor(
        private sanitizer: DomSanitizer
    ) { }

    trustHTML(text: string | undefined): string {
        // @ts-ignore
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }
}
