import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {GalleryService} from "../../../core/services/gallery.service";
import {Gallery} from "../../../core/models/gallery.model";
import {NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";
import {DialogService} from "../../../core/services/dialog.service";
import {GalleryDialogComponent} from "../../../shared/components/gallery-dialog/gallery-dialog.component";
import {MetatagService} from "../../../core/services/metatag.service";

@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {

    gallery: Gallery | undefined;

    constructor(
        private route: ActivatedRoute,
        private galleryService: GalleryService,
        private dialogService: DialogService,
        private metaTagService: MetatagService,
        config: NgbCarouselConfig) {
        config.interval = 0;
    }

    ngOnInit(): void {

        this.route.paramMap.subscribe((paramMap) => {
            const slug = paramMap.get("slug");
            this.galleryService.getGallery(slug!).subscribe((value) => {
                this.gallery = value;
                this.metaTagService.updateMetaTags(this.gallery.seo);
            })
        })
    }

    //
    onGalleryClick(ev: any, gallery: Gallery, pictureId: number) {
        this.dialogService.openModalWithParams(GalleryDialogComponent, {gallery, pictureId});
    }
}
