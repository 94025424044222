import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Offer} from "../../../core/models/offer.model";
import {OfferService} from "../../../core/services/offer.service";
import {ActivatedRoute} from "@angular/router";
import {RichTextService} from "../../../core/services/rich-text.service";
import {Meta, Title} from "@angular/platform-browser";
import {MetatagService} from "../../../core/services/metatag.service";

@Component({
  selector: 'app-offer-detailed',
  templateUrl: './offer-detailed.component.html',
  styleUrls: ['./offer-detailed.component.scss']
})
export class OfferDetailedComponent implements OnInit {
  offer: Offer | undefined;

  constructor(
      private route: ActivatedRoute,
      private offerService: OfferService,
      public richtextService: RichTextService,
      private metaTagService: MetatagService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      const slug = paramMap.get("slug");
      this.offerService.getOffer(slug!).subscribe((value) => {
        this.offer = value;
        //  set metatags for seo
        this.metaTagService.updateMetaTags(this.offer.seo);
      })
    });

  }

  changeListStyle(text: any): string {
    return text.replaceAll('<ul style="list-style-type:circle;">', '<ul class="circle">')
        .replaceAll('<ol style="list-style-type:decimal;">', '<ol class="decimal">')
        .replaceAll('<ol style="list-style-type:upper-roman;">', '<ol class="upper-roman">')
        .replaceAll('<ul style="list-style-type:disc;">', '<ul class="disc">')
        .replaceAll('<li>', '<li><span>')
        .replaceAll('</li>', '</span></li>');
  }

}
