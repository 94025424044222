import {Image, ImageWrapper} from "./image.model";
import {BlogText} from "./blogtext.model";
import {Seo} from "./seo.model";

export interface Blog {
    id: number;
    title: string;
    slug: string;
    subtitle: string;
    lead: string;
    main_picture: Image;
    mood_picture: Image;
    blog_text: BlogText[];
    seo: Seo;
}

export interface BlogDataWrapper {
    data: BlogWrapper[];
}

export interface BlogWrapper {
    id: number;
    attributes: {
        title: string,
        slug: string,
        subtitle: string;
        lead: string;
        main_picture: ImageWrapper;
        mood_picture: ImageWrapper;
        blog_text: BlogText[];
        seo: Seo;
    }
}

export interface BlogSingletonWrapper {
    data: BlogWrapper;
}
