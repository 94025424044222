import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Faq, FaqWrapper} from "../models/faq.model";
import {ImageUrlService} from "./image-url.service";

@Injectable({
    providedIn: "root",
})
export class FaqService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService,
        private imageUrlService: ImageUrlService
    ) {
    }

    public getQuestions(): Observable<Faq> {
        return this.http.get<FaqWrapper[]>(
            environment.apiUrl +
            "gyik?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) => {
                return value[0];
        }))
            .pipe(map((item) => ({
                    id: item.id,
                    title: item.title,
                    gyik: item.gyik.map((question) => ({
                        id: question.id,
                        title: question.title,
                        text: question.text
                    })),
                    mood_picture: {
                        alternativeText: item.mood_picture.alternativeText,
                        largeUrl: this.imageUrlService.getImageUrl(item.mood_picture)
                    },
                }))
            );
    }

}
