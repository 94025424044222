import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';

@Component({
  selector: 'app-price-examples',
  templateUrl: './price-examples.component.html',
  styleUrls: ['./price-examples.component.scss']
})
export class PriceExamplesComponent implements OnInit {

  constructor(
    @Inject(LOCALE_ID) public locale: string,
  ) { }

  ngOnInit(): void {
  }

}
