import {Component, OnInit} from '@angular/core';
import {EmployeeService} from "../../../core/services/employee.service";
import {Role} from "../../../core/models/employee.model";

@Component({
    selector: 'app-our-team',
    templateUrl: './our-team.component.html',
    styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {

    employeeRoles: Role[] = [];

    constructor(
        private employeeService: EmployeeService,
    ) {
    }

    ngOnInit(): void {
        this.employeeService.getRoles().subscribe((value) => {
            this.employeeRoles = value;
        })
    }

}
