import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {Gallery, GalleryWrapper, Picture} from "../models/gallery.model";
import {environment} from "../../../environments/environment";
import {ImageUrlService} from "./image-url.service";

@Injectable({
    providedIn: "root",
})
export class GalleryService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService,
        private imageUrlService: ImageUrlService,
    ) {
    }

    public getGalleries(limit?: number): Observable<Gallery[]> {
        let url = environment.apiUrl + "galeries?locale=" + this.lang.getLang();
        if (limit) {
            url = url + "&limit=" + limit;
        }
        return this.http.get<GalleryWrapper[]>(
            url
        )
            .pipe(map((value) =>
                value.map((item) => this.transformPictures(item)
                ))
            );
    }

    public getGallery(slug: string): Observable<Gallery> {
        return this.http.get<GalleryWrapper>(
            environment.apiUrl +
            "galeries/" + slug + "?locale=" +
            this.lang.getLang()
        )

            .pipe(map((value) => this.transformPictures(value)))
    }

    public transformPictures(item: GalleryWrapper) {
        let pictures: Picture[] = [];

        for (let i = 1; i <= environment.galleryLength; i++) {
            let attribute = 'pic' + i;
            // @ts-ignore
            const picture = item[attribute];
            if (picture != null && picture.standard_picture != null && picture.zoomed_picture != null) {
                picture.standard_picture.url = environment.backendBaseUrl + picture.standard_picture.url;
                picture.zoomed_picture.url = environment.backendBaseUrl + picture.zoomed_picture.url;
                pictures.push(picture);
            }
        }

        return {
            id: item.id,
            galery_name: item.galery_name,
            slug: item.slug,
            seo: item.seo,
            cover_picture: {
                id: item.cover_picture.id,
                alternativeText: item.cover_picture.alternativeText,
                url: this.imageUrlService.getImageUrl(item.cover_picture),
            },
            pictures: pictures,
        }
    }
}
