<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Anreise
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/contact/top/Anreise-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Reisetipps, Kostenloses Zahntaxi
                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <ul>
                        <ng-template i18n>Die Eurodent Zahnklinik befindet sich in Mosonmagyaróvár in Westungarn.
                        </ng-template>
                        <li i18n>etwa 50 Autominuten von Wien</li>
                        <li i18n>10 km vom österreichisch–ungarischen Grenzübergang Nickelsdorf – Hegyeshalom</li>
                        <li i18n>16 km vom slowakisch–ungarischen Grenzübergang Rajka – Cunovo</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h5 i18n>Wir helfen Ihnen, egal ob Sie mit dem Auto, dem Zug oder dem Flugzeug zu uns reisen!</h5>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    KOSTENLOSER ABHOLDIENST
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Wir bieten unseren Patient(inn)en aus Wien zweimal täglich einen kostenlosen
                                Abholdienst
                                von Montag bis Freitag an!</p>

                            <p i18n>Reservieren Sie einen Termin für Ihre Untersuchung und wählen Sie unseren
                                kostenlosen
                                Abholdienst!</p>

                            <p i18n>Unsere Kundenbetreuer stehen Ihnen per E-Mail oder Telefon zu unseren
                                Öffnungszeiten
                                sehr
                                gern zur Verfügung! Zögern Sie nicht, kontaktieren Sie uns!</p>

                            <p i18n>Der Treffpunkt des Abholdienstes befindet sich in Wien-Mitte. Wo finden Sie
                                ihn?</p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/contact/accordion/Anreise-accordion1.png">
                        </div>
                    </div>
                    <a i18n
                       href="https://www.google.com/maps/dir/48.2063218,16.3837705/@48.2062324,16.3838818,19z/data=!4m2!4m1!3e0">Für
                        Ihre Routenplanung zu Wien-Mitte klicken Sie hier!<img
                                src="assets/icons/navigation-arrow-yellow.svg"></a>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    ANFAHRT MIT DEM AUTO
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>
                                Wenn Sie mit dem Pkw anreisen, steht Ihnen unser klinikeigener,
                                bewachter Parkplatz
                                kostenlos zur Verfügung!
                            </p>
                            <p>
                                <span i18n class="bold">GPS-Koordinaten:</span><br>
                                <span i18n class="bold">Nord: 47°52′ 31”(47.875330)</span><br>
                                <span i18n class="bold">Ost: 17°16′ 9” (17.269076)</span>
                            </p>
                            <a i18n
                               href="https://www.google.com/maps/dir/Zahnklinik+Ungarn+%7C+Euro-Dent+Ltd.+Aquadental.,+Mosonmagyar%C3%B3v%C3%A1r,+Gy%C5%91rikapu+u.+7,+9200/''/@47.8753896,17.2690557,19z/data=!4m13!4m12!1m5!1m1!1s0x476c77e141801cc7:0xaf74a29be709de4c!2m2!1d17.2690197!2d47.8755565!1m5!1m1!1s0x476c77e141801cc7:0xaf74a29be709de4c!2m2!1d17.2690197!2d47.8755565?hl=de">Für
                                den Routenplaner klicken Sie hier!<img
                                        src="assets/icons/navigation-arrow-yellow.svg"></a>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/contact/accordion/Anreise-accordion2.png">
                        </div>
                    </div>
                    <div class="content--main__content__list">
                        <p i18n class="bold">Unsere Tipps!</p>
                        <div class="content--main__content__list__item" i18n>Wenn Sie aus Wien über den
                            Grenzübergang
                            Nickelsdorf/ Hegyeshalom fahren, empfehlen wir, die Autobahn bei Nickelsdorf bei der
                            letzten
                            Ausfahrtmöglichkeit in Österreich zu verlassen, denn dann brauchen Sie keine
                            Vignette für
                            Ungarn zu kaufen.
                        </div>
                        <div i18n class="content--main__content__list__item">Am Grenzübergang zwischen Österreich
                            und
                            Ungarn
                            gibt es manchmal größere Staus. In diesem Fall empfehlen wir Ihnen, über die Slowakei zu
                            uns
                            zu fahren.
                        </div>
                        <div i18n class="content--main__content__list__item">Vor Ihrer Fahrt informieren Sie sich
                            unbedingt
                            über die aktuelle Verkehrslage.
                        </div>
                    </div>
                    <a *ngIf="locale === 'de'" i18n routerLink="/assets/wegbeschreibung.pdf" target="_blank">Wegbeschreibung aus Wien<img alt="yellow navigation arrow" i18n-alt
                                                         src="assets/icons/navigation-arrow-yellow.svg"></a>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    ANREISE MIT DEM FLUGZEUG
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Wenn Sie uns Ihre Flugdaten (Datum, Uhrzeit und Flugnummer) mitteilen, holen wir
                                Sie
                                gern
                                vom
                                Flughafen Wien-Schwechat oder vom Flughafen Bratislava mit unserem kostenlosen
                                Abholdienst
                                ab.</p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/contact/accordion/Anreise-accordion3.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    ANREISE PER BAHN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Wenn Sie sich für eine Anreise mit dem Zug entscheiden, holen wir Sie gern mit
                                unserem
                                kostenlosen Abholdienst vom Bahnhof Mosonmagyaróvár ab. Sie brauchen uns nur die
                                Ankunftszeit Ihres Zuges anzugeben.
                            </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/contact/accordion/Anreise-accordion4.png">
                        </div>
                    </div>
                    <a i18n *ngIf="locale !== 'fr'" href="https://shop.oebbtickets.at/de/ticket?cref=oebb-sparschiene-ungarn">Um Ihre
                        Zugreise
                        zu planen, klicken Sie hier! <img
                                src="assets/icons/navigation-arrow-yellow.svg"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/contact/bottom/Anreise-bottom.jpg">
</div>

