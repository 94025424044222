<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Inlays, Onlays, Füllungen</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Fullung-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>um die echten Zähne zu retten</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Die Entscheidung, ob der Zahndefekt mit einer Kunststofffüllung, mit einem Inlay oder einem Onlay
                        versorgt werden muss, ist davon abhängig, wie viel gesunde Zahnsubstanz noch vorhanden ist.
                    </p>
                    <p i18n>Im Falle einer Karies tun die Zahnärzte unserer Zahnklinik alles, um die echten Zähne zu retten.
                    </p>
                    <p i18n> Unsere Zahnklinik verwendet keine Amalgamfüllungen.
                    </p>


                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <div class="content--main__information__image-container">
                <div class="image">
                    <img src="assets/images/treatments/main/Fullung-main1.png">
                    <span i18n class="before">Vorher</span>
                </div>
                <div class="image">
                    <img src="assets/images/treatments/main/Fullung-main2.png">
                    <span i18n class="after">Nachher</span>
                </div>
            </div>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Was ist eine Füllung?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Mit Kompositfüllungen werden kleinere Löcher in den beschädigten Zähnen verschlossen.
                                Diese Füllung ist ästhetisch hochwertig, schont die Zahnsubstanz und kann sogar 10 oder
                                noch mehr Jahre lang halten. Füllungen können in unserer Eurodent Zahnklinik in einer
                                Sitzung angefertigt werden.</p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/Fullung-accordion1.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Was ist ein Inlay oder ein Onlay?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <p i18n>Inlays sind Einlagefüllungen, Onlays die sog. Teilkronen, die im Labor von unseren
                                Zahntechnikern individuell hergestellt werden. Sie passen perfekt zu den Zähnen. Die
                                Herstellung dieses Zahnersatzes ist deutlich anspruchsvoller und aufwändiger als eine
                                herkömmliche Füllung. Inlays und Onlays haben ein hervorragendes Aussehen und eine
                                perfekte Funktionalität.
                            </p>
                        </div>
                        <div class="images">
                            <img class="image" src="assets/images/treatments/accordion/Fullung-accordion2.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    Vorteile der Inlays und Onlays
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">hochwertige Füllungsart</div>
                        <div i18n class="content--main__content__list__item">gute Bissfestigkeit</div>
                        <div i18n class="content--main__content__list__item">lange Haltbarkeit</div>
                        <div i18n class="content--main__content__list__item">sehr ästhetisch</div>
                        <div i18n class="content--main__content__list__item">hohe biologische Verträglichkeit (enthalten kein
                            Metall)
                        </div>
                        <div i18n class="content--main__content__list__item">keine Temperaturempfindlichkeit</div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    Ablauf der Behandlung (Inlay/Onlay)
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Anfertigung in zwei Sitzungen möglich</div>
                        <div i18n class="content--main__content__list__item">Mit Implantatkronen ist es möglich, mehrere
                            Zähne oder sogar einen vollständig zahnlosen Kiefer mit festsitzendem Zahnersatz zu
                            ersetzen.
                        </div>
                        <div i18n class="content--main__content__list__item">Anfertigungszeit beträgt ca. 5 Arbeitstage</div>
                        <div i18n class="content--main__content__list__item">Provisorische Füllung bis zur Fertigstellung der
                            Inlays
                        </div>
                    </div>
                    <p i18n><span class="bold">Diese Daten dienen nur Ihrer Orientierung!</span> Wir planen die
                        Behandlungsschritte für jeden Patienten individuell unter Beachtung der persönlichen Anliegen
                        sowie unter Berücksichtigung des Mundzustandes.;</p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    Können alle Zähne mit Inlays oder Onlays versorgt werden?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Prinzipiell können alle Seitenzähne mit Inlays oder mit Onlays versorgt werden. Bei Frontzähnen
                        (Schneide- und Eckzähne) werden Veneers (Keramikschalen) eingesetzt.

                    </p>
                    <p i18n>In Fällen, wo der Zahndefekt (Karies oder alte Füllung) sehr groß ist, tief unter das Zahnfleisch
                        hineinreicht oder die verbleibende Zahnsubstanz schwach und brüchig ist (z.B. bei
                        wurzelbehandelten Zähnen), bietet eine Krone eine dauerhaftere Lösung.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/7.jpg">
</div>

