import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from "./feature/homepage/homepage.component";
import {WeekendTreatmentsComponent} from "./feature/company-services/components/weekend-treatments/weekend-treatments.component";
import {FreeTransportComponent} from "./feature/company-services/components/free-transport/free-transport.component";
import {FreeExaminationComponent} from "./feature/company-services/components/free-examination/free-examination.component";
import {FreeAccomodationComponent} from "./feature/company-services/components/free-accomodation/free-accomodation.component";
import {FixedTeethComponent} from "./feature/treatments/fixed-teeth/fixed-teeth.component";
import {StegprotheseComponent} from "./feature/treatments/stegprothese/stegprothese.component";
import {DentalImplantsComponent} from "./feature/treatments/dental-implants/dental-implants.component";
import {RemovableBracesComponent} from "./feature/treatments/removable-braces/removable-braces.component";
import {DentalCrownComponent} from "./feature/treatments/dental-crown/dental-crown.component";
import {DentalBridgeComponent} from "./feature/treatments/dental-bridge/dental-bridge.component";
import {DentureComponent} from "./feature/treatments/denture/denture.component";
import {FillingsComponent} from "./feature/treatments/fillings/fillings.component";
import {VeneerComponent} from "./feature/treatments/veneer/veneer.component";
import {CombinedDenturesComponent} from "./feature/treatments/combined-dentures/combined-dentures.component";
import {PanoramicXrayComponent} from "./feature/treatments/panoramic-xray/panoramic-xray.component";
import {ListOfTreatmentsComponent} from "./feature/treatments/list-of-treatments/list-of-treatments.component";
import {CadCamComponent} from "./feature/treatments/cad-cam/cad-cam.component";
import {VolumeTopographyComponent} from "./feature/treatments/volume-topography/volume-topography.component";
import {EmsDentalSpaComponent} from "./feature/treatments/ems-dental-spa/ems-dental-spa.component";
import {FixedBracesComponent} from "./feature/treatments/fixed-braces/fixed-braces.component";
import {HygieneComponent} from "./feature/treatments/hygiene/hygiene.component";
import {HealthInsuranceComponent} from "./feature/prices/health-insurance/health-insurance.component";
import {WarrantyComponent} from "./feature/prices/warranty/warranty.component";
import {AdvantagesComponent} from "./feature/about-us/advantages/advantages.component";
import {OurClinicComponent} from "./feature/about-us/our-clinic/our-clinic.component";
import {DentalLaboratoryComponent} from "./feature/about-us/dental-laboratory/dental-laboratory.component";
import {PriceExamplesComponent} from "./feature/prices/price-examples/price-examples.component";
import {WelcomePackageComponent} from "./feature/offers/welcome-package/welcome-package.component";
import {OfferDetailedComponent} from "./feature/offers/offer-detailed/offer-detailed.component";
import {BlogListComponent} from "./feature/blog/blog-list/blog-list.component";
import {BlogPageComponent} from "./feature/blog/blog-page/blog-page.component";
import {OurTeamComponent} from "./feature/about-us/our-team/our-team.component";
import {ArrivalComponent} from "./feature/contact/arrival/arrival.component";
import {FaqComponent} from "./feature/about-us/faq/faq.component";
import {GalleryPageComponent} from "./feature/gallery/gallery-page/gallery-page.component";
import {GalleryListComponent} from "./feature/gallery/gallery-list/gallery-list.component";
import {ContactComponent} from "./feature/contact/contact/contact.component";
import {CallbackComponent} from "./feature/contact/callback/callback.component";
import {CoronavirusComponent} from "./feature/coronavirus/coronavirus.component";
import {ThankYouComponent} from "./shared/components/thank-you/thank-you.component";
import {UrlRedirectComponent} from "./shared/components/url-redirect/url-redirect.component";
import {AllOnFourComponent} from "./feature/treatments/all-on-four/all-on-four.component";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        component: HomepageComponent,
        data: {
            type: "homepage",
        }
    },
    {
        path: "begrussungspaket",
        pathMatch: "full",
        component: WelcomePackageComponent,
        data: {
            lang: "de",
            title: "Begrüssungspaket für Neukunden zum Kennenlernen",
            description: "Begrüssungspaket - Eine besondere Möglichkeit, einen persönlichen Eindruck in unsere Dienstleistungen zu bekommen. Profitieren Sie von unserem Spezialangebot!",
        },
    },
    {
        path: "welcome-package",
        pathMatch: "full",
        component: WelcomePackageComponent,
        data: {
            lang: "en",
            title: "Eurodent Hungary - Welcome Package",
            description: "Dental Welcome Package for new costumers. Get know our services and become one of our satisfied clients!",
        },
    },
    {
        path: "pacchetto-di-benvenuto",
        pathMatch: "full",
        component: WelcomePackageComponent,
        data: {
            lang: "it",
            title: "Pacchetto di benvenuto",
            description: "Pacchetto di benvenuto per i nuovi clienti. Scoprite i nostri servizi e diventate uno dei nostri clienti soddisfatti!",
        },
    },
    {
        path: "package-dentaire",
        pathMatch: "full",
        component: WelcomePackageComponent,
        data: {
            lang: "fr",
            title: "Package dentaire - Eurodent",
            description: "Package dentaire - Une occasion particulière de se faire une impression personnelle de nos services. Profitez de notre offre spéciale !",
        },
    },
    {
        path: "angebote/:slug",
        component: OfferDetailedComponent,
    },
    {
        path: "offers/:slug",
        component: OfferDetailedComponent,
    },
    {
        path: "offerte/:slug",
        component: OfferDetailedComponent,
    },
    {
        path: "des-offres/:slug",
        component: OfferDetailedComponent,
    },
    // Free services
    {
        path: "kostenloser-abholdienst",
        pathMatch: "full",
        component: FreeTransportComponent,
        data: {
            lang: "de",
            title: "Kostenloser Abholdienst - zuverlässig, schnell und bequem",
            description: "Kostenloser Abholdienst für Ihren Komfort aus den Flughäfen Wien und Bratislava sowie Wien Mitte hin und retour.",
        },
    },
    {
        path: "free-pick-up-service",
        pathMatch: "full",
        component: FreeTransportComponent,
        data: {
            lang: "en",
            title: "Free Pick-up Service at Eurodent",
            description: "We offer free pick-up service from the Airports Vienna and Bratislava, such as from Wien-Mitte for your comfort. We make your stay the possibly most pleasant.",
        },
    },
    {
        path: "servizio-di-transfer-gratuito",
        pathMatch: "full",
        component: FreeTransportComponent,
        data: {
            lang: "it",
            title: "Transfer- Trasporto gratuito",
            description: "Offriamo un servizio di prelievo gratuito dagli aeroporti di Vienna e Bratislava, come ad esempio da Wien-Mitte, per la vostra comodità.",
        },
    },
    {
        path: "transfert-gratuit",
        pathMatch: "full",
        component: FreeTransportComponent,
        data: {
            lang: "fr",
            title: "Transfert gratuit - fiable, rapide et pratique",
            description: "Service de transport gratuit pour votre confort au départ des aéroports de Vienne et de Bratislava ainsi que de Vienne centre aller-retour.",
        },
    },
    {
        path: "kostenlose-erstuntersuchung",
        pathMatch: "full",
        component: FreeExaminationComponent,
        data: {
            lang: "de",
            title: "Kostenlose Erstuntersuchung ohne Praxisgebühr",
            description: "Die Eurodent Zahnklinik bietet eine kostenlose Erstuntersuchung mit Diagnose und Therapieplan. Wir beantworten alle Ihre Fragen rundums Thema Zahngesundheit.",
        },
    },
    {
        path: "free-consultation",
        pathMatch: "full",
        component: FreeExaminationComponent,
        data: {
            lang: "en",
            title: "Free Initial Examination at Eurodent Dental Clinic Hungary",
            description: "The Eurodent Dental Clinic Hungary offers a free initial examination: Get a free quote for your dental treatment in Hungary with the highest quality materials.",
        },
    },
    {
        path: "prima-visita-gratuita",
        pathMatch: "full",
        component: FreeExaminationComponent,
        data: {
            lang: "it",
            title: "Prima visita gratuita",
            description: "La Clinica Dentale Eurodent Ungheria offre un esame iniziale gratuito: Ottenete un preventivo gratuito per il vostro trattamento.",
        },
    },
    {
        path: "examen-personnalisé-gratuit",
        pathMatch: "full",
        component: FreeExaminationComponent,
        data: {
            lang: "fr",
            title: "Premier examen gratuit sans frais de consultation",
            description: "L'Eurodent Clinique Dentaire propose un premier examen gratuit avec un plan de traitement. Nous répondons à toutes vos questions sur la santé dentaire.",
        },
    },
    {
        path: "kostenlose-unterkunft",
        pathMatch: "full",
        component: FreeAccomodationComponent,
        data: {
            lang: "de",
            title: "Kostenlose Unterkunft während Ihrer Zahnbehandlung in Ungarn",
            description: "Brauchen Sie eine kostenlose Unterkunft während Ihrer Zahnbehandlung in Ungarn bei Eurodent? Nehmen Sie einfach den Kontakt mit unserem kompetenten Team!",
        },
    },
    {
        path: "free-accommodation",
        pathMatch: "full",
        component: FreeAccomodationComponent,
        data: {
            lang: "en",
            title: "Free Accomodation - Dental Clinic Hungary",
            description: "Do you need a stay during your dental treatment in Hungary? Eurodent Dental Clinic Hungary provides you a free accomodation. Click for details.",
        },
    },
    {
        path: "alloggio-gratuito",
        pathMatch: "full",
        component: FreeAccomodationComponent,
        data: {
            lang: "it",
            title: "Alloggio gratuito",
            description: "Avete bisogno di un soggiorno durante il vostro trattamento dentistico in Ungheria? La Clinica dentale Eurodent Ungheria vi offre un alloggio gratuito. Cliccate per i dettagli.",
        },
    },
    {
        path: "hebergement",
        pathMatch: "full",
        component: FreeAccomodationComponent,
        data: {
            lang: "fr",
            title: "Hébergement gratuit pendant vos soins dentaires en Hongrie",
            description: "Vous avez besoin d'un hébergement gratuit pendant vos soins dentaires en Hongrie chez Eurodent? Il vous suffit de prendre contact avec notre équipe compétente!",
        },
    },
    {
        path: "wochenendbehandlung",
        pathMatch: "full",
        component: WeekendTreatmentsComponent,
        data: {
            lang: "de",
            title: "Wochenendbehandlung auch samstags!",
            description: "Samstagsbehandlungen - Möchten Sie keinen Urlaub wegen Zahnarzt nehmen? Mit unserer Wochenendbehandlung müssen Sie von der Arbeit nicht weg!",
        },
    },
    {
        path: "weekend-treatment",
        pathMatch: "full",
        component: WeekendTreatmentsComponent,
        data: {
            lang: "en",
            title: "Dental Clinic Hungary - Mosonmagyaróvár",
            description: "Dental Clinic Hungary | The best cosmetic dentistry and implantology clinic provides dental treatments for reasonable prices in Hungary.",
        },
    },
    {
        path: "trattamento-del-fine-settimana",
        pathMatch: "full",
        component: WeekendTreatmentsComponent,
        data: {
            lang: "it",
            title: "Trattamento del fine settimana",
            description: "Trattamento del fine settimana",
        },
    },
    {
        path: "soins-pendant-le-weekend",
        pathMatch: "full",
        component: WeekendTreatmentsComponent,
        data: {
            lang: "fr",
            title: "Traitement le week-end, également le samedi !",
            description: "Traitements du samedi - Vous ne voulez pas prendre de vacances pour aller chez le dentiste ? Profitez de nos traitements du week-end !",
        },
    },
    // Treatments
    {
        path: "zahnimplantate",
        pathMatch: "full",
        component: DentalImplantsComponent,
        data: {
            lang: "de",
            title: "Zahnimplantate | der moderne Ersatz fehlender Zähne",
            description: "Zahnimplantate bieten Ihnen eine dauerhafte Lösung bei fehlenden Zähnen. Modernste Technologie, 30+ Jahren Erfahrung und schmerzfreier Behandlung.",
        },
    },
    {
        path: "dental-implants",
        pathMatch: "full",
        component: DentalImplantsComponent,
        data: {
            lang: "en",
            title: "Dental Implants | Highest Quality",
            description: "Dental Implants at Eurodent Hungary are the most modern solution for tooth replacement. Enjoy your new life quality, smile like you did before.",
        },
    },
    {
        path: "impianti-dentali",
        pathMatch: "full",
        component: DentalImplantsComponent,
        data: {
            lang: "it",
            title: "Denti fissi con impianti dentali",
            description: "Le protesi fisse con impianti dentali vi danno la sensazione di avere i vostri denti. Dimenticate le dentiere vecchio stile e non incollate più.",
        },
    },
    {
        path: "implants-dentaires",
        pathMatch: "full",
        component: DentalImplantsComponent,
        data: {
            lang: "fr",
            title: "Dents fixes - Débarrassez-vous de votre prothèse dentaire !",
            description: "Vous portez un dentier et vous voulez vous en débarrasser? Avec les implants dentaires, vous bénéficiez d'une qualité supérieure et d'un confort de mâchement!",
        },
    },
    {
        path: "stegprothese",
        pathMatch: "full",
        component: StegprotheseComponent,
        data: {
            lang: "de",
            title: "Stegprothese | Die Alternative zur Vollprothese",
            description: "Eine Stegprothese ist eine perfekte Lösung, eine Vollprothese zu ersetzen. Mit Zahnimplantaten hat die Prothese einen sicheren Sitz. Kein Rutschen und Kleben!",
        },
    },
    {
        path: "overdenture",
        pathMatch: "full",
        component: StegprotheseComponent,
        data: {
            lang: "en",
            title: "Overdenture with Dental Implants",
            description: "Partial Dentures and Overdenture with Dental Implants for the best fitting and long lasting solution for your chewing comfort and life quality.",
        },
    },
    {
        path: "protesi-a-barra",
        pathMatch: "full",
        component: StegprotheseComponent,
        data: {
            lang: "it",
            title: "Protesi a barra / parziale rimovibile",
            description: "Protesi parziali e overdenture con impianti dentali per una soluzione ottimale e duratura per il comfort masticatorio e la qualità della vita.",
        },
    },
    {
        path: "prothese-dentaire-semi-amovible",
        pathMatch: "full",
        component: StegprotheseComponent,
        data: {
            lang: "fr",
            title: "Prothèse sur barre | L'alternative à la prothèse complète",
            description: "Une prothèse sur barre est une solution parfaite pour remplacer une prothèse complète. Fixement parfait des implants dentaires. Pas de glissement ni de collage!",
        },
    },
    {
        path: "feste-zahne",
        pathMatch: "full",
        component: FixedTeethComponent,
        data: {
            lang: "de",
            title: "Feste Zähne - Werden Sie die Prothese los!",
            description: "Tragen Sie eine Prothese und wollen sie sie loswerden? Mit Zahnimplantaten erhalten Sie nicht Topqualität und einen Kaukomfort wie bei den eigenen Zähnen.",
        },
    },
    {
        path: "fixed-denture",
        pathMatch: "full",
        component: FixedTeethComponent,
        data: {
            lang: "en",
            title: "Fixed Denture with dental implants",
            description: "Fixed Dentures with Dental Implants gives you the feeling like your own teeth. Forget the old-style dentures and don't glue anymore.",
        },
    },
    {
        path: "denti-fissi",
        pathMatch: "full",
        component: FixedTeethComponent,
        data: {
            lang: "it",
            title: "Impianti dentali",
            description: "Gli impianti dentali di Eurodent Ungheria sono la soluzione più moderna per la sostituzione dei denti. Godetevi la vostra nuova qualità di vita.",
        },
    },
    {
        path: "dents-fixes",
        pathMatch: "full",
        component: FixedTeethComponent,
        data: {
            lang: "fr",
            title: "implants dentaires | le remplacement moderne des dents",
            description: "Les implants dentaires vous offrent une solution durable. Une technologie de pointe, plus de 30 ans d'expérience et un traitement indolore.",
        },
    },
    {
        path: "all-on-4-de",
        pathMatch: "full",
        component: AllOnFourComponent,
        data: {
            lang: "de",
            title: "",
            description: "",
        },
    },
    {
        path: "all-on-4-en",
        pathMatch: "full",
        component: AllOnFourComponent,
        data: {
            lang: "en",
            title: "",
            description: "",
        },
    },
    {
        path: "all-on-4-it",
        pathMatch: "full",
        component: AllOnFourComponent,
        data: {
            lang: "it",
            title: "",
            description: "",
        },
    },
    {
        path: "all-on-4-fr",
        pathMatch: "full",
        component: AllOnFourComponent,
        data: {
            lang: "fr",
            title: "",
            description: "",
        },
    },
    {
        path: "herausnehmbare-zahnspange",
        pathMatch: "full",
        component: RemovableBracesComponent,
        data: {
            lang: "de",
            title: "Herausnehmbare Zahnspange \"Invisalign\" für die geraden Zähne",
            description: "Die herausnehmbare Zahnspange mit Invisalign Technologie ist die modernste Lösung, schöne und gerade Zähne zu zaubern.",
        },
    },
    {
        path: "removable-braces",
        pathMatch: "full",
        component: RemovableBracesComponent,
        data: {
            lang: "en",
            title: "Removable Braces",
            description: "Removable Braces from Invisalign are the best invisible braces to make your teeth straight.",
        },
    },
    {
        path: "apparecchio-dentale-rimovibile",
        pathMatch: "full",
        component: RemovableBracesComponent,
        data: {
            lang: "it",
            title: "Apparecchio dentale rimovibile",
            description: "Gli apparecchi rimovibili di Invisalign sono i migliori apparecchi invisibili per raddrizzare i denti.",
        },
    },
    {
        path: "appareil-orthodontique-invisible",
        pathMatch: "full",
        component: RemovableBracesComponent,
        data: {
            lang: "fr",
            title: "Appareil dentaire amovible pour les dents alignées",
            description: "Les appareils dentaires amovibles avec la technologie Invisalign sont la solution la plus moderne pour obtenir de belles dents bien alignées.",
        },
    },
    {
        path: "feste-zahnspange",
        pathMatch: "full",
        component: FixedBracesComponent,
        data: {
            lang: "de",
            title: "Feste Zahnspange für die Lösung von Zahnunregelmäßigkeiten",
            description: "Modernste Brackets für eine feste Zahnspange, mit denen Sie ein wunderschönes Lächeln ohne Zahnunregelmäßigkeiten erhalten.",
        },
    },
    {
        path: "fixed-braces",
        pathMatch: "full",
        component: FixedBracesComponent,
        data: {
            lang: "en",
            title: "Fixed Braces",
            description: "With the most modern \"Damon\" Brackets you save time and energy to get your teeth straight again.",
        },
    },
    {
        path: "apparecchio-dentale-fisso",
        pathMatch: "full",
        component: FixedBracesComponent,
        data: {
            lang: "it",
            title: "Apparecchio dentale fisso",
            description: "Con i modernissimi brackets \"Damon\" risparmierete tempo ed energia per tornare a raddrizzare i vostri denti",
        },
    },
    {
        path: "bagues-ortodontie",
        pathMatch: "full",
        component: FixedBracesComponent,
        data: {
            lang: "fr",
            title: "Appareil dentaire fixe - résoudre les irrégularités dentaire",
            description: "Des bagues à la pointe de la technologie pour un appareil dentaire fixe qui vous permettront d'obtenir un magnifique sourire sans irrégularités dentaires.",
        },
    },
    {
        path: "zahnkrone",
        pathMatch: "full",
        component: DentalCrownComponent,
        data: {
            lang: "de",
            title: "Ästhetische Zahnkrone für den Schutz und Aufbau Ihrer Zähne",
            description: "Eine ästhetische Zahnkrone dient zur Wiederaufbau zerstörter Zähne. Bei Eurodent erhalten Sie qualitativ hochwertige und dauerhafte Zahnkronen!",
        },
    },
    {
        path: "dental-crown",
        pathMatch: "full",
        component: DentalCrownComponent,
        data: {
            lang: "en",
            title: "Metal free ceramic dental crowns (Cercon)",
            description: "Metal free ceramic dental crowns (Cercon) are the most aesthetic and biocompatible tooth replacements at Eurodent. High quality for an affordable price.",
        },
    },
    {
        path: "corona-dentale",
        pathMatch: "full",
        component: DentalCrownComponent,
        data: {
            lang: "it",
            title: "Corona dentale in ceramica senza metallo (Cercon)",
            description: "Le corone dentali in ceramica senza metallo (Cercon) sono i sostituti dentali più estetici e biocompatibili di Eurodent. Alta qualità a un prezzo accessibile.",
        },
    },
    {
        path: "couronne-dentaire",
        pathMatch: "full",
        component: DentalCrownComponent,
        data: {
            lang: "fr",
            title: "Couronne dentaire esthétique - la reconstitution des dents",
            description: "Une couronne dentaire esthétique sert à reconstruire les dents détruites. Chez Eurodent, vous obtenez des couronnes dentaires durables et de grande qualité !",
        },
    },
    {
        path: "zahnbrucke",
        pathMatch: "full",
        component: DentalBridgeComponent,
        data: {
            lang: "de",
            title: "",
            description: "",
        },
    },
    {
        path: "dental-bridge",
        pathMatch: "full",
        component: DentalBridgeComponent,
        data: {
            lang: "en",
            title: "Dental Bridge | Eurodent Dental Clinic Hungary",
            description: "A Dental Bridge is the best solution to close a gap without dental implants. With our metal free cercon bridges you get your smile back.",
        },
    },
    {
        path: "ponte-dentale",
        pathMatch: "full",
        component: DentalBridgeComponent,
        data: {
            lang: "it",
            title: "Ponte dentale| Eurodent Dental Clinic Hungary",
            description: "Corone, ponti",
        },
    },
    {
        path: "bridge-dentaire",
        pathMatch: "full",
        component: DentalBridgeComponent,
        data: {
            lang: "fr",
            title: "Bridge dentaire de haute qualité pour combler les espaces",
            description: "Vous avez des dents manquantes et ne souhaitez pas avoir d'implants dentaires? Un bridge dentaire esthétique vous y aidera.",
        },
    },
    {
        path: "zahnprothese",
        pathMatch: "full",
        component: DentureComponent,
        data: {
            lang: "de",
            title: "Hochwertige Zahnbrücke für den Verschluss von Zahnlücken",
            description: "Haben Sie Zahnlücken und möchten keine Zahnimplantate haben? Eine ästhetische Zahnbrücke aus bestem Material hilft Ihnen dabei. Qualität und Zuverlässigkeit!",
        },
    },
    {
        path: "full-denture",
        pathMatch: "full",
        component: DentureComponent,
        data: {
            lang: "en",
            title: "Full Denture | Eurodent Dental Clinic Hungary",
            description: "With a full denture you get your teeth back even if a dental implantation is not possible.",
        },
    },
    {
        path: "dentiera",
        pathMatch: "full",
        component: DentureComponent,
        data: {
            lang: "it",
            title: "Protesi totale / parziale rimovibile",
            description: "Un ponte dentale è la soluzione migliore per chiudere una lacuna senza impianti dentali. Con i nostri ponti cercon metal free potrete riavere il vostro sorriso.",
        },
    },
    {
        path: "prothese-dentaire",
        pathMatch: "full",
        component: DentureComponent,
        data: {
            lang: "fr",
            title: "Prothèse dentaire | Remplacement ds dents rapidement",
            description: "Une prothèse dentaire permet de rétablir rapidement la fonction masticatoire et la capacité de parler. Elle est fabriquée avec le plus grand soin.",
        },
    },
    {
        path: "kombinierter-zahnersatz",
        pathMatch: "full",
        component: CombinedDenturesComponent,
        data: {
            lang: "de",
            title: "Kombinierter Zahnersatz | Zahnersatz kosten",
            description: "Kombinierter Zahnersatz - Von einem kombinierten Zahnersatz spricht man, wenn der Zahnersatz sowohl festsitzende als auch herausnehmbare Bestandteile hat.",
        },
    },
    {
        path: "combined-dentures",
        pathMatch: "full",
        component: CombinedDenturesComponent,
        data: {
            lang: "en",
            title: "Combined dentures",
            description: "Molar tooth replacement without dental implants. If you have some molars missing and you don't want to have implants, combined dentures are the best solution.",
        },
    },
    {
        path: "protesi-combinate",
        pathMatch: "full",
        component: CombinedDenturesComponent,
        data: {
            lang: "it",
            title: "Protesi combinate",
            description: "Sostituzione dei denti senza impianti dentali. Se mancano alcuni molari e non si vuole ricorrere agli impianti, la soluzione migliore è la protesi combinata.",
        },
    },
    {
        path: "protheses-combinees",
        pathMatch: "full",
        component: CombinedDenturesComponent,
        data: {
            lang: "fr",
            title: "Prothèse combinée | Prothèse dentaire coûts",
            description: "Prothèse dentaire combinée - On parle de prothèse dentaire combinée lorsque la prothèse comporte à la fois des éléments fixes et des éléments amovibles.",
        },
    },
    {
        path: "inlay-onlay-fullung",
        pathMatch: "full",
        component: FillingsComponent,
        data: {
            lang: "de",
            title: "Zahnprothese | Ersatz aller Zähne schnell und günstig",
            description: "Eine schnelle Wiederherstellung der Kaufunktion und des Sprechvermögens bietet eine Zahnprothese. Hersteellung im eigenen Zahntechniklabor mit größter Sorgfalt.",
        },
    },
    {
        path: "inlay-onlay-filling",
        pathMatch: "full",
        component: FillingsComponent,
        data: {
            lang: "en",
            title: "Inlay / Onlay / Filling",
            description: "Inlay / Onlay / Filling - Conservative Dentistry, tight dental fillings for a long lasting tooth health.",
        },
    },
    {
        path: "inlay-onlay-otturazione",
        pathMatch: "full",
        component: FillingsComponent,
        data: {
            lang: "it",
            title: "Inlay/onlay/otturazione",
            description: "Inlay / Onlay / Filling - Odontoiatria conservativa, otturazioni dentali strette per una salute dei denti duratura.",
        },
    },
    {
        path: "inlay-onlay-obturation",
        pathMatch: "full",
        component: FillingsComponent,
        data: {
            lang: "fr",
            title: "Inlay, onlay, obturation",
            description: "Inlay, onlay, obturation - En cas de carie, les dentistes de notre clinique dentaire font tout pour sauver les vraies dents.",
        },
    },
    {
        path: "veneer-verblendschale",
        pathMatch: "full",
        component: VeneerComponent,
        data: {
            lang: "de",
            title: "Veneer / Verblendschale",
            description: "Veneer als Verblendschale für ein ästhetisches Lächeln wie bei Hollywood Stars!",
        },
    },
    {
        path: "veneer",
        pathMatch: "full",
        component: VeneerComponent,
        data: {
            lang: "en",
            title: "Dental Veneer",
            description: "A dental veneer is an aesthetic shell for make your teeth like Hollywood stars have.",
        },
    },
    {
        path: "facetta-dentale",
        pathMatch: "full",
        component: VeneerComponent,
        data: {
            lang: "it",
            title: "Faccetta dentale",
            description: "Le faccette dentali sono un guscio estetico per rendere i denti come quelli delle star di Hollywood.",
        },
    },
    {
        path: "facette",
        pathMatch: "full",
        component: VeneerComponent,
        data: {
            lang: "fr",
            title: "Facette",
            description: "Facette pour un sourire esthétique comme celui des stars de Hollywood !",
        },
    },
    {
        path: "cad-cam-technologie",
        pathMatch: "full",
        component: CadCamComponent,
        data: {
            lang: "de",
            title: "CAD-CAM Technologie",
            description: "Wir verwenden die modernste CAD-CAM Technologie, damit Ihre Zahnkronen und Zahnbrücken mit höchster Präzision angefertigt werden!",
        },
    },
    {
        path: "cad-cam-technologies",
        pathMatch: "full",
        component: CadCamComponent,
        data: {
            lang: "en",
            title: "CAD CAM technology",
            description: "CAD CAM technology is used by us to prepare the most solid tooth replacement with digital technology.  The future of the dentistry is at Eurodent.",
        },
    },
    {
        path: "tecnologia-cad-cam",
        pathMatch: "full",
        component: CadCamComponent,
        data: {
            lang: "it",
            title: "Tecnologia CAD CAM",
            description: "La tecnologia CAD CAM per preparare la più solida sostituzione del dente con la tecnologia digitale. Il futuro dell'odontoiatria è in Eurodent.",
        },
    },
    {
        path: "la-technologie-cad-cam",
        pathMatch: "full",
        component: CadCamComponent,
        data: {
            lang: "fr",
            title: "La technologie CAD/CAM",
            description: "La technologie CAD CAM est utilisée par nous pour préparer le remplacement de dent le plus solide avec la technologie numérique.",
        },
    },
    {
        path: "ems-dental-spa",
        pathMatch: "full",
        component: EmsDentalSpaComponent,
        data: {
            lang: "de",
            title: "EMS DENTAL Spa",
            description: "EMS DENTAL Spa - Die modernste Lösung, den Zahnstein loszuweden und Zahnfleischentzündung vorzubeugen!",
        },
    },
    {
        path: "ems-dental-spa",
        pathMatch: "full",
        component: EmsDentalSpaComponent,
        data: {
            lang: "en",
            title: "EMS Dental Spa Oral hygiene",
            description: "EMS Dental Spa Oral hygiene is the most modern technology to keep your teeth healthy and free from tartar and periodontitis",
        },
    },
    {
        path: "ems-dentale-spa",
        pathMatch: "full",
        component: EmsDentalSpaComponent,
        data: {
            lang: "it",
            title: "EMS DENTALE Spa",
            description: "EMS Dental Spa L'igiene orale è la tecnologia più moderna per mantenere i denti sani e privi di tartaro e parodontite.",
        },
    },
    {
        path: "ems-dentaire-spa",
        pathMatch: "full",
        component: EmsDentalSpaComponent,
        data: {
            lang: "fr",
            title: "EMS DENTAIRE Spa",
            description: "EMS DENTAIRE Spa - La solution la plus moderne pour se débarrasser du tartre et prévenir la gingivite !",
        },
    },
    {
        path: "mundhygiene",
        pathMatch: "full",
        component: HygieneComponent,
        data: {
            lang: "de",
            title: "Mundhygiene",
            description: "Mundhygiene - Die grundlegende Voraussetzung für die Gesundheit und Festigkeit der Zähne ist eine gründliche und effektive Mundpflege.",
        },
    },
    {
        path: "oral-hygiene",
        pathMatch: "full",
        component: HygieneComponent,
        data: {
            lang: "en",
            title: "Oral hygiene for healthy teeth",
            description: "A regural oral hygiene for healthy teeth helps you to remove rartar and prevent periodontitis",
        },
    },
    {
        path: "igiene-orale",
        pathMatch: "full",
        component: HygieneComponent,
        data: {
            lang: "it",
            title: "Igiene orale",
            description: "Un'igiene orale regolare per denti sani aiuta a rimuovere il tartaro e a prevenire la parodontite.",
        },
    },
    {
        path: "hygiene-buccale",
        pathMatch: "full",
        component: HygieneComponent,
        data: {
            lang: "fr",
            title: "Hygiène buccale",
            description: "Hygiène buccale - La condition fondamentale pour la santé et la solidité des dents est une hygiène buccale approfondie et efficace.",
        },
    },
    {
        path: "3D-volumentomographie",
        pathMatch: "full",
        component: VolumeTopographyComponent,
        data: {
            lang: "de",
            title: "Zahnbehandlungen - Eurodent Zahnklinik Ungarn",
            description: "Zahnbehandlungen - Unser Hauptprofil sind Implantologie und ästhetische Zahnheilkunde, aber wir bieten auch alle übrigen zahnärztlichen Leistungen an.",
        },
    },
    {
        path: "3D-volume-tomography",
        pathMatch: "full",
        component: VolumeTopographyComponent,
        data: {
            lang: "en",
            title: "3D volume tomography",
            description: "With our most innovative 3D volume tomography unit there are no more hidden dental problems. Dental implantation planning was never easier than with a 3D CT.",
        },
    },
    {
        path: "tac-3D",
        pathMatch: "full",
        component: VolumeTopographyComponent,
        data: {
            lang: "it",
            title: "TAC 3D",
            description: "La pianificazione dell'impianto dentale non è mai stata così facile come con una TAC 3D.",
        },
    },
    {
        path: "tomographie-volumique-numerisee-ct-3D",
        pathMatch: "full",
        component: VolumeTopographyComponent,
        data: {
            lang: "fr",
            title: "Tomographie volumique numérisée CT 3D",
            description: "Soins dentaires - Notre profil principal est l'implantologie et la dentisterie esthétique, mais nous proposons également tous les autres services dentaires.",
        },
    },
    {
        path: "panoramarontgenaufnahme",
        pathMatch: "full",
        component: PanoramicXrayComponent,
        data: {
            lang: "de",
            title: "Erstuntersuchung - Eurodent Zahnklinik Ungarn",
            description: "Erstuntersuchung - KONSULTATIONEN SIND IN UNSERER KLINIK GRATIS",
        },
    },
    {
        path: "panoramic-x-ray",
        pathMatch: "full",
        component: PanoramicXrayComponent,
        data: {
            lang: "en",
            title: "Panoramic X-Ray",
            description: "A Panoramic X-Ray is the basis to get a picture of your teeth' conditions. We use panoramic x-rays to be able to give you the most appropriate treatment plan.",
        },
    },
    {
        path: "radiografia-panoramica",
        pathMatch: "full",
        component: PanoramicXrayComponent,
        data: {
            lang: "it",
            title: "Radiografia panoramica",
            description: "Una radiografia panoramica è la base per ottenere un quadro delle condizioni dei vostri denti. Per il piano di trattamento più appropriato.",
        },
    },
    {
        path: "radiographie-panoramique",
        pathMatch: "full",
        component: PanoramicXrayComponent,
        data: {
            lang: "fr",
            title: "Radiographie panoramique",
            description: "Radiographie panoramique pour une diagnostique profonde sans suprises inattendus !",
        },
    },
    {
        path: "preisbeispiele",
        pathMatch: "full",
        component: PriceExamplesComponent,
        data: {
            lang: "de",
            title: "Kosten - Eurodent Zahnklinik Ungarn",
            description: "Kosten - Wir erstellen unseren Patienten einen individuellen Heil- und Kostenplan aufgrund eines aktuellen Röntgenbildes oder nach einer Erstuntersuchung.",
        },
    },
    {
        path: "costs-price-examples",
        pathMatch: "full",
        component: PriceExamplesComponent,
        data: {
            lang: "en",
            title: "Costs, Price Examples",
            description: "Dental Treatment - What does it cost? Costs and Price Examples for your orientation. Save at least 50% from your dental treatment costs!",
        },
    },
    {
        path: "esempi-dei-prezzi",
        pathMatch: "full",
        component: PriceExamplesComponent,
        data: {
            lang: "it",
            title: "Esempi dei prezzi",
            description: "Cure dentali - Quanto costa? Costi ed esempi di prezzo per il vostro orientamento. Risparmiate almeno il 50% sui costi delle vostre cure dentistiche!",
        },
    },
    {
        path: "nos-prix",
        pathMatch: "full",
        component: PriceExamplesComponent,
        data: {
            lang: "fr",
            title: "Coûts - Eurodent clinique dentaire Hongrie",
            description: "Coûts - Nous établissons pour nos patients un plan de traitement et de coûts individuel sur la base d'une radiographie récente ou après un premier examen.",
        },
    },
    {
        path: "krankenversicherung",
        pathMatch: "full",
        component: HealthInsuranceComponent,
        data: {
            lang: "de",
            title: "Krankenversicherung",
            description: "Krankenversicherung - Ihre Krankenkasse kann voll oder teilweise die Kosten für Ihre Zahnbehandlung erstatten.",
        },
    },
    {
        path: "health-insurance",
        pathMatch: "full",
        component: HealthInsuranceComponent,
        data: {
            lang: "en",
            title: "Healthcare refunds",
            description: "Your Private Healthcare Insurance participates of the costs of your dental treatment in Hungary at Eurodent. Get the same % back like in your domestic country.",
        },
    },
    {
        path: "assicurazione-sanitaria",
        pathMatch: "full",
        component: HealthInsuranceComponent,
        data: {
            lang: "it",
            title: "Assicurazione sanitaria",
            description: "La vostra assicurazione sanitaria partecipa ai costi delle vostre cure dentistiche in Ungheria presso Eurodent. Ottenete la stessa percentuale di rimborso.",
        },
    },
    {
        path: "remboursement-des-soins-dentaires",
        pathMatch: "full",
        component: HealthInsuranceComponent,
        data: {
            lang: "fr",
            title: "Remboursement des soins dentaires",
            description: "Remboursement des soins dentaires - Votre caisse d'assurance maladie peut rembourser tout ou partie des frais de vos soins dentaires.",
        },
    },
    {
        path: "garantie",
        pathMatch: "full",
        component: WarrantyComponent,
        data: {
            lang: "de",
            title: "Eurodent Zahnklinik - Garantie",
            description: "Eurodent Zahnklinik - Garantie: Bei uns erhalten Sie für eine Garantie für jeden Zahnersatz und ein Zertifikat. Sogar lebenslange Garantie für Zahnimplantate!",
        },
    },
    {
        path: "warranty",
        pathMatch: "full",
        component: WarrantyComponent,
        data: {
            lang: "en",
            title: "Dental Treatment Warranty",
            description: "At Eurodent you don't leave without a warranty for your tooth replacement! Up to lifelong warranty for dental implants, quality warranty.",
        },
    },
    {
        path: "garanzia",
        pathMatch: "full",
        component: WarrantyComponent,
        data: {
            lang: "it",
            title: "Garanzia - Implantologia dentale Ungheria",
            description: "Da Eurodent non si esce senza una garanzia per la sostituzione dei denti! Garanzia fino a tutta la vita per gli impianti dentali, garanzia di qualità.",
        },
    },
    {
        path: "conditions-de-garantie",
        pathMatch: "full",
        component: WarrantyComponent,
        data: {
            lang: "fr",
            title: "Garantie chez Eurodent",
            description: "Chez Eurodent, vous ne partez pas sans une garantie pour votre remplacement de dents ! Jusqu'à la garantie à vie pour les implants dentaires, garantie de qualité.",
        },
    },
    // About us
    {
        path: "ihre-vorteile",
        pathMatch: "full",
        component: AdvantagesComponent,
        data: {
            lang: "de",
            title: "Unsere Klinik - Eurodent Zahnklinik Ungarn",
            description: "Unsere Klinik - Bei EURODENT  Zahn- und Implantatzentrum stehen Ihre Wünsche und Ansprüche im Vordergrund. Entdecken Sie Ihre Vorteile.",
        },
    },
    {
        path: "your-benefits",
        pathMatch: "full",
        component: AdvantagesComponent,
        data: {
            lang: "en",
            title: "Your benetfits at Eurdent Dental Clinic in Hungary",
            description: "Your benetfits at Eurdent Dental Clinic in Hungary: High quality standards, free pick-up and accomodation, painless dental treatments",
        },
    },
    {
        path: "vantaggi",
        pathMatch: "full",
        component: AdvantagesComponent,
        data: {
            lang: "it",
            title: "I vostri vantaggi presso la Clinica dentale Eurdent in Ungheria",
            description: "I vostri vantaggi presso la Clinica dentale Eurdent in Ungheria: standard di alta qualità, ritiro e alloggio gratuiti, trattamenti dentali indolori.",
        },
    },
    {
        path: "vos-avantages-avec-nous",
        pathMatch: "full",
        component: AdvantagesComponent,
        data: {
            lang: "fr",
            title: "Vos avantages avec nous",
            description: "Vos avantages à la clinique dentaire Eurdent en Hongrie: normes de qualité élevées, prise en charge et hébergement gratuits, traitements dentaires sans douleur.",
        },
    },
    {
        path: "unsere-klinik",
        pathMatch: "full",
        component: OurClinicComponent,
        data: {
            lang: "de",
            title: "Unsere Klinik - Eurodent Zahnklinik Ungarn",
            description: "Unsere Klinik EURODENT Zahn- und Implantatzentrum, damit Ihr Aufenthalt und Ihre Sitzungen werden so angenehm, wie möglich werden.",
        },
    },
    {
        path: "our-clinic",
        pathMatch: "full",
        component: OurClinicComponent,
        data: {
            lang: "en",
            title: "Dental Clinic Hungary - Mosonmagyaróvár",
            description: "Dental Clinic Hungary | The best cosmetic dentistry and implantology clinic provides dental treatments for reasonable prices in Hungary.",
        },
    },
    {
        path: "la-nostra-clinica",
        pathMatch: "full",
        component: OurClinicComponent,
        data: {
            lang: "it",
            title: "Clinica dentale Ungheria - Mosonmagyaróvár",
            description: "Clinica dentale Ungheria | La migliore clinica di odontoiatria estetica e implantologia offre trattamenti dentali a prezzi ragionevoli in Ungheria.",
        },
    },
    {
        path: "notre-clinique",
        pathMatch: "full",
        component: OurClinicComponent,
        data: {
            lang: "fr",
            title: "Notre clinique - Eurodent Clinique Dentaire Hongrie",
            description: "Notre clinique EURODENT est un centre dentaire et d'implants, afin que votre séjour et vos séances soient aussi agréables que possible.",
        },
    },
    {
        path: "unser-team",
        pathMatch: "full",
        component: OurTeamComponent,
        data: {
            lang: "de",
            title: "Eurodent Zahnklinik Team",
            description: "Das Eurodent Zahnklinik Team erwartet Sie mit 30+ Jahren Erfahrung und bester Qualität, wenn Sie eine Zahnbehandlung brauchen.",
        },
    },
    {
        path: "our-team",
        pathMatch: "full",
        component: OurTeamComponent,
        data: {
            lang: "en",
            title: "Dentists - Eurodent Dental Clinic Hungary",
            description: "Dentists - Eurodent Dental Clinic Hungary. Highest Quality provided by an experienced and high-qualified team of dentists and oral surgeons.",
        },
    },
    {
        path: "la-nostra-personale",
        pathMatch: "full",
        component: OurTeamComponent,
        data: {
            lang: "it",
            title: "Dentisti - Clinica dentale Eurodent Ungheria",
            description: "Dentisti - Clinica dentale Eurodent Ungheria. Massima qualità fornita da un team di dentisti e chirurghi orali esperti e altamente qualificati.",
        },
    },
    {
        path: "notre-equipe",
        pathMatch: "full",
        component: OurTeamComponent,
        data: {
            lang: "fr",
            title: "Notre équipe chez Eurodent Clinique Dentaire",
            description: "L'équipe de la Eurodent Clinique Dentaire vous attend avec plus de 30 ans d'expérience et la meilleure qualité si vous avez besoin de soins dentaires.",
        },
    },
    {
        path: "galerie",
        pathMatch: "full",
        component: GalleryListComponent,
        data: {
            lang: "de",
            title: "Galerie",
            description: "Galerie - Entdecken Sie die Eurodent Zahnklinik Ungarn in Bildern und lassen Sie sich überzeugen.",
        },
    },
    {
        path: "gallery",
        pathMatch: "full",
        component: GalleryListComponent,
        data: {
            lang: "en",
            title: "Dental Clinic Hungary Picture Gallery",
            description: "Dental Clinic Hungary Picture Gallery - Eurodent in Pictures",
        },
    },
    {
        path: "galleria",
        pathMatch: "full",
        component: GalleryListComponent,
        data: {
            lang: "it",
            title: "Clinica dentale Ungheria Galleria di immagini",
            description: "Clinica dentale Ungheria Galleria di immagini - Eurodent in immagini",
        },
    },
    {
        path: "galerie",
        pathMatch: "full",
        component: GalleryListComponent,
        data: {
            lang: "fr",
            title: "Galerie",
            description: "Galerie - Découvrez la clinique dentaire Eurodent Hongrie en images et laissez-vous convaincre.",
        },
    },
    {
        path: "galerie/:slug",
        pathMatch: "full",
        component: GalleryPageComponent
    },
    {
        path: "gallery/:slug",
        pathMatch: "full",
        component: GalleryPageComponent
    },
    {
        path: "galleria/:slug",
        pathMatch: "full",
        component: GalleryPageComponent
    },
    {
        path: "galerie/:slug",
        pathMatch: "full",
        component: GalleryPageComponent
    },
    {
        path: "haufig-gestellte-fragen",
        pathMatch: "full",
        component: FaqComponent,
        data: {
            lang: "de",
            title: "Eurodent Zahnklinik Ungarn",
            description: "Eurodent Zahnklinik Ungarn - Häufig gestellte Fragen im Thema Zahnbehandlung und Zahngesundheit.",
        },
    },
    {
        path: "frequently-asked-questions",
        pathMatch: "full",
        component: FaqComponent,
        data: {
            lang: "en",
            title: "STATEMENT ON DATA PROTECTION – INFORMATION ON DATA CONTROL",
            description: "STATEMENT ON DATA PROTECTION – INFORMATION ON DATA CONTROL",
        },
    },
    {
        path: "domande-frequenti",
        pathMatch: "full",
        component: FaqComponent,
        data: {
            lang: "it",
            title: "DICHIARAZIONE SULLA PROTEZIONE DEI DATI - INFORMAZIONI SUL CONTROLLO DEI DATI",
            description: "DICHIARAZIONE SULLA PROTEZIONE DEI DATI - INFORMAZIONI SUL CONTROLLO DEI DATI",
        },
    },
    {
        path: "questions-frequentes",
        pathMatch: "full",
        component: FaqComponent,
        data: {
            lang: "fr",
            title: "Eurodent Clinique Dentaire Hongrie",
            description: "Eurodent Clinique Dentaire Hongrie - Questions fréquentes sur les soins et la santé dentaires.",
        },
    },
    {
        path: "eurodent-zahntechniklabor",
        pathMatch: "full",
        component: DentalLaboratoryComponent,
        data: {
            lang: "de",
            title: "Eurodent Zahntechniklabor",
            description: "Unser klinikeigenes Eurodent Zahntechniklabor steht unseren PatientInnen mit modernster Ausstattung zur Verfügung, damit Sie wieder sorglos lächeln können!",
        },
    },
    {
        path: "eurodent-dental-laboratory",
        pathMatch: "full",
        component: DentalLaboratoryComponent,
        data: {
            lang: "en",
            title: "Dental Clinic Lab Hungary - Mosonmagyaróvár",
            description: "Dental Clinic Hungary | The best cosmetic dentistry and implantology clinic provides dental treatments for reasonable prices in Hungary with its own Dental Lab.",
        },
    },
    {
        path: "laboratorio-odontotecnico-eurodent",
        pathMatch: "full",
        component: DentalLaboratoryComponent,
        data: {
            lang: "it",
            title: "Laboratorio odontotecnico Ungheria - Mosonmagyaróvár",
            description: "La migliore clinica di odontoiatria estetica, implantologia offre trattamenti dentali a prezzi ragionevoli in Ungheria con un proprio laboratorio odontotecnico.",
        },
    },
    {
        path: "laboratoire-dentaire-eurodent",
        pathMatch: "full",
        component: DentalLaboratoryComponent,
        data: {
            lang: "fr",
            title: "Notre clinique - Eurodent clinique dentaire Hongrie",
            description: "Notre clinique - Chez EURODENT Hongrie Centre dentaire et d'implants donne une réponse à tous vos problèmes dentaires.",
        },
    },
    {
        path: "blog",
        pathMatch: "full",
        component: BlogListComponent,
        data: {
            type: "blog",
        },
    },
    {
        path: "blog/:slug",
        component: BlogPageComponent,
    },
    {
        path: "liste-der-behandlungen",
        pathMatch: "full",
        component: ListOfTreatmentsComponent,
        data: {
            lang: "de",
            title: "Zahnbehandlungen - Eurodent Zahnklinik Ungarn",
            description: "Zahnbehandlungen - Unser Hauptprofil sind Implantologie und ästhetische Zahnheilkunde, aber wir bieten auch alle übrigen zahnärztlichen Leistungen an.",
        },
    },
    {
        path: "list-of-treatments",
        pathMatch: "full",
        component: ListOfTreatmentsComponent,
        data: {
            lang: "en",
            title: "List of Treatments",
            description: "List of Treamtents - Get know what we offer for you all around the subject dental treatment and tooth health.",
        },
    },
    {
        path: "elenco-dei-trattamenti",
        pathMatch: "full",
        component: ListOfTreatmentsComponent,
        data: {
            lang: "it",
            title: "Elenco dei trattamenti",
            description: "Elenco dei Treamtents - Scoprite cosa offriamo per voi su tutto ciò che riguarda il trattamento dentale e la salute dei denti.",
        },
    },
    {
        path: "liste-des-traitements",
        pathMatch: "full",
        component: ListOfTreatmentsComponent,
        data: {
            lang: "fr",
            title: "Soins dentaires - Eurodent Clinique Dentaire Hongrie",
            description: "Soins dentaires - Notre profil principal est l'implantologie et la dentisterie esthétique, mais nous proposons également tous les autres services dentaires.",
        },
    },
    // Contact
    {
        path: "kontakt",
        pathMatch: "full",
        component: ContactComponent,
        data: {
            lang: "de",
            title: "Eurodent Zahnklinik Kontakt",
            description: "Eurodent Zahnklinik Kontakt - H-9200 Mosonmagyaróvár, Győri kapu utca 7, Tel: +36 96 578 250,  Nulltarif:  00 800 1000 7000, E-Mail: eurodent@eurodent.hu",
        },
    },
    {
        path: "contact",
        pathMatch: "full",
        component: ContactComponent,
        data: {
            lang: "en",
            title: "Contact",
            description: "Contact: H-9200 Mosonmagyaróvár, Győri kapu utca 7, Tel: +36 96 578 250,  Free: 00 800 1000 7000, E-Mail: eurodent@eurodent.hu",
        },
    },
    {
        path: "contatto",
        pathMatch: "full",
        component: ContactComponent,
        data: {
            lang: "it",
            title: "Contatto",
            description: "Contatto: H-9200 Mosonmagyaróvár, Győri kapu utca 7, Tel: +36 96 578 250, Gratuito: 00 800 1000 7000, E-Mail: eurodent@eurodent.hu",
        },
    },
    {
        path: "contact",
        pathMatch: "full",
        component: ContactComponent,
        data: {
            lang: "fr",
            title: "Eurodent Clinique Dentaire Contact",
            description: "Contact Eurodent Clinique Dentaire - H-9200 Mosonmagyaróvár, Győri kapu utca 7, Tel : +36 96 578 250, Gratuit : 00 800 1000 7000, E-Mail : eurodent@eurodent.hu",
        },
    },
    {
        path: "kontakt/danke",
        pathMatch: "full",
        component: ThankYouComponent,
        data: {
            lang: "de",
            title: "unverbindliche Konsultation",
            description: "Seien Sie Gast bei uns für eine unverbindliche Konsultation und holen Sie sich einen Behandlungsplan. Wir finden eine Lösung auf Ihr Zahnproblem!",
        },
    },
    {
        path: "contact/thankyou",
        pathMatch: "full",
        component: ThankYouComponent,
        data: {
            lang: "en",
            title: "non-binding consultation",
            description: "Be our guest for a non-binding consultation and get a treatment plan. We will find a solution to your dental problem!",
        },
    },
    {
        path: "contatto/grazie",
        pathMatch: "full",
        component: ThankYouComponent,
        data: {
            lang: "it",
            title: "consulenza non vincolante",
            description: "Siate nostri ospiti per una consulenza non vincolante e ottenete un piano di trattamento. Troveremo una soluzione al vostro problema dentale!",
        },
    },
    {
        path: "contact/merci",
        pathMatch: "full",
        component: ThankYouComponent,
        data: {
            lang: "fr",
            title: "consultation sans engagement",
            description: "Soyez notre invité pour une consultation sans engagement et obtenez un plan de traitement. Nous trouverons une solution à votre problème dentaire !",
        },
    },
    {
        path: "gratis-ruckruf",
        pathMatch: "full",
        component: CallbackComponent,
        data: {
            lang: "de",
            title: "Gratis Rückruf",
            description: "Sind Sie nur zu einem bestimmten Zeitpunkt erreichbar? Fordern Sie einen Gratis Rückruf von uns, unsere KollegIn ruft Sie gerne an! +3696578250",
        },
    },
    {
        path: "free-call-back",
        pathMatch: "full",
        component: CallbackComponent,
        data: {
            lang: "en",
            title: "Free Call Back",
            description: "Are available in a ceratein time period only? Request a free call back and our colleague keeps in touch with you.",
        },
    },
    {
        path: "richiamo-gratuito",
        pathMatch: "full",
        component: CallbackComponent,
        data: {
            lang: "it",
            title: "Richiamo gratuito",
            description: "Siete disponibili solo in un periodo di tempo limitato? Richiedete una telefonata e un nostro collega si metterà in contatto con voi.",
        },
    },
    {
        path: "rappel-gratuit",
        pathMatch: "full",
        component: CallbackComponent,
        data: {
            lang: "fr",
            title: "Rappel gratuit",
            description: "Vous n'êtes disponible que pendant une période donnée ? Demandez un rappel gratuit et notre collègue reste en contact avec vous.",
        },
    },
    {
        path: "gratis-ruckruf/danke",
        pathMatch: "full",
        component: ThankYouComponent,
        data: {
            lang: "de",
            title: "unverbindliche Konsultation",
            description: "Seien Sie Gast bei uns für eine unverbindliche Konsultation und holen Sie sich einen Behandlungsplan. Wir finden eine Lösung auf Ihr Zahnproblem!",
        },
    },
    {
        path: "free-call-back/thankyou",
        pathMatch: "full",
        component: ThankYouComponent,
        data: {
            lang: "en",
            title: "non-binding consultation",
            description: "Be our guest for a non-binding consultation and get a treatment plan. We will find a solution to your dental problem!",
        },
    },
    {
        path: "richiamo-gratuito/grazie",
        pathMatch: "full",
        component: ThankYouComponent,
        data: {
            lang: "it",
            title: "consulenza non vincolante",
            description: "Siate nostri ospiti per una consulenza non vincolante e ottenete un piano di trattamento. Troveremo una soluzione al vostro problema dentale!",
        },
    },
    {
        path: "rappel-gratuit/merci",
        pathMatch: "full",
        component: ThankYouComponent,
        data: {
            lang: "fr",
            title: "consultation sans engagement",
            description: "Soyez notre invité pour une consultation sans engagement et obtenez un plan de traitement. Nous trouverons une solution à votre problème dentaire !",
        },
    },
    {
        path: "anreise",
        pathMatch: "full",
        component: ArrivalComponent,
        data: {
            lang: "de",
            title: "Anreise - Eurodent Zahnklinik Ungarn",
            description: "Anreise - Eurodent Zahnklinik Ungarn, Adresse: H-9200 Mosonmagyaróvár, Győri kapu utca 7",
        },
    },
    {
        path: "travel-information",
        pathMatch: "full",
        component: ArrivalComponent,
        data: {
            lang: "en",
            title: "Travel information",
            description: "Travel information - How to come to us, useful information about travel to Eurodent.",
        },
    },
    {
        path: "come-arrivare-da-noi",
        pathMatch: "full",
        component: ArrivalComponent,
        data: {
            lang: "it",
            title: "Come arrivare da noi",
            description: "Informazioni di viaggio - Come arrivare da noi, informazioni utili sul viaggio verso Eurodent.",
        },
    },
    {
        path: "comment-venir",
        pathMatch: "full",
        component: ArrivalComponent,
        data: {
            lang: "fr",
            title: "Séjour dentaire - Eurodent",
            description: "Séjour dentaire chez Eurodent - Transfert et hébergement gratuits !",
        },
    },
    {
        path: "coronavirus-und-reiseinfos",
        pathMatch: "full",
        component: CoronavirusComponent,
        data: {
            lang: "de",
            title: "Coronavirus und Reiseinfos",
            description: "Coronavirus und Reiseinfos",
        },
    },
    {
        path: "corona-virus-and-current-travel-infos",
        pathMatch: "full",
        component: CoronavirusComponent,
        data: {
            lang: "en",
            title: "Corona virus and current travel infos",
            description: "Corona virus and current travel infos",
        },
    },
    {
        path: "corona-virus-e-informazioni-di-viaggio",
        pathMatch: "full",
        component: CoronavirusComponent,
        data: {
            lang: "it",
            title: "Corona virus e informazioni di viaggio",
            description: "Corona virus e informazioni di viaggio",
        },
    },
    {
        path: "coronavirus-et-informations-sur-les-voyages",
        pathMatch: "full",
        component: CoronavirusComponent,
        data: {
            lang: "fr",
            title: "Coronavirus et informations sur les voyages",
            description: "Coronavirus et informations sur les voyages",
        },
    },
    {
        path: "**",
        component: UrlRedirectComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
