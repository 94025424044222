import {Component, ElementRef, EventEmitter, Inject, LOCALE_ID, OnInit, Output, ViewChild} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {CountrycodeService} from "../../../core/services/countrycode.service";
import {PrivacyPolicy} from "../../../core/models/privacy-policy.model";
import {PrivacyPolicyService} from "../../../core/services/privacy-policy.service";
import {FormService} from "../../../core/services/form.service";
import {RoutesService} from "../../../core/services/routes.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
    contactForm: FormGroup = this.formBuilder.group({
        first_name: [null, [Validators.required]],
        last_name: [null, [Validators.required]],
        email: [null, [Validators.required]],
        country: [null, [Validators.required]],
        area: [null, [Validators.required]],
        number: [null, [Validators.required]],
        comment: [null, [Validators.required]],
    });

    uploadedFile: File | undefined;
    uploadedFiles: File[] = [];

    countryCodes: string[] = [];

    allowedFileTypes = [
        'application/zip',
        'image/png',
        'image/jpeg',
        'image/tiff',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    privacy: PrivacyPolicy | undefined;

    errors = new Map();
    @Output() formIsSent = new EventEmitter<boolean>();

    placeholderList = [
        {lang: "de", placeholderName: "firstname", placeholderText: "Ihre Vorname"},
        {lang: "en", placeholderName: "firstname", placeholderText: "First name"},
        {lang: "it", placeholderName: "firstname", placeholderText: "Il vostro nome"},
        {lang: "fr", placeholderName: "firstname", placeholderText: "Votre prénom"},
        {lang: "de", placeholderName: "lastname", placeholderText: "Ihre Nachname"},
        {lang: "en", placeholderName: "lastname", placeholderText: "Last name"},
        {lang: "it", placeholderName: "lastname", placeholderText: "Il vostro cognome"},
        {lang: "fr", placeholderName: "lastname", placeholderText: "Votre nom"},
        {lang: "de", placeholderName: "email", placeholderText: "Ihre E-mail Adresse"},
        {lang: "en", placeholderName: "email", placeholderText: "E-Mail"},
        {lang: "it", placeholderName: "email", placeholderText: "Il vostro indirizzo e-mail"},
        {lang: "fr", placeholderName: "email", placeholderText: "Votre adresse e-mail"},
    ]

    disabledSubmitButton = false;

    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private countryCodeService: CountrycodeService,
        private privacyPolicyService: PrivacyPolicyService,
        private formService: FormService,
        @Inject(LOCALE_ID) public locale: string,
        public routesService: RoutesService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.countryCodeService.getCountryCodes().subscribe((value) => {
            this.countryCodes = value;
        });
        this.privacyPolicyService.getPrivacyPolicy().subscribe((value) => {
            this.privacy = value;
        });
    }

    formIsValid(): boolean {
        return this.contactForm.valid;
    }

    getPlaceholders(placeholderName: string) {
        return this.placeholderList.find((x: { placeholderName: string; lang: string; }) => x.placeholderName === placeholderName && x.lang === this.locale)?.placeholderText;
    }

    getFormValidationErrors() {
        this.errors = new Map();
        Object.keys(this.contactForm.controls).forEach((key) => {
            const controlErrors = this.contactForm.get(key)?.errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    this.errors.set(key, keyError);
                });
            }
        });
    }

    hasError(controlName: string, errorName: string): boolean {
        return this.errors.get(controlName) === errorName;
    }

    checkValidation(event: any) {
        const formControl = this.contactForm.controls[event.target.name];
        this.validateField(event.target.name, formControl);
    }

    private validateField(fieldName: string, formControl: AbstractControl) {
        if (formControl.hasError("required")) {
            this.errors.set(fieldName, "required");
        } else {
            this.errors.delete(fieldName);
        }
    }

    uploadFile(event: any, number: number) {
        if (this.uploadedFiles[number] === undefined) {
            this.uploadedFiles.push(event.target.files[0]);
        } else {
            this.uploadedFiles[number] = event.target.files[0];
        }
        this.contactForm.get('file')?.updateValueAndValidity();
    }

    deleteFile(number: number) {
        this.uploadedFiles.splice(number, 1);
    }

    fileValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (
                !!this.uploadedFile &&
                !this.allowedFileTypes.includes(this.uploadedFile?.type)
            ) {
                return {fileType: {value: this.uploadedFile?.type}};
            } else {
                return null;
            }
        };
    }

    onSubmit() {
        if (this.formIsValid()) {
            this.disabledSubmitButton = true;
            this.http.get(environment.ipApiUrl).subscribe((data: any) => {
                let contactForm = {
                    first_name: this.contactForm.controls["first_name"].value,
                    last_name: this.contactForm.controls["last_name"].value,
                    email: this.contactForm.controls["email"].value,
                    country: this.contactForm.controls["country"].value,
                    area: this.contactForm.controls["area"].value,
                    number: this.contactForm.controls["number"].value,
                    comment: this.contactForm.controls["comment"].value,
                    ip: data.ip,
                    url: document.location.host,
                }
                this.formService.postContactForm(contactForm, this.uploadedFiles).subscribe();
                this.router.navigate([this.routesService.getRouterLink('contact-thankyou')]);
            })

        } else {
            this.getFormValidationErrors();
        }
    }

}
