<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Ihre Vorteile bei uns
            </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/about-us/top/IhreVorteile-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Profitieren Sie als Patient(in) von diesen Vorteilen</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n>Bei der Eurodent Zahnklinik in Ungarn erhalten Sie eine hochwertige kieferchirurgische und
                        ästhetische Versorgung - zu einem guten Preis, ohne dabei Abstriche an der Qualität hinnehmen zu
                        müssen.</p>
                    <p i18n>Unsere Patient(inn)en brauchen keine Angst vor einer Zahnbehandlung zu haben. Die Behandlungen
                        werden immer so schmerzfrei wie möglich durchgeführt. Unsere Zahnärzte haben sehr viel
                        Erfahrung, sind bestens ausgebildet und arbeiten mit Geduld und Mitgefühl. Ihr Aufenthalt und
                        Ihre Sitzungen werden so angenehm wie möglich gestaltet.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>EURODENT ZAHN- UND IMPLANTATZENTRUM - Für eine anspruchsvolle, preiswerte Zahnbehandlung in Ungarn</h1>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    Ihre Vorteile als Patient bei der Eurodent Zahnklinik
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Weitestgehend schmerz- und angstfreie Zahnbehandlungen. Für Angstpatienten sind die Behandlungen auch unter Sedierung möglich.</div>
                        <div i18n class="content--main__content__list__item">Hochwertige, niveauvolle und vielfältige zahnmedizinische Behandlungen zu günstigen Preisen (bis zu 50-60% Ersparnis gegenüber Preisen in Österreich, Deutschland und der Schweiz)</div>
                        <div i18n class="content--main__content__list__item">Zuschuss durch die Krankenkasse. Unsere Heil-und Kostenpläne sind für die Krankenkassen geeignet. </div>
                        <div i18n class="content--main__content__list__item">hohe Qualitätsstandards</div>
                        <div i18n class="content--main__content__list__item">zeitkomprimierte Zahnbehandlungen
                        </div>
                        <div i18n class="content--main__content__list__item">Rundumservice von der Reiseplanung bis zur Nachsorge</div>
                        <div i18n class="content--main__content__list__item">Zahnbehandlung ohne Sprachbarriere (Deutsch, Französisch, Arabisch, Italienisch, Englisch)</div>
                        <div i18n class="content--main__content__list__item">Zahnklinik und hauseigenes Zahntechniklabor  werden ständig weiterentwickelt</div>
                        <div i18n class="content--main__content__list__item">kostenlose Unterkunftsmöglichkeit, kostenloser Abholdienst, kostenlose Beratung</div>
                    </div>
                </div>
            </div>

            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    Unsere Zahnklinik bietet Ihnen die Lösung
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">wenn Ihnen Ihre Gesundheit und Ihr Lächeln wichtig sind</div>
                        <div i18n class="content--main__content__list__item">wenn Sie endlich wieder unbeschwert essen und lachen möchten</div>
                        <div i18n class="content--main__content__list__item">wenn Sie Ihre Zahnbehandlungs- und Zahnersatzkosten reduzieren möchten</div>
                        <div i18n class="content--main__content__list__item">wenn Sie erfahrene, hochqualifizierte und sich ständig weiterbildende Zahnärzte möchten</div>
                        <div i18n class="content--main__content__list__item">wenn Sie nach hochwertigen, vielfältigen und niveauvollen zahnmedizinischen Leistungen suchen.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <p i18n>Die meisten unserer Patienten kommen durch persönliche Empfehlungen (mehr als 50.000 zufriedene Patienten aus dem deutschsprachigen Raum seit 1992).</p>
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/about-us/bottom/1.jpg">
</div>
