import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Blog, BlogDataWrapper, BlogSingletonWrapper, BlogWrapper} from "../models/blog.model";
import {ImageUrlService} from "./image-url.service";
import {ImageWrapper} from "../models/image.model";
import {BlogText} from "../models/blogtext.model";

@Injectable({
    providedIn: "root",
})
export class BlogService {
    constructor(
        private http: HttpClient,
        private lang: LanguageService,
        private imageUrlService: ImageUrlService,
    ) {
    }

    public getBlogs(): Observable<Blog[]> {
        return this.http.get<BlogDataWrapper>(
            environment.apiUrl +
            "blogs?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) => value.data))
            .pipe(map((value) => {
                return value.map((item) => (
                    this.transformBlogData(item)
                ))
            }));
    }

    public getBlog(slug: string): Observable<Blog> {
        return this.http.get<BlogSingletonWrapper>(
            environment.apiUrl +
            "blogs/" +
            slug +
            "?locale=" +
            this.lang.getLang()
        )
            .pipe(
                map((value) => (
                    this.transformBlogData(value.data)
                ))
            );
    }

    private transformBlogData(item: any) {
        return {
            id: item.id,
            title: item.attributes.title,
            slug: item.attributes.slug,
            subtitle: item.attributes.subtitle,
            lead: item.attributes.lead,
            main_picture: {
                alternativeText: item.attributes.main_picture.data.attributes.alternativeText,
                largeUrl: this.imageUrlService.getImageUrl(item.attributes.main_picture.data.attributes)
            },
            mood_picture: {
                alternativeText: item.attributes.mood_picture.data.attributes.alternativeText,
                largeUrl: this.imageUrlService.getImageUrl(item.attributes.mood_picture.data.attributes)
            },
            blog_text: item.attributes.blog_text,
            seo: item.attributes.seo,
        }
    }
}
