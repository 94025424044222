import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Blog} from "../../../core/models/blog.model";
import {BlogTextAccordion} from "../../../core/models/blogtext.model";
import {RichTextService} from "../../../core/services/rich-text.service";

@Component({
    selector: 'app-offer-accordion',
    templateUrl: './offer-accordion.component.html',
    styleUrls: ['./offer-accordion.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OfferAccordionComponent implements OnInit {

    @Input() accordion: BlogTextAccordion | undefined;

    constructor(
        public richtextService: RichTextService
    ) {
    }

    ngOnInit(): void {
    }

    changeListStyle(text: string): string {
        return text.replaceAll('<ul style="list-style-type:circle;">', '<ul class="circle">')
            .replaceAll('<ol style="list-style-type:decimal;">', '<ol class="decimal">')
            .replaceAll('<ol style="list-style-type:upper-roman;">', '<ol class="upper-roman">')
            .replaceAll('<ul style="list-style-type:disc;">', '<ul class="disc">')
            .replaceAll('<li>', '<li><span>')
            .replaceAll('</li>', '</span></li>');
    }

}
