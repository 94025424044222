import { Component, OnInit } from '@angular/core';
import {RoutesService} from "../../../../core/services/routes.service";

@Component({
  selector: 'app-free-accomodation',
  templateUrl: './free-accomodation.component.html',
  styleUrls: ['./free-accomodation.component.scss']
})
export class FreeAccomodationComponent implements OnInit {

  constructor(
      public routesService: RoutesService
  ) { }

  ngOnInit(): void {
  }

}
