import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LanguageService} from "./language.service";
import {map, Observable} from "rxjs";
import {PrivacyPolicy, PrivacyPolicyWrapper} from "../models/privacy-policy.model";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class PrivacyPolicyService {
    constructor(private http: HttpClient, private lang: LanguageService) {
    }

    public getPrivacyPolicy(): Observable<PrivacyPolicy> {
        return this.http.get<PrivacyPolicyWrapper[]>(
            environment.apiUrl +
            "privacy-policy?locale=" +
            this.lang.getLang()
        )
            .pipe(map((value) => {
                return value[0];
            }))
            .pipe(map((value) => ({
                id: value.id,
                privacy_policy: {
                    id: value.privacy_policy.id,
                    ext: value.privacy_policy.ext,
                    mime: value.privacy_policy.mime,
                    url: value.privacy_policy.url,
                },
                hungarian_privacy_policy: {
                    id: value.hungarian_privacy_policy.id,
                    ext: value.hungarian_privacy_policy.ext,
                    mime: value.hungarian_privacy_policy.mime,
                    url: value.hungarian_privacy_policy.url,
                }
            })))

    }
}
