import {Injectable} from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class ScrollViewService {
  constructor() {
  }

  scrollViewToPositionWithTimeout(_top: number, _timeout: number): void {
    setTimeout(() => {
      window.scrollTo({top: _top, behavior: 'smooth'});
    }, _timeout);
  }
}
