<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>Zahnimplantate </h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/Zahnimplantate-top.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Zahnimplantat in Ungarn

                    </h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <div class="content--top__lead__description__list">
                        <div i18n class="content--top__lead__description__list__title">Zahnimplantation in Eurodent
                            Zahnklinik Ungarn mit digitaler Technologie
                        </div>
                        <div i18n class="content--top__lead__description__list__item">moderne, schonende Verfahren

                        </div>
                        <div i18n class="content--top__lead__description__list__item">höchste Präzision, perfekte Genauigkeit

                        </div>
                        <div i18n class="content--top__lead__description__list__item">hervorragende Ergebnisse

                        </div>
                        <div i18n class="content--top__lead__description__list__item">3 Jahre Garantie

                        </div>
                        <div i18n class="content--top__lead__description__list__item">30 Jahre Erfahrung, professionelle
                            Zahnärzte
                        </div>
                        <div i18n class="content--top__lead__description__list__item">bis zu 70% Ersparnis
                        </div>
                    </div>

                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <h1 i18n>WAS SIND ZAHNIMPLANTATE?
            </h1>
            <p i18n>Zahnimplantate kommen dann zur Anwendung, wenn ein Zahn oder mehrere Zähne ganz verloren sind. Durch
                Implantologie werden die fehlenden Zähne mithilfe eingesetzter künstlicher Zahnwurzeln aus
                biokompatiblem Material - aus reinem Titan - ersetzt.
                Das Implantat übernimmt dabei die Funktion der Zahnwurzel, sitzt fest im Kiefer und bildet so den
                Pfeiler für den Zahnersatz. Dieses Verfahren wird heute routinemäßig angewendet. Der Unterschied von
                Zahnimplantaten im Vergleich zu anderen Lösungen ist grandios.
            </p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    VORTEILE VON ZAHNIMPLANTATEN
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Sie spüren kaum Unterschied zu den eigenen
                            Zähnen. Der implantierte Zahn ist genauso stark und schön wie echte Zähne.
                        </div>
                        <div i18n class="content--main__content__list__item">Zahnimplantate stellen das natürliche Sprechen
                            und die Kaufunktion wieder her.
                        </div>
                        <div i18n class="content--main__content__list__item">Festsitzender Zahnersatz auf Implantaten bietet
                            eine besonders ästhetische Lösung. Das Endergebnis ist nicht von natürlichen Zähnen zu
                            unterscheiden.
                        </div>
                        <div i18n class="content--main__content__list__item">Hilft das Gesicht vor dem Altern zu bewahren,
                            denn der Verlust der eigenen Zähne führt zu einer Rückbildung der Gesichtsmuskulatur.
                        </div>
                        <div i18n class="content--main__content__list__item">Bei richtiger Pflege kann ein Implantat sogar
                            ein Leben lang halten.
                        </div>
                        <div i18n class="content--main__content__list__item">Kauen mit Zahnimplantaten trainiert den
                            Kieferknochen, so beugen Implantate auch einem Knochenschwund vor.
                        </div>
                        <div i18n class="content--main__content__list__item">Natürliches Mundgefühl, gute Lebensqualität und
                            mehr Selbstbewusstsein.
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    TUT EINE ZAHNIMPLANTATION WEH?

                    <img alt="content accordion icon" i18n-alt src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Nein! Selbst die Implantation tut nicht weh. Sie werden für die Operation örtlich betäubt, also
                        spüren Sie gar nichts. Angstpatienten bieten wir eine Zahnimplantation unter Sedierung an.
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    WIE LANGE DAUERT EINE IMPLANTATION?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Das hängt davon ab, was für Implantate verwendet und wie viele gesetzt werden. Der Eingriff pro
                        Implantat dauert durchschnittlich nicht länger als 20 Minuten. Das Implantationsvorgehen ist so
                        schonend und sicher, dass Ihnen alle erforderlichen Implantate meist in einer Sitzung eingesetzt
                        werden können.
                    </p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck4">
                <label i18n class="content__accordion__label" for="chck4">
                    Für welche Zahnprobleme bieten Zahnimplantate eine perfekte Lösung?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <h4 i18n>Fehlender Einzelzahn</h4>
                            <p i18n>Ein kleiner Unfall führt zum Verlust eines Zahns oder ein wurzelbehandelter Zahn, der
                                sich trotz Wurzelspitzenresektion immer wieder entzündet, muss entfernt werden?
                                Auch in diesen Fällen bringen Zahnimplantate die natürliche Ästhetik und Funktion der
                                Zähne zurück. Implantate können in bestimmten Fällen sofort nach einem Unfall oder einer
                                Zahnentfernung gesetzt werden.
                            </p>
                        </div>
                        <div class="images">
                            <div class="image">
                                <img alt="Zahn implantate" i18n-alt src="assets/images/treatments/accordion/Zahnimplantate-accordion1.png">
                            </div>
                        </div>
                    </div>
                    <div class="content--main__content__text-image--detailed reversed">
                        <div class="text">
                            <h4 i18n>Mehrere fehlenden Zähne</h4>
                            <p i18n>Wenn zwei oder mehrere Zähne fehlen, sorgt eine auf Zahnimplantaten verankerte
                                festsitzende Brücke für optimale Stabilität sowie ein ästhetisch und funktionell
                                überzeugendes Ergebnis. Das Abschleifen von gesunden Nachbarzähnen ist nicht nötig.
                            </p>
                        </div>
                        <div class="images">
                            <div class="image">
                                <img src="assets/images/treatments/accordion/Zahnimplantate-accordion2.png">
                            </div>
                        </div>
                    </div>
                    <div class="content--main__content__text-image--detailed">
                        <div class="text">
                            <h4 i18n>Vollständig zahnloser Kiefer</h4>

                            <p class="no-margin"><ng-template i18n>Für die Versorgung von vollständig zahnlosen Kiefern bietet die Implantologie herausnehmbare Prothesen und fest verschraubte Zahnreihen an.</ng-template>
                                <br><span i18n class="bold">Die Vorteile beider Lösungen im Vergleich zur klassischen Haftprothese sprechen für sich:</span>
                            </p>
                            <ul class="list">
                                <li i18n>Prothesen auf Implantaten bieten in jeder Situation einen sicheren Halt.</li>
                                <li i18n>Die Kaukräfte werden durch die Prothese in den Knochen geleitet. Dies verhindert den
                                    Knochenabbau und gewährleistet somit einen langfristigen, optimalen Prothesensitz.
                                </li>
                                <li i18n>Der Gaumen bleibt frei vom Prothesenmaterial. Der Geschmackssinn und somit der
                                    Genuss beim Essen werden nicht beeinträchtigt.
                                </li>
                            </ul>


                        </div>
                        <div class="images">
                            <div class="image">
                                <img src="assets/images/treatments/accordion/Zahnimplantate-accordion3.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck5">
                <label i18n class="content__accordion__label" for="chck5">
                    Wie läuft die Behandlung ab?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Eine Implantation besteht aus mehreren Schritten. Sie sollten mit folgenden Behandlungen bzw.
                        Sitzungen rechnen: *
                    </p>
                    <div class="content--main__content__numbered-list">
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">1</div>
                            <div class="information">
                                <div i18n class="information__title">Konsultation, Erstuntersuchung, CT-Aufnahme</div>
                                <div i18n class="information__text">Bei den Behandlungen streben wir nach höchstmöglicher
                                    Sicherheit. Deshalb ist die Anfertigung und Auswertung einer aktuellen CT-Aufnahme
                                    vor Erstellung von Kosten- und Behandlungsplänen sowie chirurgischen Eingriffen
                                    unerlässlich.
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">2</div>
                            <div class="information">
                                <div i18n class="information__title">Implantation
                                </div>
                                <div i18n class="information__text">Unser Kieferchirurg setzt die Implantate im Kieferknochen
                                    ein. In einer Sitzung ist es nur möglich, einen Kiefer mit Implantaten zu versorgen.

                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">3</div>
                            <div class="information">
                                <div i18n class="information__title">Kontrolle und Nahtentfernung</div>
                                <div i18n class="information__text">nach 10 Tagen Kontrolle und Nahtentfernung
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">4</div>
                            <div class="information">
                                <div i18n class="information__title">Freilegung von Implantaten, Abdrucknahme</div>
                                <div i18n class="information__text">nach 3-6 Monate Heilungszeit folgen die Freilegung von
                                    Implantaten, das Einsetzen von Heilungsschrauben und das Anfertigen eines Abdrucks.
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">5</div>
                            <div class="information">
                                <div i18n class="information__title">Gerüstprobe
                                </div>
                                <div i18n class="information__text">nach einer Woche Gerüstprobe, sofern erforderlich
                                </div>
                            </div>
                        </div>
                        <div class="content--main__content__numbered-list__item">
                            <div class="dotted-line"></div>
                            <div class="number">6</div>
                            <div class="information">
                                <div i18n class="information__title">Fertigstellung</div>
                                <div i18n class="information__text">Fertigstellung nach einer weiteren Woche
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><span i18n class="bold">Diese Daten dienen nur Ihrer Orientierung!</span><ng-template i18n> Wir planen die
                        Behandlungsschritte für alle Patienten individuell aufgrund ihrer persönlichen Anliegen,
                        CT-Aufnahme und Knochensubstanz. Zu weiteren Kosten kann es bei einer Implantation kommen, wenn
                        ein vorheriger Knochenaufbau (mittels eines künstlichen Knochenersatzmaterials) erforderlich
                        ist. Das ist jedoch nur dann notwendig, wenn die vorhandene Knochenmenge im Ober- oder
                        Unterkiefer für eine Implantation nicht ausreichend ist.</ng-template>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/2.jpg">
</div>

